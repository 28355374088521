import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './style.css';
import { useParams } from 'react-router-dom';
import { useData } from "./Cache";


const TeamList = () => {
    const { matches, teams, seasons, loading, error } = useData();

  const [filteredMatchess, setMatches] = useState([]);
  const [filteredSeasons, setSeasons] = useState([]);
  const { leagueName } = useParams();
  const [activeTab, setActiveTab] = useState("fixture");

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const filteredMatches = matches.filter(
          (match) =>
            formatTeamNameForURL(match.league_name) === leagueName &&
            match.is_it_fixture === false
        ); // Filter matches
        setMatches(filteredMatches);
      } catch (error) {
        console.error("Hata:", error);
      }
    };
    fetchMatches();
    const fetchSeasons = async () => {
      try {
        const filteredSeasons = seasons.filter(
          (season) => formatTeamNameForURL(season.league_name) === leagueName
        ); // Filter matches
        setSeasons(filteredSeasons);
      } catch (error) {
        console.error("Hata:", error);
      }
    };
    fetchSeasons();
  }, []);

    if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;



    return (
        <div>
            <Header />
            <header class="header-banner">
                <h1 class="banner-title">Takım Listesi</h1>
                <div class="zigzag">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path fill="#4facfe" fill-opacity="1" d="M0,256L48,224C96,192,192,128,288,96C384,64,480,64,576,96C672,128,768,192,864,213.3C960,235,1056,213,1152,213.3C1248,213,1344,235,1392,245.3L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
                    </svg>
                </div>
            </header>

            <div className='centered-container'>
                <div className='centered-container-2'>

                    <select onChange={(e) => setActiveTab(e.target.value)}>
                        <option value="">Sezon Seç</option>
                        {seasons
                            .map((season) => (
                                <option key={season.id} value={season.season_name}>
                                    {season.season_name}
                                </option>
                            ))}
                    </select>
                </div>

                <div className="table-container">
                    {seasons.map((season) => {
                        if (activeTab === season.season_name) {
                            const filteredMatches = matches.filter(
                                (match) => match.match_season === season.season_name
                            );

                            return <TeamLists key={season.id} teams={teams} matches={filteredMatches} />;
                        }
                        return null; // Eğer aktif tab değilse, null döndür
                    })}

                </div>
            </div>
            <Footer />
        </div>
    );
};
function formatTeamNameForURL(teamName) {
    return teamName
        .toLowerCase()
        .replace(/ç/g, "c")
        .replace(/ğ/g, "g")
        .replace(/ı/g, "i")
        .replace(/ö/g, "o")
        .replace(/ş/g, "s")
        .replace(/ü/g, "u")
        .replace(/[^a-z0-9 ]/g, "")
        .trim()
        .replace(/\s+/g, "-");
}

const getTeamStats = (teamName, matches) => {
    const sortedMatches = matches
        .filter(
            (match) =>
                match.first_team_name === teamName || match.second_team_name === teamName
        )
        .sort((a, b) => new Date(b.date) - new Date(a.date));
    const lastFiveMatches = sortedMatches.slice(0, 5);

    let wins = 0, draws = 0, losses = 0;
    matches.forEach((match) => {
        if (match.first_team_name === teamName) {
            if (match.first_team_score > match.second_team_score) wins++;
            else if (match.first_team_score === match.second_team_score) draws++;
            else losses++;
        } else if (match.second_team_name === teamName) {
            if (match.second_team_score > match.first_team_score) wins++;
            else if (match.second_team_score === match.first_team_score) draws++;
            else losses++;
        }
    });
    return {
        teamName,
        wins,
        draws,
        losses,
        lastFiveMatches,
    };
};

function TeamLists({ teams, matches }) {
    return (
        <div className="table-container">
            <div>
                <table>
                    <thead>
                        <tr>
                                    <th>Sıra</th>
                                    <th>Takım</th>
                                    <th>Oyn. Maç</th>
                                    <th>Gal</th>
                                    <th>Ber</th>
                                    <th>Mağ</th>
                                    <th>AG</th>
                                    <th>YG</th>
                                    <th>Av</th>
                                    <th>Puan</th>
                                    <th>Form</th>
                                    <th>İncele</th>
                        </tr>
                    </thead>
                    <tbody>
                        {teams.map((team, index) => {
                            const stats = getTeamStats(team.team_name, matches);


                            // Gol averajını hesapla
                            const totalGoalsFor = stats.lastFiveMatches.reduce(
                                (sum, match) =>
                                    match.first_team_name === team.team_name
                                        ? sum + match.first_team_score
                                        : sum + match.second_team_score,
                                0
                            );

                            const totalGoalsAgainst = stats.lastFiveMatches.reduce(
                                (sum, match) =>
                                    match.first_team_name === team.team_name
                                        ? sum + match.second_team_score
                                        : sum + match.first_team_score,
                                0
                            );

                            const goalDifference = totalGoalsFor - totalGoalsAgainst;

                            // Puanı hesapla (galibiyet = 3, beraberlik = 1, yenilgi = 0)
                            const points = stats.wins * 3 + stats.draws;

                            const formStart = stats.lastFiveMatches.map((match) => {
                                if (
                                    (match.first_team_name === team.team_name &&
                                        match.first_team_score > match.second_team_score) ||
                                    (match.second_team_name === team.team_name &&
                                        match.second_team_score > match.first_team_score)
                                ) {
                                    return "win";
                                } else if (
                                    match.first_team_score === match.second_team_score &&
                                    ((match.first_team_name === team.team_name) || (match.second_team_name === team.team_name))
                                ) {
                                    return "draw";
                                } else if ((match.first_team_name === team.team_name) || (match.second_team_name === team.team_name)) {
                                    return "loss";
                                }
                            });
                            const form = formStart.filter((item) => item !== undefined);
                            const formReverse = formStart.filter((item) => item !== undefined).reverse();


                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td style={{ display: "flex", alignItems: "center", textAlign: "left" }}
                                        onClick={() => window.location.href = `/takimlar/${formatTeamNameForURL(team.team_name)}`}>
                                        <img
                                            src={team.logo}
                                            alt={team.team_name}
                                            style={{ width: "30px", marginRight: "10px" }}
                                        />
                                        {team.team_name}
                                    </td>
                                    <td>{stats.wins + stats.draws + stats.losses}</td>
                                    <td>{stats.wins}</td>
                                    <td>{stats.draws}</td>
                                    <td>{stats.losses}</td>
                                    <td>{totalGoalsFor}</td>
                                    <td>{totalGoalsAgainst}</td>
                                    <td>{goalDifference}</td>
                                    <td>{points}</td>
                                    <td>
                                        {formReverse && formReverse.length > 0 ? (
                                                    formReverse.map((result, i) => (
                                                        <span
                                                            key={i}
                                                            class={`form ${result}`}
                                                        >
                                                            {console.log(result)}{result.slice(0, 1).toUpperCase()}
                                                        </span>
                                                    ))
                                                ) : (
                                                    <span style={{ color: "gray" }}>-</span>
                                                )}
                                    </td>
                                    <td onClick={() => window.location.href = `/takimlar/${formatTeamNameForURL(team.team_name)}`}>➡</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default TeamList;
