import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Add_Teams.css';
import { useUser } from "../UserContext"; // UserContext'i çağır

const AddTeams = ({ closeModal, refreshTeams }) => {
    const [leagues, setLeagues] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [teamName, setTeamName] = useState('');
    const [selectedLeague, setSelectedLeague] = useState('');
    const [selectedSeason, setSelectedSeason] = useState('');
    const [logo, setLogo] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [teamPoints, setTeamPoints] = useState(''); // Takım puanı için state

    const { user, setUser } = useUser();
    const userCity = user?.city;
    // Ligleri ve sezonları getir
    useEffect(() => {
        fetchLeagues();
        fetchSeasons();
    }, []);

    const fetchLeagues = async () => {
        try {
            const response = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/ligler/by-city/${userCity}`);
            setLeagues(response.data);
        } catch (error) {
            console.error("Error fetching leagues:", error);
        }
    };

    const fetchSeasons = async () => {
        try {
            const response = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/sezonlar/by-city/${userCity}`);
            setSeasons(response.data);
        } catch (error) {
            console.error("Error fetching seasons:", error);
        }
    };

     // Takım puanı için sadece 0 veya pozitif sayı girilmesini sağla
    const handlePointsChange = (e) => {
        const value = e.target.value;

        // Sadece sayılar ve boş değer kabul edilir
        if (/^\d*$/.test(value)) {
            setTeamPoints(value);
        }
    };


    const handleAddTeam = async () => {
    if (!teamName || !selectedLeague || !selectedSeason) {
        setErrorMessage("Lütfen tüm alanları doldurun.");
        return;
    }

     // Kullanıcıdan gelen text'i int'e çevir
    const parsedTeamPoints = parseInt(teamPoints, 10);
    const finalTeamPoints = isNaN(parsedTeamPoints) ? 0 : parsedTeamPoints; // Eğer sayı değilse, 0 olarak ayarla

    try {
        const formData = new FormData();
        formData.append('team_name', teamName);
        formData.append('current_league', selectedLeague);
        formData.append('current_season', selectedSeason);
        formData.append('city',userCity);
        formData.append('team_points', finalTeamPoints); // Int olarak gönder


        // Logo eklenmişse formData'ya ekle
        if (logo) {
            formData.append('logo', logo);
        }

        const response = await axios.post("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/add", formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });

        const message = response.data.message;
        if (response.status === 201) {
            setSuccessMessage("Takım başarıyla eklendi!");
            setErrorMessage('');
            refreshTeams();
        }else {
            setErrorMessage(message);
            setSuccessMessage('');
        }
    } catch (error) {
        console.error("Error details:", error);
        if (error.response?.status === 400) {
            setErrorMessage(error.response.data.error);
        } else {
            setErrorMessage("Bir hata oluştu. Lütfen tekrar deneyinn.");
        }
    }
};

    return (
    <div className="modal-overlay">
        <div className="modal-content">
            <button className="close-button" onClick={closeModal}>&times;</button>

        <div className="modal-title-addTeam">Takım Ekle</div>

        <select onChange={(e) => setSelectedLeague(e.target.value)} value={selectedLeague}>
            <option value="">Lig Seçin</option>
            {leagues.map((league, index) => (
                <option key={index} value={league.league_name}>{league.league_name}</option>
            ))}
        </select>

        <select onChange={(e) => setSelectedSeason(e.target.value)} value={selectedSeason}>
            <option value="">Sezon Seçin</option>
            {seasons.map((season, index) => (
                <option key={index} value={season.season_name}>{season.season_name}{"  "}{season.league_name}</option>
            ))}
        </select>

        <input
            type="text"
            placeholder="Takım adı girin"
            value={teamName}
            onChange={(e) => setTeamName(e.target.value)}
            className="custom-input"
        />
                
         {/* Takım Puanı Inputu */}
        <input
            type="text"
            placeholder="Takım Puanı (Opsiyonel)"
            value={teamPoints}
            onChange={handlePointsChange}
            className="custom-input"
        />

        <label htmlFor="logo-upload" className="custom-file-label">Logo Yükle</label>
        <input
            type="file"
            id="logo-upload"
            onChange={(e) => setLogo(e.target.files[0])}
            className="custom-file-input"
        />



    <button onClick={handleAddTeam} className="create-button">Oluştur</button>
    {errorMessage && <p className="error-message">{errorMessage}</p>}
    {successMessage && <p className="success-message">{successMessage}</p>}
</div>

        </div>
    );
};

export default AddTeams;
