import Header from "./Header";
import Footer from "./Footer";
import "./Player.css";
import React, { useEffect, useState, useRef } from "react";
import { useData } from "./Cache";
import { useParams } from "react-router-dom";
import Chart from "chart.js/auto";

const PlayerPage = () => {
  const { cityName, leagueName, playerID } = useParams();
  const {
    matches,
    players,
    playerStatistics1,
    seasons,
    loading,
    teams,
    error,
  } = useData();

  // Oyuncuyu bul
  const player = players.find((player) => player.id === Number(playerID));

  const playerStatistics = playerStatistics1
    .filter((stat) => stat.player_id === Number(playerID))
    .map((stat) => {
      const match = matches.find((m) => m.id === stat.match_id);
      const team = teams.find((t) => t.id === stat.team_id);
      return match && team
        ? {
            ...stat,
            match,
            team_name: team.team_name,
          }
        : null;
    })
    .filter(Boolean);

  const playedTeams = [
    ...new Set(
      playerStatistics
        .map((stat) => teams.find((team) => team.id === stat.team_id))
        .filter(Boolean) // undefined olanları kaldırır
    ),
  ];

  const [filteredStats, setFilteredStats] = useState(playerStatistics);
  const [selectedLeagues, setSelectedLeagues] = useState(["Tümü"]);
  const [selectedTeams, setSelectedTeams] = useState(["Tümü"]);

  // Lig seçimi değiştiğinde çalışacak fonksiyon
  const handleLeagueChange = (season) => {
    if (season === "Tümü") {
      setSelectedLeagues(["Tümü"]);
    } else {
      const newSelection = selectedLeagues.includes(season)
        ? selectedLeagues.filter((item) => item !== season)
        : [...selectedLeagues.filter((item) => item !== "Tümü"), season];
      setSelectedLeagues(
        newSelection.length === 0 ||
          newSelection.length ===
            seasons.filter(
              (season) =>
                formatTeamNameForURL(season.city) ===
                formatTeamNameForURL(cityName)
            ).length
          ? ["Tümü"]
          : newSelection
      );
    }
  };
  const handleTeamChange = (team) => {
    if (team === "Tümü") {
      setSelectedTeams(["Tümü"]);
    } else {
      const newSelection = selectedTeams.includes(team)
        ? selectedTeams.filter((item) => item !== team)
        : [...selectedTeams.filter((item) => item !== "Tümü"), team];
      setSelectedTeams(
        newSelection.length === 0 || newSelection.length === playedTeams.length
          ? ["Tümü"]
          : newSelection
      );
    }
  };

  const [playerStats, setPlayerStats] = useState(playerStatistics);

  useEffect(() => {
    if (playerStatistics.length > 0 && playerStats.length === 0) {
      setPlayerStats(playerStatistics);
    }
  }, [playerStatistics]);

  const [playerSummary, setPlayerSummary] = useState({
    wins: 0,
    losses: 0,
    draws: 0,
    totalPoints: 0,
    totalGoals: 0,
    totalValue: 0,
    yellowCards: 0,
    redCards: 0,
  });

  useEffect(() => {
    const filteredStatistics = playerStats.filter((stat) => {
      const matchLeague =
        selectedLeagues[0] === "Tümü" ||
        selectedLeagues.includes(
          stat.match.league_name + stat.match.match_season
        );
      const matchTeam =
        selectedTeams[0] === "Tümü" || selectedTeams.includes(stat.team_name);
      return matchLeague && matchTeam;
    });

    setFilteredStats(filteredStatistics);

    // Oyuncunun istatistiklerini özetleyen değerleri hesapla
    let wins = 0,
      losses = 0,
      draws = 0,
      totalPoints = 0;
    let totalGoals = 0,
      value = 0,
      yellowCards = 0,
      redCards = 0;

    filteredStatistics.forEach((stat) => {
      const {
        match,
        team_name,
        number_of_goals,
        points,
        yellow_card,
        red_card,
      } = stat;

      // Takımın maç sonucuna göre galibiyet, mağlubiyet veya beraberlik belirle
      if (match.first_team_name === team_name) {
        if (match.first_team_score > match.second_team_score) wins++;
        else if (match.first_team_score < match.second_team_score) losses++;
        else draws++;
      } else if (match.second_team_name === team_name) {
        if (match.second_team_score > match.first_team_score) wins++;
        else if (match.second_team_score < match.first_team_score) losses++;
        else draws++;
      }

      totalGoals += number_of_goals;
      if (yellow_card > 0) yellowCards += 1;
      if (red_card > 0) redCards += 1;
      totalPoints += points;
    });

    value =
      ((totalPoints / (wins + losses + draws)) * 100 +
        (wins + losses + draws) * 20 -
        yellowCards * 30 -
        redCards * 50) *
      70;

    setPlayerSummary({
      wins,
      losses,
      draws,
      totalPoints,
      totalGoals,
      value,
      yellowCards,
      redCards,
    });
  }, [selectedLeagues, selectedTeams, playerStats]);

  const barChartRef = useRef(null);
  const lineChartRef = useRef(null);
  let barChartInstance = useRef(null);
  let lineChartInstance = useRef(null);

  useEffect(() => {
    if (!filteredStats || filteredStats.length === 0) return;

    const recentStats = filteredStats.slice(-5);
    const matchLabels = recentStats.map(
      (stat, index) => `Maç ${stat.match_id}`
    );
    const goals = recentStats.map((stat) => stat.number_of_goals || 0);
    const points = recentStats.map((stat) => stat.points || 0);

    if (barChartInstance.current) barChartInstance.current.destroy();
    if (lineChartInstance.current) lineChartInstance.current.destroy();

    if (barChartRef.current) {
      barChartInstance.current = new Chart(barChartRef.current, {
        type: "bar",
        data: {
          labels: matchLabels,
          datasets: [
            {
              label: "Form Durumu",
              data: points,
              backgroundColor: "green",
              borderRadius: 5,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          onClick: (event, elements) => {
            if (elements.length > 0) {
              // Tıklanan etiketin index'ini al
              const index = elements[0].index;
              const matchId = recentStats[index].match_id;

              // Tıklanan maça yönlendirme işlemi
              window.location.href = `/${formatTeamNameForURL(
                cityName
              )}/${formatTeamNameForURL(leagueName)}/maclar/${matchId}`;
            }
          },
        },
      });
    }

    if (lineChartRef.current) {
      lineChartInstance.current = new Chart(lineChartRef.current, {
        type: "line",
        data: {
          labels: matchLabels,
          datasets: [
            {
              label: "Gol",
              data: goals,
              borderColor: "blue",
              borderWidth: 2,
              fill: false,
            },
            {
              label: "Puan",
              data: points,
              borderColor: "green",
              borderWidth: 2,
              fill: false,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    }
  }, [filteredStats]);

  const groupedStats = filteredStats.reduce((acc, stat) => {
    const teamName = stat.team_name;
    if (!acc[teamName]) {
      acc[teamName] = [];
    }
    acc[teamName].push(stat);
    return acc;
  }, {});


  const formatDate = (isoDate) => {
    if (!isoDate) {
      return "";
    }
    const months = [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ];

    const date = new Date(isoDate);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  if (!player) {
    return <p>Player not found.</p>;
  }
  return (
    <div className="body-p">
      <Header />
      <header className="header-banner-p">
        <img
          src={player.player_img}
          alt={player.player_name}
          className="player-img-p"
        />
        <h1 className="banner-title-p">{player.player_name}</h1>
        <h2 className="id-p">{player.id}</h2>
        <div className="zigzag-p">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 340">
            <path
              fill="#253165"
              fillOpacity="1"
              d="M0,256L48,224C96,192,192,128,288,96C384,64,480,64,576,96C672,128,768,192,864,213.3C960,235,1056,213,1152,213.3C1248,213,1344,235,1392,245.3L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
            ></path>
          </svg>
        </div>
      </header>

      <div className="player-profile-container">
        {/* Sol Üst - Oyuncu Kartı */}
        <div className="player-card-p">
          <div className="detail-row-p">
            <span className="detail-label-p">Mevki:</span>
            <span className="detail-value-p">{player.player_position}</span>
          </div>
          <div className="detail-row-p">
            <span className="detail-label-p">Yaş:</span>
            <span className="detail-value-p">
              {calculateAge(player.birth_date)}
            </span>
          </div>
          <div className="detail-row-p">
            <span className="detail-label-p">Uyruk:</span>
            <span className="detail-value-p">{player.nationality}</span>
          </div>
          <div className="detail-row-p">
            <span className="detail-label-p">Ana Takımı:</span>
            <span className="detail-value-p">{player.team_name}</span>
          </div>
          <div className="detail-row-p">
            <span className="detail-label-p">Oynadığı Maç:</span>
            <span className="detail-value-p">{player.total_matches}</span>
          </div>
          <div className="detail-row-p">
            <span className="detail-label-p">Attığı Gol:</span>
            <span className="detail-value-p">{player.total_goals}</span>
          </div>
        </div>

        {/* Sol Alt - İstatistik Kartı */}
        <div className="filter-card-p">
          <div className="filter-group-p">
            <h4>Lig/Sezona Göre</h4>
            <label>
              <input
                type="checkbox"
                checked={selectedLeagues.includes("Tümü")}
                onChange={() => handleLeagueChange("Tümü")}
              />
              Tümü
            </label>
            {seasons
              .filter(
                (season) =>
                  formatTeamNameForURL(season.city) ===
                  formatTeamNameForURL(cityName)
              )
              .map((season, index) => (
                <label key={index}>
                  <input
                    type="checkbox"
                    checked={selectedLeagues.includes(
                      season.league_name + season.season_name
                    )}
                    onChange={() =>
                      handleLeagueChange(
                        season.league_name + season.season_name
                      )
                    }
                  />
                  {season.league_name} {season.season_name}
                </label>
              ))}
          </div>

          <div className="filter-group-p">
            <h4>Takıma Göre</h4>
            <label>
              <input
                type="checkbox"
                checked={selectedTeams.includes("Tümü")}
                onChange={() => handleTeamChange("Tümü")}
              />
              Tümü
            </label>
            {playedTeams.map((team, index) => (
              <label key={index}>
                <input
                  type="checkbox"
                  checked={selectedTeams.includes(team.team_name)}
                  onChange={() => handleTeamChange(team.team_name)}
                />
                <img
                  src={team.logo}
                  alt={team.team_name}
                  className="team-logo-p"
                />
                {team.team_name}
              </label>
            ))}
          </div>
        </div>

        {/* Sağ Büyük Kart - Detaylı İstatistikler */}
        <div className="detailed-card-p">
          <div className="stats-content-p">
            <div className="stats-summary-p">
              <div className="stat-card-p">
                <h3>Değeri</h3>
                <p>{playerSummary.value}</p>
              </div>
              <div className="stat-card-p">
                <h3>Toplam Puan</h3>
                <p>{playerSummary.totalPoints}</p>
              </div>
              <div className="stat-card-p">
                <h3>Toplam Goller</h3>
                <p>{playerSummary.totalGoals}</p>
              </div>
              <div className="stat-card-p">
                <h3>Galibiyet</h3>
                <p>{playerSummary.wins}</p>
              </div>
              <div className="stat-card-p">
                <h3>Mağlubiyet</h3>
                <p>{playerSummary.losses}</p>
              </div>
              <div className="stat-card-p">
                <h3>Beraberlik</h3>
                <p>{playerSummary.draws}</p>
              </div>

              <div className="stat-card-p">
                <h3>Sarı Kart</h3>
                <p>{playerSummary.yellowCards}</p>
              </div>
              <div className="stat-card-p">
                <h3>Kırmızı Kart</h3>
                <p>{playerSummary.redCards}</p>
              </div>
            </div>
            <div className="detailed-stats-p">
              <div className="stat-item-p">
                <h4>Galibiyet Oranı</h4>
                <div className="stat-bar-p">
                  <div
                    className="progress-p"
                    style={{
                      width: `${
                        (playerSummary.wins /
                          (playerSummary.wins +
                            playerSummary.losses +
                            playerSummary.draws)) *
                        100
                      }%`,
                    }}
                  ></div>
                </div>
              </div>
              <div className="stat-item-p">
                <h4>Beraberlik Oranı</h4>
                <div className="stat-bar-p">
                  <div
                    className="progress-p"
                    style={{
                      width: `${
                        (playerSummary.draws /
                          (playerSummary.wins +
                            playerSummary.losses +
                            playerSummary.draws)) *
                        100
                      }%`,
                    }}
                  ></div>
                </div>
              </div>
              <div className="stat-item-p">
                <h4>Maç Başına Gol</h4>
                <div className="stat-bar-p">
                  <div
                    className="progress-p"
                    style={{
                      width: `${
                        (playerSummary.totalGoals /
                          (playerSummary.wins +
                            playerSummary.losses +
                            playerSummary.draws)) *
                        100
                      }%`,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div className="chart-container">
            <div className="chart-box">
              <h3>Form Durumu</h3>
              <canvas ref={barChartRef}></canvas>
            </div>
            <div className="chart-box">
              <h3>Gol & Puan</h3>
              <canvas ref={lineChartRef}></canvas>
            </div>
          </div>

          <div className="match-list-p">
            <h3 style={{ textAlign: "center" }}>Yapılan Maçlar</h3>
            {Object.entries(groupedStats).map(([teamName, stats]) => (
              <div
                key={teamName}
                style={{ marginBottom: "20px" }}
                className="match-list-div-p"
              >
                <h3>{teamName}</h3>
                <table border="1" width="100%">
                  <thead>
                    <tr>
                      <th>Tarih</th>
                      <th>Sezon & Lig</th>
                      <th>Rakip Takım</th>
                      <th>Sonuç</th>
                      <th>Goller</th>
                      <th>Kırmızı & Sarı Kart</th>
                      <th>Kazanılan Puan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stats.map((stat) => {
                      const match = stat.match;
                      const isFirstTeam =
                        match.first_team_name === stat.team_name;
                      const opponentName = isFirstTeam
                        ? match.second_team_name
                        : match.first_team_name;
                      const opponentScore = isFirstTeam
                        ? match.second_team_score
                        : match.first_team_score;
                      const teamScore = isFirstTeam
                        ? match.first_team_score
                        : match.second_team_score;
                      const opponentTeam = teams.find(
                        (t) => t.team_name === opponentName
                      );

                      return (
                        <tr key={match.id}>
                          <td>{formatDate(match.date)}</td>
                          <td>
                            {match.match_season} - {match.league_name}
                          </td>

                          <td>
                            <div className="opponent-team-p">
                              <img
                                src={opponentTeam.logo}
                                alt={opponentName}
                                className="opponent-team-logo-p"
                              />
                              <span>{opponentName}</span>
                            </div>
                          </td>
                          <td>
                            {opponentScore}-{teamScore}
                          </td>
                          <td>{stat.goals_minutes || "-"}</td>
                          <td>
                            {stat.yellow_card > 0
                              ? stat.yellow_card + "🟨"
                              : "-"}{" "}
                            {stat.red_card > 0 ? stat.red_card + `🟥` : "-"}
                          </td>

                          <td>{stat.points}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
function formatTeamNameForURL(teamName) {
  return teamName
    .toLowerCase()
    .replace(/ç/g, "c")
    .replace(/ğ/g, "g")
    .replace(/ı/g, "i")
    .replace(/ö/g, "o")
    .replace(/ş/g, "s")
    .replace(/ü/g, "u")
    .replace(/[^a-z0-9 ]/g, "")
    .trim()
    .replace(/\s+/g, "-");
}
const formatValue = (value) => {
  if (value >= 1000) {
    return `${(value / 1000).toFixed(1)}K`;
  }
  return value;
};
const calculateAge = (birthDate) => {
  if (!birthDate) return "Bilinmiyor";
  const today = new Date();
  const birth = new Date(birthDate);
  let age = today.getFullYear() - birth.getFullYear();
  const monthDiff = today.getMonth() - birth.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
    age--; // Doğum günü henüz gelmediyse yaş 1 azaltılır
  }
  return age;
};
export default PlayerPage;
