import React, { useState, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/editor_logo.png";
import { useUser } from "../UserContext";
import axios from "axios";
import "./EditorMenu.css";

const EditorMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const userCity = user?.city;
  const [menuOpen, setMenuOpen] = useState(false); // Menü açma/kapatma kontrolü
  const toggleMenu = () => setMenuOpen(!menuOpen); // Menü toggle fonksiyonu

  const menuItems = [
    { name: "Ana Sayfa", path: "/" },
    { name: "Genel Bakış", path: "/editor" },
    { name: "Ligler", path: "/editor/ligler" },
    { name: "Sezonlar", path: "/editor/sezonlar" },
    { name: "Takımlar", path: "/editor/takimlar" },
    { name: "Oyuncular", path: "/editor/oyuncular" },
    { name: "Maçlar", path: "/editor/maclar" },
    { name: "Yazı Ekle", path: "/editor/yazi-ekle" },
  ];

  const adminItems = [{ name: "Kullanıcı Ekle", path: "/editor/kullanici-ekle" }];

  const handleLogout = async () => {
    try {
      await axios.post("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/api/users/logout", {}, { withCredentials: true });
      localStorage.removeItem('user'); // Cache temizle
      setUser(null);
      navigate("/");
    } catch (error) {
      console.error("Çıkış hatası:", error);
    }
  };

  return (
    <>
      {/* Menü Butonu */}
      <div className="menu-toggle" onClick={toggleMenu}>
        <span className="menu-icon">☰</span>
      </div>

     

      {/* Menü Bar */}
      <div className={`editor-menu-bar ${menuOpen ? "open" : ""}`}>

   
        

        <div className="logo-container2">
          <img src={logo} alt="Elitlig Logo" className="logo2" />
        </div>

 {/* Kullanıcının Şehri */}
        <div className="user-city">
          📍 {userCity}
        </div>

        {menuItems.map((item, index) => (
          <Link
            key={index}
            to={item.path}
            className={`menu-item ${location.pathname === item.path ? "active" : ""}`}
            onClick={toggleMenu} // Menüden bir linke tıklayınca menüyü kapatır
          >
            {item.name}
          </Link>
        ))}

        {/* Admin-only Links */}
        {user?.role === "admin" &&
          adminItems.map((item, index) => (
            <Link
              key={index}
              to={item.path}
              className={`menu-item ${location.pathname === item.path ? "active" : ""}`}
              onClick={toggleMenu}
            >
              {item.name}
            </Link>
          ))}

        {/* Logout Button */}
        <button onClick={handleLogout} className="logout-button">
          Çıkış Yap
        </button>
      </div>
    </>
  );
};

export default EditorMenu;
