import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

// DataContext oluştur
const DataContext = createContext();

// DataProvider bileşeni
export const DataProvider = ({ children }) => {
  const [data, setData] = useState({
    matches: [],
    lastFiveMatches: [],
    players: [],
    teams: [],
    playerStatistics1: [],
    leagues: [],
    seasons: [],
  }); // Tüm veriler burada saklanır
  const [loading, setLoading] = useState(true); // Yüklenme durumu
  const [error, setError] = useState(null); // Hata durumu

  // Veritabanından tüm verileri al
  const fetchData = async () => {
    try {
      setLoading(true);
      const [
        matchesRes,
        lastFiveMatchesRes,
        playersRes,
        teamsRes,
        playerStatisticsRes,
        leaguesRes,
        seasonsRes,
      ] = await Promise.all([
        axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar"),
        axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar/last-five"),
        axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/"),
        axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/"),
        axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-istatistikleri/"),
        axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/ligler"),
        axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/sezonlar"),
      ]);

      setData({
        matches: matchesRes.data,
        lastFiveMatches: lastFiveMatchesRes.data,
        players: playersRes.data,
        teams: teamsRes.data,
        playerStatistics1: playerStatisticsRes.data,
        leagues: leaguesRes.data,
        seasons: seasonsRes.data,
      });
    } catch (err) {
      console.error("Veri çekme hatası:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Uygulama yüklendiğinde bir kez çalışır
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DataContext.Provider
      value={{
        ...data,
        loading,
        error,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

// Context kullanımı kolaylaştır
export const useData = () => useContext(DataContext);
