import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import logo from "../assets/elitLig_logo.png";
import logo2 from "../assets/team_logo2.png";
import "./style.css";
import defaultPlayerImage from "../assets/defaultPlayer.png";
import { useNavigate, useParams } from "react-router-dom";
import field from "../assets/football_field.jpg";
import { useData } from "./Cache";

const MatchPage = () => {
  const { matchID, leagueName, cityName } = useParams();
  const { matches, players, playerStatistics1, loading, teams, error } = useData();

  // Hook'ları koşulsuz çağırıyoruz
  const [matchResults, setMatchResults] = useState(null);
  const [activeTab, setActiveTab] = useState("tab1");
  const [cards, setCards] = useState({ red: {}, yellow: {}, goal: {} });
  const [PlayerStatistics, setPlayerStatistics] = useState([]);

  // Match'i ve ilgili bilgileri bul
  const match = matches.find((match) => match.id === Number(matchID));

  if (match) {
  // Takımların logolarını bul
  const firstTeam = teams.find((team) => team.team_name === match.first_team_name);
  const secondTeam = teams.find((team) => team.team_name === match.second_team_name);

  // Maça logoları ekle
  match.first_team_logo = firstTeam ? firstTeam.logo : null;
  match.second_team_logo = secondTeam ? secondTeam.logo : null;
}

  const mvp = match ? players.find((player) => player.id === match.match_mvp) : null;

  // Oynayan takımları ve ilgili maçları filtrele
  const { first_team_name, second_team_name } = match || {};
  const relatedMatches = matches
  .filter(
    (m) =>
      m.is_it_fixture === false &&
      ((m.first_team_name === first_team_name && m.second_team_name === second_team_name) ||
        (m.first_team_name === second_team_name && m.second_team_name === first_team_name))
  )
  .map((match) => {
    // Logoları ekle
    const firstTeam = teams.find((team) => team.team_name === match.first_team_name);
    const secondTeam = teams.find((team) => team.team_name === match.second_team_name);

    return {
      ...match,
      first_team_logo: firstTeam ? firstTeam.logo : null,
      second_team_logo: secondTeam ? secondTeam.logo : null,
    };
  });

  useEffect(() => {
    // Maç sonuçlarını hesaplama fonksiyonu
    const calculateResults = async () => {
      if (matches && first_team_name && second_team_name) {
        const results = calculateMatchResults(matches, first_team_name, second_team_name);
        setMatchResults(results);
      }
    };

    // Oyuncu istatistiklerini getirme fonksiyonu
    const fetchPlayerStatistics = async () => {
      try {
        const statistics = playerStatistics1.filter((stat) => stat.match_id === Number(matchID));

        const playerDetails = statistics.map((stat) => {
          const player = players.find((p) => p.id === stat.player_id) || {};
          const team = teams.find((t) => t.id === stat.team_id) || {};

          return {
            ...stat,
            player,
            team,
          };
        });

        const cards = playerDetails.reduce(
          (acc, stat) => {
            const teamName = stat.team?.team_name || "Unknown Team";
            acc.red[teamName] = (acc.red[teamName] || 0) + (stat.red_card || 0);
            acc.yellow[teamName] = (acc.yellow[teamName] || 0) + (stat.yellow_card || 0);
            acc.goal[teamName] = (acc.goal[teamName] || 0) + (stat.goal_to_himself || 0);
            return acc;
          },
          { red: {}, yellow: {}, goal: {} }
        );

        setCards(cards);
        setPlayerStatistics(playerDetails);
      } catch (error) {
        console.error("Error fetching player statistics:", error);
      }
    };

    calculateResults();
    fetchPlayerStatistics();
  }, [matches, first_team_name, second_team_name, playerStatistics1, players, teams, matchID]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const formatTime = (timeString) => {
    const time = timeString.slice(0, 5);
    return `${time}`;
  };

  if (!match) {
    return <p>Match not found.</p>;
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <Header />
      <div className="layout-container">
        {/* Sol Üst Tablo */}
        <div className="table table-top">
          <table className="info-table-match">
            <tbody>
              <tr>
                <td
                  onClick={() =>
                    (window.location.href = `/${formatTeamNameForURL(
                      match.league_name
                    )}/sonuclar`)
                  }
                  style={{ cursor: "pointer" }}
                >
                  <b>Lig Tablosu</b>
                </td>
              </tr>
              <tr>
                <td
                  onClick={() => (window.location.href = `/duyurular`)}
                  style={{ cursor: "pointer" }}
                >
                  <b>Duyurular</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Sol Alt Tablo */}
        <div className="table table-bottom">
          <table>
            <thead>
              <tr>
                <th>
                  {match?.league_name
                    ? match.league_name.charAt(0).toUpperCase() +
                      match.league_name.slice(1)
                    : "Veri yükleniyor..."}{" "}
                  Diğer Maçlar
                </th>
              </tr>
            </thead>
            <tbody>
              {matches
                .filter(
                  (currentMatch) =>
                    currentMatch.league_name === match.league_name &&
                    currentMatch.is_it_fixture === false
                )
                .slice(0, 3)
                .map((currentMatch) => (
                  <tr key={currentMatch.id}>
                    <td>
                      <a href={`/${formatTeamNameForURL(cityName)}/${formatTeamNameForURL(leagueName)}/maclar/${currentMatch.id}`}>
                        <b style={{ textAlign: "right" }}>
                          {currentMatch.first_team_name
                            .substring(0, 4)
                            .toUpperCase()}
                        </b>
                        <div className="score-match">
                          {currentMatch.first_team_score} -
                          {currentMatch.second_team_score}
                        </div>
                        <b style={{ textAlign: "left" }}>
                          {currentMatch.second_team_name
                            .substring(0, 4)
                            .toUpperCase()}
                        </b>
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th>
                  {match?.league_name
                    ? match.league_name.charAt(0).toUpperCase() +
                      match.league_name.slice(1)
                    : "Veri yükleniyor..."}{" "}
                  Fikstür
                </th>
              </tr>
            </thead>
            <tbody>
              {matches
                .filter(
                  (currentMatch) =>
                    currentMatch.league_name === match.league_name &&
                    currentMatch.is_it_fixture === true
                )
                .slice(0, 3)
                .map((currentMatch) => (
                  <tr key={currentMatch.id}>
                    <td>
                      <a href={`/${formatTeamNameForURL(cityName)}/${formatTeamNameForURL(leagueName)}/maclar/${currentMatch.id}`}>
                        <b style={{ textAlign: "right" }}>
                          {currentMatch.first_team_name
                            .substring(0, 4)
                            .toUpperCase()}
                        </b>
                        <div className="score-match">
                          {formatTime(currentMatch.time)}
                        </div>
                        <b style={{ textAlign: "left" }}>
                          {currentMatch.second_team_name
                            .substring(0, 4)
                            .toUpperCase()}
                        </b>
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        {/* Sağ Üst Büyük Kutu */}
        <div className="large-box-match">
          {/* Üst Kısım: Takım Bilgileri ve Skor */}
          <div className="match-header-match">
            <div
              className="team-match"
              onClick={() =>
                (window.location.href = `/${formatTeamNameForURL(cityName)}/${formatTeamNameForURL(leagueName)}/takimlar/${formatTeamNameForURL(
                  match.first_team_name
                )}`)
              }
            >
              <img
                src={match.first_team_logo}
                alt={match.first_team_name}
                className="team-logo-match"
              />
              <span>{match.first_team_name}</span>
            </div>
            <div className="score-match-2">
              <div className="score-box-match">
                <span>{match.first_team_score}</span> -{" "}
                <span>{match.second_team_score}</span>
              </div>
            </div>
            <div
              className="team-match"
              onClick={() =>
                (window.location.href = `/${formatTeamNameForURL(cityName)}/${formatTeamNameForURL(leagueName)}/takimlar/${formatTeamNameForURL(
                  match.second_team_name
                )}`)
              }
            >
              <span>{match.second_team_name}</span>
              <img
                src={match.second_team_logo}
                alt={match.second_team_name}
                className="team-logo-match"
              />
            </div>
          </div>

          {/* Orta Kısım: Oyuncu Golleri */}

          {/* Alt Kısım: Zaman Çizgisi */}
          <div className="match-timeline-match">
            <div className="team-logos-timeline">
              <img
                src={match.first_team_logo}
                alt={match.first_team_name}
                className="team-logo-timeline"
              />
              <img
                src={match.second_team_logo}
                alt={match.second_team_name}
                className="team-logo-timeline"
              />
            </div>

            {PlayerStatistics.filter((p) => p.number_of_goals !== 0).map(
              (player) =>
                player.goals_minutes.split(" ").map((minute, index) => {
                  const positionStyle = (minute / 50) * 100; // Pozisyonu hesapla
                  const isFirstTeam =
                    player.team.team_name === match.first_team_name;

                  return (
                    <div
                      key={index}
                      className={`goal-marker ${
                        isFirstTeam ? "first-team" : "second-team"
                      }`}
                      style={{
                        left: `${positionStyle}%`,
                        position: "absolute",
                      }}
                    >
                      {/* Top ibaresi */}
                      <div className="goal-ball"></div>

                      {/* Oyuncu ismini hover ile gösteren tooltip */}
                      <div className="goal-tooltip">
                        {player.player.player_name}
                      </div>
                    </div>
                  );
                })
            )}

            <div className="timeline-line"></div>
          </div>

          <div className="match-goals-match">
            <div className="goals-list left">
              {PlayerStatistics.filter(
                (p) =>
                  p.team.team_name === match.first_team_name &&
                  p.number_of_goals !== 0
              ).map((p, index) => (
                <span
                  key={index}
                  onClick={() =>
                    (window.location.href = `/${formatTeamNameForURL(cityName)}/${formatTeamNameForURL(leagueName)}/oyuncular/${p.player.id}`)
                  }
                >
                  {p.player.player_name} {p.goals_minutes}'
                </span>
              ))}
            </div>
            <div className="goals-list right">
              {PlayerStatistics.filter(
                (p) =>
                  p.team.team_name === match.second_team_name &&
                  p.number_of_goals !== 0
              ).map((p, index) => (
                <span
                  key={index}
                  style={{ textAlign: "right" }}
                  onClick={() =>
                    (window.location.href = `/${formatTeamNameForURL(cityName)}/${formatTeamNameForURL(leagueName)}/oyuncular/${p.player.id}`)
                  }
                >
                  {p.goals_minutes}' {p.player.player_name}
                </span>
              ))}
            </div>
          </div>
        </div>

        <div className="match-highlights-container">
          {match.match_video && (
            <a
              href={match.match_video}
              target="_blank"
              rel="noopener noreferrer"
              className="highlight-card"
            >
              <img
                src={`https://img.youtube.com/vi/${extractVideoID(
                  match.match_video
                )}/hqdefault.jpg`}
                alt={match.match_title}
                className="highlight-thumbnail"
              />
              <div className="highlight-info">
                <span className="platform">YouTube</span>
                <h4>Maç Kaydı | Elit Lig</h4>
              </div>
            </a>
          )}

          {match.match_interview && (
            <a
              href={match.match_interview}
              target="_blank"
              rel="noopener noreferrer"
              className="highlight-card"
            >
              <img
                src={`https://img.youtube.com/vi/${extractVideoID(
                  match.match_interview
                )}/hqdefault.jpg`}
                alt={match.match_title}
                className="highlight-thumbnail"
              />
              <div className="highlight-info">
                <span className="platform">YouTube</span>
                <h4>Maç Röportajı | Elit Lig</h4>
              </div>
            </a>
          )}
        </div>

        {/* Alt Sekmeler (Tabs) */}
        <div className="tabs-container">
          <div className="tabs-buttons">
            <button
              className={activeTab === "tab1" ? "active" : ""}
              onClick={() => setActiveTab("tab1")}
            >
              Latest
            </button>
            <button
              className={activeTab === "tab2" ? "active" : ""}
              onClick={() => setActiveTab("tab2")}
            >
              Oyuncular
            </button>
            <button
              className={activeTab === "tab3" ? "active" : ""}
              onClick={() => setActiveTab("tab3")}
            >
              İstatistikler
            </button>
          </div>
          <div className="tabs-content">
            <TabContent
              activeTab={activeTab}
              match={match}
              mvp={mvp}
              PlayerStatistics={PlayerStatistics}
              cards={cards}
              matchResults={matchResults}
              relatedMatches={relatedMatches}
              cityName={cityName}
              leagueName={leagueName}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const formatDate = (isoDate) => {
  if (!isoDate) {
    return "";
  }
  const months = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ];

  const date = new Date(isoDate); // Tarih objesi oluştur
  const day = date.getDate(); // Gün
  const month = months[date.getMonth()]; // Ay (yazıyla)
  const year = date.getFullYear(); // Yıl

  return `${day} ${month} ${year}`;
};
const TabContent = ({
  activeTab,
  match,
  mvp,
  PlayerStatistics,
  cards,
  matchResults,
  relatedMatches,
  cityName,
  leagueName
}) => {
  const [activeTab2, setActiveTab2] = useState("stats");

  const handleTabChange2 = (tab) => {
    setActiveTab2(tab);
  };
  switch (activeTab) {
    case "tab1":
      return (

        <div className="tab-content-1">
          {/* Sol tarafta Man of the Match */}
          <div>
            <div className="man-of-the-match">
              <div className="motm-header">
                <p>Elitlig</p>
                <span>
                  {match.league_name} {match.match_season}
                </span>
              </div>
              <div className="motm-player">
                <img
                  src={mvp?.player_img} // Oyuncu fotoğrafı
                  alt={mvp?.player_name}
                />
                <h2>
                  <b>{mvp?.player_name}</b>
                </h2>
                <p>Maçın Oyuncusu</p>
              </div>
              <button
                className="view-more-btn"
                onClick={() => (window.location.href = `/${formatTeamNameForURL(cityName)}/${formatTeamNameForURL(leagueName)}/oyuncular/${mvp?.id}`)}
              >
                Profili Görüntüle →
              </button>



                  
            </div>
            <div className="match-comment-container">
              <h3 className="comment-header">MAÇ RAPORU</h3>
              <div className="match-comment">
                {match.match_comment
                  ? match.match_comment
                  : "Maç yorumu henüz eklenmedi."}
              </div>
            </div>
          </div>



          {/* Sağdaki Küçük Kutular */}
          <div className="small-boxes">
            <div className="small-box">
              <img src={match.match_picture} alt={match.match_title} />
            </div>
          </div>

                

        </div>
      );
    case "tab2":
      return (
        <div className="tab-content-2">
          <div className="lineup-container">
            {/* Sol Taraf - Takım 1 Oyuncu Listesi */}
            <div className="team-players left-team">
              <h2>{match.first_team_name} Oyuncuları</h2>
              <h3>Kaleci</h3>
              {PlayerStatistics.filter(
                (player) =>
                  player.player_position === "kaleci" &&
                  player.team.team_name === match.first_team_name
              ).map((player, index) => (
                <Player key={player.id || index} player={player} cityName={cityName} leagueName={leagueName}/>
              ))}

              <h3>Defans</h3>
              {PlayerStatistics.filter(
                (player) =>
                  player.player_position === "defans" &&
                  player.team.team_name === match.first_team_name
              ).map((player, index) => (
                <Player key={player.id || index} player={player} cityName={cityName} leagueName={leagueName}/>
              ))}

              <h3>Orta Saha</h3>
              {PlayerStatistics.filter(
                (player) =>
                  player.player_position === "orta saha" &&
                  player.team.team_name === match.first_team_name
              ).map((player, index) => (
                <Player key={player.id || index} player={player} cityName={cityName} leagueName={leagueName}/>
              ))}

              <h3>Forvet</h3>
              {PlayerStatistics.filter(
                (player) =>
                  player.player_position === "forvet" &&
                  player.team.team_name === match.first_team_name
              ).map((player, index) => (
                <Player key={player.id || index} player={player} cityName={cityName} leagueName={leagueName}/>
              ))}
            </div>

            {/* Orta Kısım - Oyuncu Dizilişi */}
            <div className="field-container">
              <img src={field} alt="Football Field" className="field-image" />

              {/* Birinci Takımın Logosu */}
              <img
                src={match.first_team_logo}
                alt={match.first_team_name}
                className="team-logo-field top-logo"
              />

              {/* Birinci Takım Oyuncuları */}
              {PlayerStatistics.filter(
                (player) => player.team.team_name === match.first_team_name
              ).map((player, index) => {
                const positionMap = {
                  kaleci: "5% 47%", // Kaleci sabit ortada
                  forvet: "43% 47%", // Forvet sabit ortada
                  genel: [
                    "16% 17%",
                    "16% 37%",
                    "16% 57%",
                    "16% 77%",
                    "30% 23%",
                    "30% 43%",
                  ],
                };

                let positionStyle;

                // Kaleci ve Forvet için özel konum
                if (player.player_position === "kaleci") {
                  positionStyle = positionMap.kaleci;
                } else if (player.player_position === "forvet") {
                  positionStyle = positionMap.forvet;
                } else {
                  // Defans ve Orta Saha oyuncuları sırayla genel dizilimde
                  positionStyle =
                    positionMap.genel[index % positionMap.genel.length];
                }

                // Eğer pozisyon uygun değilse gösterme
                if (!positionStyle) return null;

                return (
                  <div
                    key={player.id}
                    className="position-t2 first-team"
                    style={{
                      top: positionStyle.split(" ")[0],
                      left: positionStyle.split(" ")[1],
                    }}
                    onClick={() =>
                      (window.location.href = `/${formatTeamNameForURL(cityName)}/${formatTeamNameForURL(leagueName)}/oyuncular/${player.player.id}`)
                    }
                  >
                    <img
                      src={player.player?.player_img}
                      alt={player.player.player_name}
                      className="player-image-t2"
                    />
                    <div className="player-name-t2">
                      {player.player.player_name}
                    </div>
                  </div>
                );
              })}

              {/* İkinci Takım Oyuncuları */}
              {PlayerStatistics.filter(
                (player) => player.team.team_name === match.second_team_name
              ).map((player, index) => {
                const positionMap = {
                  kaleci: "5% 47%", // Kaleci sabit ortada
                  forvet: "43% 47%", // Forvet sabit ortada
                  genel: [
                    "16% 17%",
                    "16% 37%",
                    "16% 57%",
                    "16% 77%",
                    "30% 23%",
                    "30% 43%",
                  ],
                };

                let positionStyle;

                // Kaleci ve Forvet için özel konum
                if (player.player_position === "kaleci") {
                  positionStyle = positionMap.kaleci;
                } else if (player.player_position === "forvet") {
                  positionStyle = positionMap.forvet;
                } else {
                  // Defans ve Orta Saha oyuncuları sırayla genel dizilimde
                  positionStyle =
                    positionMap.genel[index % positionMap.genel.length];
                }

                // Eğer pozisyon uygun değilse gösterme
                if (!positionStyle) return null;

                return (
                  <div
                    key={player.id}
                    className="position-t2 second-team"
                    style={{
                      bottom: positionStyle.split(" ")[0],
                      left: positionStyle.split(" ")[1],
                    }}
                    onClick={() =>
                      (window.location.href = `/${formatTeamNameForURL(cityName)}/${formatTeamNameForURL(leagueName)}/oyuncular/${player.player.id}`)
                    }
                  >
                    <img
                      src={player.player?.player_img}
                      alt={player.player.player_name}
                      className="player-image-t2"
                    />
                    <div className="player-name-t2">
                      {player.player.player_name}
                    </div>
                  </div>
                );
              })}

              {/* İkinci Takımın Logosu */}
              <img
                src={match.second_team_logo}
                alt={match.second_team_name}
                className="team-logo-field bottom-logo"
              />
            </div>

            {/* Sağ Taraf - Takım 2 Oyuncu Listesi */}
            <div className="team-players right-team">
              <h2>{match.second_team_name} Oyuncuları</h2>
              <h3>Kaleci</h3>
              {PlayerStatistics.filter(
                (player) =>
                  player.player_position === "kaleci" &&
                  player.team.team_name === match.second_team_name
              ).map((player, index) => (
                <Player key={player.id || index} player={player} cityName={cityName} leagueName={leagueName}/>
              ))}

              <h3>Defans</h3>
              {PlayerStatistics.filter(
                (player) =>
                  player.player_position === "defans" &&
                  player.team.team_name === match.second_team_name
              ).map((player, index) => (
                <Player key={player.id || index} player={player} cityName={cityName} leagueName={leagueName}/>
              ))}

              <h3>Orta Saha</h3>
              {PlayerStatistics.filter(
                (player) =>
                  player.player_position === "orta saha" &&
                  player.team.team_name === match.second_team_name
              ).map((player, index) => (
                <Player key={player.id || index} player={player} cityName={cityName} leagueName={leagueName}/>
              ))}

              <h3>Forvet</h3>
              {PlayerStatistics.filter(
                (player) =>
                  player.player_position === "forvet" &&
                  player.team.team_name === match.second_team_name
              ).map((player, index) => (
                <Player key={player.id || index} player={player} cityName={cityName} leagueName={leagueName}/>
              ))}
            </div>
          </div>
        </div>
      );

    case "tab3":
      const totalMatch =
        matchResults.firstTeamWins +
        matchResults.secondTeamWins +
        matchResults.draws;
      const team1Stats = calculateTeamStats(
        matchResults.firstTeamMatches,
        match.first_team_name,
        match.league_name
      );
      const team2Stats = calculateTeamStats(
        matchResults.secondTeamMatches,
        match.second_team_name,
        match.league_name
      );
      return (
        <div className="tab-content-3">
          {/* Sekme Butonları */}
          <div className="tabs-header">
            <button
              className={`tab-button ${
                activeTab2 === "head-to-head" ? "active" : ""
              }`}
              onClick={() => setActiveTab2("head-to-head")}
            >
              Head-to-Head
            </button>
            <button
              className={`tab-button ${activeTab2 === "stats" ? "active" : ""}`}
              onClick={() => setActiveTab2("stats")}
            >
              Match Stats
            </button>
          </div>

          {/* Sekme İçeriği */}
          {activeTab2 === "stats" && (
            <div className="match-stats-container">
              <h3 className="match-stats-title-match">Match Stats</h3>
              <table className="stats-table-match">
                <thead>
                  <tr>
                    <th className="team-header-match">
                      <strong>{match.first_team_name}</strong>
                      <img
                        src={match.first_team_logo}
                        alt={match.first_team_name}
                        className="team-logo-match-3"
                      />
                    </th>
                    <th></th>
                    <th className="team-header-match">
                      <strong>{match.second_team_name}</strong>
                      <img
                        src={match.second_team_logo}
                        alt={match.second_team_name}
                        className="team-logo-match-3"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {[
                    {
                      label: "Topla Oynama Yüzdesi %",
                      team1: match.first_team_percentage,
                      team2: match.second_team_percentage,
                    },
                    {
                      label: "Kırmızı Kart",
                      team1: cards.red[match.first_team_name],
                      team2: cards.red[match.second_team_name],
                    },
                    {
                      label: "Sarı Kart",
                      team1: cards.yellow[match.first_team_name],
                      team2: cards.yellow[match.first_team_name],
                    },
                    {
                      label: "Skor",
                      team1: match.first_team_score,
                      team2: match.second_team_score,
                    },
                    {
                      label: "Kendi Kalesine Gol",
                      team1: cards.goal[match.first_team_name],
                      team2: cards.goal[match.second_team_name],
                    },
                  ].map((stat, index) => (
                    <tr key={index}>
                      <td>
                        <strong>{stat.team1}</strong>
                      </td>
                      <td>{stat.label}</td>
                      <td>
                        <strong>{stat.team2}</strong>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {activeTab2 === "head-to-head" && (
            <div className="head-to-head-container">
              {/* Head to Head Başlık */}
              <h3 className="section-title-head-to-head">Head-to-Head</h3>

              {/* Dört Kutu */}
              <div className="stat-boxes-head-to-head">
                <div className="head-to-head">
                  {/* Üst Kısım: Takım İsimleri ve Logolar */}
                  <div className="head-to-head-header">
                    <div className="team-name-match">
                      <h4>{match.first_team_name}</h4>
                      <img
                        src={match.first_team_logo}
                        alt={match.first_team_name}
                      />
                    </div>
                    <div className="team-name-match">
                      <img
                        src={match.second_team_logo}
                        alt={match.second_team_name}
                      />
                      <h4>{match.second_team_name}</h4>
                    </div>
                  </div>

                  {/* Alt Kısım: Bar Grafikleri */}
                  <div className="stats-container-match">
                    {/* Sol Takım */}
                    <div className="team-stats-match">
                      <div className="bar-stat-match">
                        <div className="bar-match" style={{ direction: "rtl" }}>
                          <div
                            className="filled-bar-match"
                            style={{
                              width: `${
                                (matchResults.firstTeamWins / totalMatch) * 100
                              }%`,
                            }}
                          ></div>
                        </div>
                        <span>{matchResults.firstTeamWins} Zaferler</span>
                      </div>

                      <div className="bar-stat-match">
                        <div className="bar-match" style={{ direction: "rtl" }}>
                          <div
                            className="filled-bar-match"
                            style={{
                              width: `${
                                (matchResults.secondTeamWins / totalMatch) * 100
                              }%`,
                            }}
                          ></div>
                        </div>
                        <span>{matchResults.secondTeamWins} Yenilgi</span>
                      </div>

                      <div className="bar-stat-match">
                        <div className="bar-match" style={{ direction: "rtl" }}>
                          <div
                            className="filled-bar-match"
                            style={{
                              width: `${
                                (matchResults.draws / totalMatch) * 100
                              }%`,
                            }}
                          ></div>
                        </div>
                        <span>{matchResults.draws} Berabere</span>
                      </div>
                    </div>

                    {/* Orta Kısım */}
                    <div className="center-stats-match">
                      <div className="played-match">{totalMatch}</div>
                      <div className="draws-match">Toplam Maç</div>
                    </div>

                    {/* Sağ Takım */}
                    <div className="team-stats-match">
                      <div className="bar-stat-match">
                        <span>Zaferler {matchResults.secondTeamWins}</span>
                        <div className="bar-match">
                          <div
                            className="filled-bar-match"
                            style={{
                              width: `${
                                (matchResults.secondTeamWins / totalMatch) * 100
                              }%`,
                            }}
                          ></div>
                        </div>
                      </div>

                      <div className="bar-stat-match">
                        <span>Yenilgi {matchResults.firstTeamWins}</span>
                        <div className="bar-match">
                          <div
                            className="filled-bar-match"
                            style={{
                              width: `${
                                (matchResults.firstTeamWins / totalMatch) * 100
                              }%`,
                            }}
                          ></div>
                        </div>
                      </div>

                      <div className="bar-stat-match">
                        <span>Berabere {matchResults.draws}</span>
                        <div className="bar-match">
                          <div
                            className="filled-bar-match"
                            style={{
                              width: `${
                                (matchResults.draws / totalMatch) * 100
                              }%`,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="stat-box-head-to-head recent-meetings">
                  <h4 className="section-title-rm">Son Karşılaşmalar</h4>
                  <div className="meeting-list-rm">
                    {/* Tekil Maç Kartı */}
                    {relatedMatches
                      .filter(
                        (currentMatch) => currentMatch.is_it_fixture === false
                      )
                      .slice(0, 7)
                      .map((currentMatch, index) => (
                        <div
                          className="meeting-card-rm"
                          onClick={() =>
                            (window.location.href = `/${formatTeamNameForURL(cityName)}/${formatTeamNameForURL(leagueName)}/maclar/${currentMatch.id}`)
                          }
                          key={index}
                        >
                          <div className="meeting-date-rm">
                            {formatDate(currentMatch.date)}
                          </div>
                          <div className="meeting-info-rm">
                            <span className="team-name-rm">
                              {currentMatch.first_team_name}
                            </span>
                            <img
                              src={
                                currentMatch.first_team_name ===
                                match.first_team_name
                                  ? match.first_team_logo
                                  : currentMatch.first_team_name ===
                                    match.second_team_name
                                  ? match.second_team_logo
                                  : logo
                              }
                              alt={currentMatch.first_team_name}
                              className="team-logo-rm"
                            />
                            <div className="score-box-rm">
                              {currentMatch.first_team_score} -{" "}
                              {currentMatch.second_team_score}
                            </div>
                            <img
                              src={
                                currentMatch.second_team_name ===
                                match.first_team_name
                                  ? match.first_team_logo
                                  : currentMatch.first_team_name ===
                                    match.second_team_name
                                  ? match.second_team_logo
                                  : logo
                              }
                              alt={currentMatch.first_team_name}
                              className="team-logo-rm"
                            />
                            <span className="team-name-rm">
                              {currentMatch.second_team_name}
                            </span>
                          </div>
                          <div className="meeting-venue-rm">
                            {currentMatch.match_field}
                          </div>
                          <span className="arrow-icon-rm">→</span>
                        </div>
                      ))}
                  </div>
                </div>

                <div className="stat-box-head-to-head form-guide-container">
                  <div className="form-guide-header-gc">
                    <h4 className="team-name-gc">{match.first_team_name}</h4>
                    <h4 className="team-name-gc">{match.second_team_name}</h4>
                  </div>
                  <div className="form-guide-rows-gc">
                    {/* Brighton'ın sonuçları */}
                    <div>
                      <table className="form-table">
                        <tbody>
                          {matchResults.firstTeamMatches
                            .slice(0, 5)
                            .map((match, index) => {
                              // Skor ve sonuç ikonunu belirle
                              const isWin =
                                (match.first_team_name ===
                                  matchResults.firstTeam &&
                                  match.first_team_score >
                                    match.second_team_score) ||
                                (match.second_team_name ===
                                  matchResults.firstTeam &&
                                  match.second_team_score >
                                    match.first_team_score);
                              const isDraw =
                                match.first_team_score ===
                                match.second_team_score;
                              const resultClass = isWin
                                ? "win"
                                : isDraw
                                ? "draw"
                                : "loss";

                              return (
                                <tr
                                  key={index}
                                  style={{ justifyContent: "right" }}
                                >
                                  {/* Sol Taraf: Takım İsmi ve Ev/Saha Bilgisi */}
                                  <td
                                    className="column-left"
                                    style={{ textAlign: "right" }}
                                  >
                                    {match.first_team_name ===
                                    matchResults.firstTeam
                                      ? match.second_team_name
                                      : match.first_team_name}
                                  </td>

                                  {/* Orta: Skor */}
                                  <td className="column-center">
                                    <div className="score-badge-gc">
                                      {match.first_team_score} -{" "}
                                      {match.second_team_score}
                                    </div>
                                  </td>

                                  {/* Sağ Taraf: Sonuç İkonu */}
                                  <td className="column-right">
                                    <div
                                      className={`result-icon-gc ${resultClass}`}
                                    >
                                      {isWin ? "W" : isDraw ? "D" : "L"}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>

                    {/* Crystal Palace'ın sonuçları */}
                    <div>
                      <table className="form-table">
                        <tbody>
                          {matchResults.secondTeamMatches
                            .slice(0, 5)
                            .map((match, index) => {
                              // Sonuç durumu hesaplama
                              const isWin =
                                (match.first_team_name ===
                                  matchResults.secondTeam &&
                                  match.first_team_score >
                                    match.second_team_score) ||
                                (match.second_team_name ===
                                  matchResults.secondTeam &&
                                  match.second_team_score >
                                    match.first_team_score);
                              const isDraw =
                                match.first_team_score ===
                                match.second_team_score;
                              const resultClass = isWin
                                ? "win"
                                : isDraw
                                ? "draw"
                                : "loss";

                              return (
                                <tr key={index}>
                                  {/* Sol Taraf: Sonuç İkonu */}
                                  <td className="column-left">
                                    <div
                                      className={`result-icon-gc ${resultClass}`}
                                    >
                                      {isWin ? "W" : isDraw ? "D" : "L"}
                                    </div>
                                  </td>

                                  {/* Orta: Skor */}
                                  <td className="column-center">
                                    <div className="score-badge-gc">
                                      {match.first_team_score} -{" "}
                                      {match.second_team_score}
                                    </div>
                                  </td>

                                  {/* Sağ Taraf: Takım İsmi ve Ev/Saha Bilgisi */}
                                  <td
                                    className="column-right"
                                    style={{ textAlign: "left" }}
                                  >
                                    {match.first_team_name ===
                                    matchResults.secondTeam
                                      ? match.second_team_name
                                      : match.first_team_name}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="stat-box-head-to-head">
                  <h4 className="section-title-rm">Sezonda Şu Ana Kadar</h4>
                  <table className="season-stats-table-h">
                    <thead>
                      <tr>
                        <th className="team-header-h">
                          {match.first_team_name}
                        </th>
                        <th></th>
                        <th className="team-header-h">
                          {match.second_team_name}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>{team1Stats.position || "-"}</strong>
                        </td>
                        <td>Sıra</td>
                        <td>
                          <strong>{team2Stats.position || "-"}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>{team1Stats.wins}</strong>
                        </td>
                        <td>Zafer</td>
                        <td>
                          <strong>{team2Stats.wins}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>{team1Stats.draws}</strong>
                        </td>
                        <td>Berabere</td>
                        <td>
                          <strong>{team2Stats.draws}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>{team1Stats.losses}</strong>
                        </td>
                        <td>Mağlubiyet</td>
                        <td>
                          <strong>{team2Stats.losses}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>{team1Stats.avgGoalsScored}</strong>
                        </td>
                        <td>Maç Başına Atılan Gol</td>
                        <td>
                          <strong>{team2Stats.avgGoalsScored}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>{team1Stats.avgGoalsConceded}</td>
                        <td>Maç Başına Yenilen Gol</td>
                        <td>
                          <strong>{team2Stats.avgGoalsConceded}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>{team1Stats.cleanSheets}</strong>
                        </td>
                        <td>Clean Sheets</td>
                        <td>
                          <strong>{team2Stats.cleanSheets}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            {team1Stats.biggestWin
                              ? `${team1Stats.biggestWin.score} v ${team1Stats.biggestWin.opponentName}`
                              : "N/A"}
                          </strong>
                        </td>
                        <td>En Büyük Zafer</td>
                        <td>
                          <strong>
                            {team2Stats.biggestWin
                              ? `${team2Stats.biggestWin.score} v ${team2Stats.biggestWin.opponentName}`
                              : "N/A"}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            {team1Stats.worstDefeat
                              ? `${team1Stats.worstDefeat.score} v ${team1Stats.worstDefeat.opponentName}`
                              : "N/A"}
                          </strong>
                        </td>
                        <td>En Büyük Yenilgi</td>
                        <td>
                          <strong>
                            {team2Stats.worstDefeat
                              ? `${team2Stats.worstDefeat.score} v ${team2Stats.worstDefeat.opponentName}`
                              : "N/A"}
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Player Card'lar
              <div className="player-cards-container-head-to-head">
                <div className="player-card-head-to-head">
                  <img src="player1.png" alt="Player 1" />
                  <h4>Oyuncu 1</h4>
                  <p>Pozisyon: Orta Saha</p>
                </div>
                <div className="player-card-head-to-head">
                  <img src="player2.png" alt="Player 2" />
                  <h4>Oyuncu 2</h4>
                  <p>Pozisyon: Defans</p>
                </div>
                <div className="player-card-head-to-head">
                  <img src="player3.png" alt="Player 3" />
                  <h4>Oyuncu 3</h4>
                  <p>Pozisyon: Forvet</p>
                </div>
              </div> */}
            </div>
          )}
        </div>
      );
    default:
      return null;
  }
};

function Player({ player, cityName, leagueName }) {
  return (
    <div
      className="player-row-field"
      onClick={() => (window.location.href = `/${formatTeamNameForURL(cityName)}/${formatTeamNameForURL(leagueName)}/oyuncular/${player.player.id}`)}
    >
      {/* Oyuncu Fotoğrafı */}
      <img
        src={player.player.player_img || defaultPlayerImage} // Oyuncu resmi ya da varsayılan resim
        alt={player.player.player_name}
        className="player-image-field"
      />

      {/* Oyuncu Bilgileri */}
      <div className="player-info-field">
        <div className="player-name-field">
          <strong>{player.player.player_name}</strong>
        </div>
        {player.goals_minutes && (
          <div className="player-minute-field">
            <span className="minute-icon-field"></span>
            <span>{player.goals_minutes}'</span>
          </div>
        )}
        <div className="player-country-field">
          <span>{player.player.nationality}</span>
        </div>
      </div>
    </div>
  );
}

const calculateTeamStats = (matches, teamName, leagueName) => {
  // Lige göre maçları filtrele
  const filteredMatches = matches.filter(
    (match) => match.league_name === leagueName
  );

  let goalsScored = 0;
  let goalsConceded = 0;
  let wins = 0;
  let draws = 0;
  let losses = 0;
  let cleanSheets = 0;
  let biggestWin = null;
  let worstDefeat = null;

  filteredMatches.forEach((match) => {
    const isHome = match.first_team_name === teamName;
    const teamScore = isHome ? match.first_team_score : match.second_team_score;
    const opponentScore = isHome
      ? match.second_team_score
      : match.first_team_score;
    const opponentName = isHome
      ? match.second_team_name
      : match.first_team_name;
    // Toplam goller
    goalsScored += teamScore;
    goalsConceded += opponentScore;

    // Zafer, beraberlik veya mağlubiyet kontrolü
    if (teamScore > opponentScore) {
      wins++;
      // En büyük galibiyet
      const goalDifference = teamScore - opponentScore;
      if (!biggestWin || goalDifference > biggestWin.difference) {
        biggestWin = {
          score: `${teamScore} - ${opponentScore}`,
          difference: goalDifference,
          opponentName: opponentName,
        };
      }
    } else if (teamScore < opponentScore) {
      losses++;
      // En kötü mağlubiyet
      const goalDifference = opponentScore - teamScore;
      if (!worstDefeat || goalDifference > worstDefeat.difference) {
        worstDefeat = {
          score: `${teamScore} - ${opponentScore}`,
          difference: goalDifference,
          opponentName: opponentName,
        };
      }
    } else {
      draws++;
    }

    // Clean sheets kontrolü
    if (opponentScore === 0) cleanSheets++;
  });

  const avgGoalsScored = (goalsScored / filteredMatches.length).toFixed(2);
  const avgGoalsConceded = (goalsConceded / filteredMatches.length).toFixed(2);

  return {
    teamName,
    leagueName,
    matchesPlayed: filteredMatches.length,
    avgGoalsScored,
    avgGoalsConceded,
    cleanSheets,
    wins,
    draws,
    losses,
    biggestWin,
    worstDefeat,
  };
};

function formatTeamNameForURL(teamName) {
  return teamName
    .toLowerCase()
    .replace(/ç/g, "c")
    .replace(/ğ/g, "g")
    .replace(/ı/g, "i")
    .replace(/ö/g, "o")
    .replace(/ş/g, "s")
    .replace(/ü/g, "u")
    .replace(/[^a-z0-9 ]/g, "")
    .trim()
    .replace(/\s+/g, "-");
}
function extractVideoID(url) {
  let videoID = "";
  if (url.includes("youtu.be")) {
    videoID = url.split("youtu.be/")[1].split("?")[0];
  } else if (url?.includes("youtube.com")) {
    const params = new URLSearchParams(new URL(url).search);
    videoID = params.get("v");
  }
  return videoID;
}
const calculateMatchResults = (
  allMatches,
  first_team_name,
  second_team_name
) => {
  // 1. İlgili takımların geçmiş maçlarını filtrele
  const firstTeamMatches = allMatches.filter(
    (m) =>
      m.first_team_name === first_team_name ||
      m.second_team_name === first_team_name
  );

  const secondTeamMatches = allMatches.filter(
    (m) =>
      m.first_team_name === second_team_name ||
      m.second_team_name === second_team_name
  );

  // 2. İki takımın karşılaştığı maçları bul
  const relatedMatches = allMatches.filter(
    (m) =>
      (m.first_team_name === first_team_name &&
        m.second_team_name === second_team_name) ||
      (m.first_team_name === second_team_name &&
        m.second_team_name === first_team_name)
  );

  // 3. Kazananları ve beraberlikleri hesapla
  let firstTeamWins = 0;
  let secondTeamWins = 0;
  let draws = 0;

  relatedMatches.forEach((m) => {
    if (m.first_team_score > m.second_team_score) {
      if (m.first_team_name === first_team_name) {
        firstTeamWins++;
      } else {
        secondTeamWins++;
      }
    } else if (m.second_team_score > m.first_team_score) {
      if (m.second_team_name === first_team_name) {
        firstTeamWins++;
      } else {
        secondTeamWins++;
      }
    } else {
      draws++;
    }
  });

  // 4. Sonuçları döndür
  return {
    firstTeam: first_team_name,
    secondTeam: second_team_name,
    firstTeamWins,
    secondTeamWins,
    draws,
    firstTeamMatches,
    secondTeamMatches,
  };
};

export default MatchPage;
