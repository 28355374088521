import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Add_Player.css";
//import { response } from "express";
import { useUser } from "../UserContext";

const Add_Players = ({ closeModal, refreshTeams, onPlayerAdded, defaultTeam }) => {
  const [teams, setTeams] = useState([]);
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [playerEntries, setPlayerEntries] = useState([
    { playerName: "", playerPosition: "", playerImage: null },
  ]);  // Çoklu oyuncu girişleri
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { user, setUser } = useUser();
  const userCity = user?.city;
  
  useEffect(() => {
    fetchTeams();
  }, []);

    useEffect(() => {
    if (defaultTeam && teams.length > 0) {
      // Varsayılan takımı otomatik olarak seç
      const defaultTeamObject = teams.find(
        (team) => team.team_name === defaultTeam
      );
      if (defaultTeamObject) {
        setSelectedTeam(defaultTeamObject.id);
        setSearchQuery(defaultTeamObject.team_name);
      }
    }
  }, [defaultTeam, teams]); // `defaultTeam` veya `teams` değişirse çalışır

  const fetchTeams = async () => {
    try {
      const teamsResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/by-city/${userCity}`);
      const sortedTeams = teamsResponse.data.sort((a, b) =>
        a.team_name.localeCompare(b.team_name)
      );
      setTeams(sortedTeams);
      setFilteredTeams(sortedTeams); // Varsayılan olarak tüm takımları göster
    } catch (err) {
      console.error("Error fetching teams", err);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = teams.filter((team) =>
      team.team_name.toLowerCase().includes(query)
    );
    setFilteredTeams(filtered);
  };

  const handleTeamSelect = (team) => {
    setSelectedTeam(team.id);
    setSearchQuery(team.team_name); // Seçilen takımın adını arama çubuğuna yerleştir
  }; 

  const handleAddPlayer = async () => {
    if (!selectedTeam) {
      setErrorMessage("Lütfen bir takım seçin.");
      return;
    }
  
    const currentDate = new Date().toISOString().split("T")[0];
    const playersToAdd = playerEntries.filter((entry) => entry.playerName); // Boş olanları filtrele
  
    if (playersToAdd.length === 0) {
      setErrorMessage("Lütfen en az bir oyuncu adı girin.");
      return;
    }
  
    try {
      const newPlayerIds = [];

      for (const entry of playersToAdd) {
        const existingPlayerResponse = await axios.get(
          `https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/check-name/${entry.playerName}`
        );

        if (existingPlayerResponse.data.exists) {
          setErrorMessage(`Aynı isimli oyuncu var: ${entry.playerName}`);
          return; // Eğer aynı isimde oyuncu varsa, işlemi durdur
        }
      }
  
      for (const entry of playersToAdd) {
        // Veritabanında aynı isimde bir oyuncu olup olmadığını kontrol et
       

        // Yeni oyuncu için FormData oluştur
        const formData = new FormData();
        formData.append("player_name", entry.playerName);
        formData.append("player_img", entry.playerImage); // Resim ekle
        formData.append("team_id", selectedTeam);
        formData.append("player_position", entry.playerPosition || null);
        formData.append("attendence_date", currentDate);
        formData.append("city",userCity)
  
        // Yeni oyuncuyu ekle
        const response = await axios.post(
          "https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/add",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
  
        if (response.data && response.data.player && response.data.player.id) {
          newPlayerIds.push(response.data.player.id);
        } else {
          console.error("Yeni oyuncunun ID'si alınamadı:", response.data);
        }
      }
  
      setSuccessMessage("Tüm oyuncular başarıyla eklendi!");
      setErrorMessage("");
      refreshTeams();
  
      if (onPlayerAdded) {
        onPlayerAdded(newPlayerIds);
      }
    } catch (error) {
      console.error("Error details:", error);
      setErrorMessage("Bir hata oluştu. Lütfen tekrar deneyin.");
    }
  };
  

  const handleAddEntry = () => {
    setPlayerEntries([
      ...playerEntries,
      { playerName: "", playerPosition: "", playerImage: null },
    ]);
  };

  const handleInputChange = (index, field, value) => {
    const updatedEntries = [...playerEntries];
    updatedEntries[index][field] = value;
    setPlayerEntries(updatedEntries);
  };

  const handleFileChange = (index, file) => {
    const updatedEntries = [...playerEntries];
    updatedEntries[index].playerImage = file; // Dosya verisini sakla
    updatedEntries[index].fileName = file ? file.name : ""; // Dosya adını sakla
    setPlayerEntries(updatedEntries);
  };
  

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button-player" onClick={closeModal}>
          &times;
        </button>

        <div className="modal-title-addTeam">Toplu Oyuncu Ekle</div>

      {!defaultTeam && (
        <div className="team-selection">
          <input
            type="text"
            placeholder="Takım ara..."
            value={searchQuery}
            onChange={handleSearch}
            className="custom-input"
          />
          <div className="team-list">
            {filteredTeams.map((team) => (
              <div
                key={team.id}
                className={`team-item ${selectedTeam === team.id ? "selected" : ""}`}
                onClick={() => handleTeamSelect(team)}
              >
                {team.team_name}
              </div>
            ))}
          </div>
        </div>
)}

<div className="player-entries">
  {playerEntries.map((entry, index) => (
    <div className="player-inputs" key={index}>
      {/* Fotoğraf seçme alanı */}
      <div className="photo-input-wrapper">
        <label htmlFor={`photo-input-${index}`} className="photo-label">
          Foto
        </label>
        <input
          id={`photo-input-${index}`}
          type="file"
          accept="image/*"
          onChange={(e) => handleFileChange(index, e.target.files[0])}
          className="photo-input"
        />

        {/* Dosya adı fotoğraf butonunun altına yazılır */}
        {entry.fileName && <p className="file-name"> {entry.fileName}</p>}
      </div>

      <input
        type="text"
        placeholder="Oyuncu Adı Soyadı"
        value={entry.playerName}
        onChange={(e) => handleInputChange(index, "playerName", e.target.value)}
        className="custom-input"
      />

      <select
        className="custom-select"
        value={entry.playerPosition}
        onChange={(e) =>
          handleInputChange(index, "playerPosition", e.target.value)
        }
      >
        <option value="">Mevki</option>
        <option value="Kaleci">Kaleci</option>
        <option value="Defans">Defans</option>
        <option value="Orta Saha">Orta Saha</option>
        <option value="Forvet">Forvet</option>
      </select>
    </div>
  ))}
 
</div>
<button onClick={handleAddEntry} className="add-entry-button">
    + Oyuncu Ekle
  </button>

        <button onClick={handleAddPlayer} className="create-button">
          Oluştur
        </button>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}
      </div>
    </div>
  );
};

export default Add_Players;
