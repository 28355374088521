import "./style.css";
import logo from "../assets/elitLig_logo.png";
import defaultImage from "../assets/field.jpg";
import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Header from "./Header";
import Footer from "./Footer";
import { useData } from "./Cache";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";

function Home() {
  const {
    matches,
    players,
    teams,
    playerStatistics1,
    seasons,
    loading,
    error,
  } = useData();
  

  const { cityName, leagueName } = useParams();

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  // Group matches by date
  const sortedMatches = getFilteredMatches(matches, leagueName, cityName, true);
  const limit = 9;
  const lastFiveMatches = getFilteredMatches(
    matches,
    leagueName,
    cityName,
    false,
    limit
  );

  const sortByGoals = "goals";
  const sortByValue = "value";
  const playerStatsGoals = getPlayerStatsByLeague(
    matches,
    players.filter(
      (player) =>
        formatTeamNameForURL(player.city) === formatTeamNameForURL(cityName) && player.active
    ),
    playerStatistics1,
    leagueName,
    cityName,
    sortByGoals
  );
  const playerStatsValue = getPlayerStatsByLeague(
    matches,
    players.filter(
      (player) =>
        formatTeamNameForURL(player.city) === formatTeamNameForURL(cityName) && player.active
    ),
    playerStatistics1,
    leagueName,
    cityName,
    sortByValue
  );
  
  const formattedCityName = formatTeamNameForURL(cityName);
  
  const topTeams = getTopTeamsByStats(
    matches,
    teams.filter((team) => {
        const formattedCity = formatTeamNameForURL(team.city);
        //console.log("filtrelemed once takimlar ", team.team_name," takimin db de sehri ",team.city);

        //console.log(`takim sehri: ${formattedCity}, website sehir: ${cityName}`);
        return formattedCity === formattedCityName && team.active;
      }),
    cityName,
    leagueName
  );


  const groupedMatchesAll = sortedMatches.reduce((groups, match) => {
    const date = match.date;
    if (!groups[date]) groups[date] = [];
    groups[date].push(match);
    return groups;
  }, {});

  return (
    <div>
      <Header />
      <MainContent
        matches={lastFiveMatches}
        groupedMatches={groupedMatchesAll}
        teams={topTeams}
        allTeams={teams.filter((team) => 
  formatTeamNameForURL(team.city) === formatTeamNameForURL(cityName) && team.active
)}
        players_goal={playerStatsGoals}
        players_value={playerStatsValue}
        seasons={seasons}
        leagueName={leagueName}
        cityName={cityName}
        totalMatches={matches}
      />
      <Footer />
    </div>
  );
}
const formatDateTime = (timeString) => {
  const time = timeString.slice(0, 5);
  return `${time}`;
};

function formatTeamNameForURL(teamName) {
  return teamName
    .toLowerCase()
    .replace(/ç/g, "c")
    .replace(/ğ/g, "g")
    .replace(/ı/g, "i")
    .replace(/ö/g, "o")
    .replace(/ş/g, "s")
    .replace(/ü/g, "u")
    .replace(/[^a-z0-9 ]/g, "")
    .trim()
    .replace(/\s+/g, "-");
}
function MainContent({
  matches,
  players_goal,
  players_value,
  teams,
  allTeams,
  groupedMatches,
  seasons,
  leagueName,
  cityName,
  totalMatches,
}) {
  const [selectedSeason, setSelectedSeason] = useState(""); // Seçili sezon
  const [filteredTeams, setFilteredTeams] = useState([]); // Filtrelenmiş takımlar

  // Belirli lige ait sezonları bul
  const leagueSeasons = seasons.filter(
    (season) => season.league_name === leagueName
  );

  // İlk yüklemede varsayılan sezonu belirle
  useEffect(() => {
    if (leagueSeasons.length > 0 && !selectedSeason) {
      setSelectedSeason(leagueSeasons[leagueSeasons.length - 1].season_name); // En güncel sezonu seç
    }
  }, [leagueSeasons, selectedSeason]);

  useEffect(() => {
    // Seçili sezona ait maçları filtreleme
    const seasonMatches = totalMatches.filter(
      (match) =>
        formatTeamNameForURL(match.league_name) === leagueName &&
        match.match_season === selectedSeason &&
        match.is_it_fixture === false &&
        formatTeamNameForURL(match.city) === formatTeamNameForURL(cityName)
    );

    // Takımların yeni istatistiklerini hesapla
    const updatedTeams = allTeams.map((team) => {
      const teamMatches = seasonMatches.filter(
        (match) =>
          match.first_team_name === team.team_name ||
          match.second_team_name === team.team_name
      );

      const playedMatches = teamMatches.length;

      // Galibiyet sayısını hesapla
      const wins = teamMatches.filter(
        (match) =>
          (match.first_team_name === team.team_name &&
            match.first_team_score > match.second_team_score) ||
          (match.second_team_name === team.team_name &&
            match.second_team_score > match.first_team_score)
      ).length;

      // Beraberlik sayısını hesapla
      const draws = teamMatches.filter(
        (match) => match.first_team_score === match.second_team_score
      ).length;

      // Atılan gol sayısını hesapla
      const goalsScored = teamMatches.reduce((acc, match) => {
        if (match.first_team_name === team.teamName) {
          return acc + match.first_team_score;
        } else if (match.second_team_name === team.teamName) {
          return acc + match.second_team_score;
        }
        return acc;
      }, 0);

      // Puan hesaplama (Galibiyet: 3 puan, Beraberlik: 1 puan)
      const points = wins * 3 + draws * 1;

      return {
        ...team,
        playedMatches,
        wins,
        points,
      };
    });

setFilteredTeams(
  updatedTeams
    .filter((team) => team.current_season === selectedSeason || team.playedMatches > 0)
    .sort((a, b) => (b.points + b.team_points) - (a.points + a.team_points)) // Puan sırasına göre sırala
    .slice(0, 21) // İlk 21 takımı al
);
  }, [selectedSeason, teams, matches, leagueName]);
  return (
    <div className="layout">
      {/* Üstte 6 Kutucuk */}
      <div className="little-match-container">
        {matches
          .sort((a, b) => new Date(a.date) - new Date(b.date))
          .map((match, index) => (
            <div
              className="box"
              key={index}
              style={{ textWrap: "nowrap" }}
              onClick={() => (window.location.href = `/${formatTeamNameForURL(cityName)}/${formatTeamNameForURL(leagueName)}/maclar/${match.id}`)}
            >
              
              <img
                src={
                  allTeams.find((team) => team.team_name === match.first_team_name)
                    ?.logo || logo
                }
                alt={match.first_team_name || "Unknown Team"}
                className="team-logo2-home"
              />

              <h3>{match.first_team_name.substring(0, 8)}</h3>


                  
              <div className="score">
                {match.first_team_score}-{match.second_team_score}
              </div>

                  <h3>{match.second_team_name.substring(0, 8)}</h3>
                  
              <img
                src={
                  allTeams.find(
                    (team) => team.team_name === match.second_team_name
                  )?.logo || logo
                }
                alt={match.second_team_name || "Unknown Team"}
                className="team-logo2-home"
              />
              
            </div>
          ))}
        <div
          className="box"
          onClick={() => (window.location.href = `/${formatTeamNameForURL(cityName)}/${formatTeamNameForURL(leagueName)}/sonuclar`)}
        >
          <h3>TÜM MAÇLARI GÖRÜNTÜLE</h3>
        </div>
      </div>

      {/* Alttaki 3 Yan Yana Tablo */}
      <div className="main-tables">
        <div className="fixture-table">
          <div className="table-header-home">
            <h2>FİKSTÜR</h2>
          </div>
          <table className="grouped-matches-table-home">
            <tbody>
              {Object.keys(groupedMatches)
                .slice(0, 4)
                .reverse()
                .map((date) => (
                  <React.Fragment key={date}>
                    {/* Tarih Başlığı */}
                    <tr className="date-header-home">
                      <td colSpan="5" className="date-header-cell">
                        {new Date(date).toLocaleDateString("tr-TR", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                          weekday: "long",
                        })}
                      </td>
                    </tr>

                    {/* Maçlar */}
                    {groupedMatches[date].slice().reverse().map((match) => (
                      <React.Fragment key={match.id}>
                        <tr
                          className="match-row-home"
                          onClick={() =>
                            (window.location.href = `/${formatTeamNameForURL(cityName)}/${formatTeamNameForURL(leagueName)}/maclar/${match.id}`)
                          }
                        >
                          {/* İlk Takım */}
                          <td className="team-cell-home">
                            <div className="team-container-home">

                              <img
                                src={
                                  allTeams.find(
                                    (team) =>
                                      team.team_name === match.first_team_name
                                  )?.logo || logo
                                }
                                alt={match.first_team_name || "Unknown Team"}
                                className="team-logo-home"
                              />
                                  <span className="team-name-home">
                                {match.first_team_name.substring(0, 10)}
                              </span>
                            </div>
                          </td>

                          {/* Skor veya Zaman */}
                          <td className="match-time-cell-home">
                            <span className="match-time-home">
                              {formatDateTime(match.time)}
                            </span>
                          </td>

                          {/* İkinci Takım */}
                          <td className="team-cell-home">
                            <div className="team-container-home">
                              <span className="team-name-home">
                                {match.second_team_name.substring(0, 10)}
                              </span>
                              <img
                                src={
                                  allTeams.find(
                                    (team) =>
                                      team.team_name === match.second_team_name
                                  )?.logo || logo
                                }
                                alt={match.second_team_name || "Unknown Team"}
                                className="team-logo-home"
                              />

                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
            </tbody>
          </table>
          <button
            className="view-full-list-button"
            onClick={() =>
              (window.location.href = `/${formatTeamNameForURL(cityName)}/${formatTeamNameForURL(leagueName)}/fikstur`)
            }
          >
            Tüm Listeyi Görüntüle →
          </button>
        </div>

        <div className="table-home">
          <MatchCarousel
            matches={matches.filter(
              (match) => match.match_title?.trim() !== ""
            )}
            cityName = {cityName}
            leagueName = {leagueName}
          />

          {/* İkinci Tablo Altındaki 2 Alt Tablo */}
          <div className="sub-tables">
            <div className="sub-table">
              {/* Oyuncu Tablosu */}
              <div className="goal-table-container">
                {/* Başlık */}
                <div className="table-header-home">
                  <h2>GOL KRALLIĞI</h2>
                </div>

                {/* Oyuncu Kartı */}
                <div className="player-card-home">
                  {/* Sol Taraftaki Logo ve İstatistikler */}
                  <div className="player-stats-home">
                    <ul>
                      <li>Toplam Maç</li>
                      <li style={{ fontSize: "23px" }}>
                        {players_goal[0]?.total_matches}
                      </li>
                      <li>Toplam Gol</li>
                      <li style={{ fontSize: "23px" }}>
                        {players_goal[0]?.total_goals}
                      </li>
                      <li>M.B.GOL</li>
                      <li style={{ fontSize: "23px" }}>
                        {(
                          players_goal[0]?.total_goals /
                          players_goal[0]?.total_matches
                        )?.toFixed(2)}
                      </li>
                      <li>Değeri</li>
                      <li style={{ fontSize: "23px" }}>
                        {Math.floor(players_goal[0]?.value)}
                      </li>
                    </ul>
                  </div>

                  {/* Oyuncu Resmi */}
                  <div
                    className="player-image-home"
                    onClick={() =>
                      (window.location.href = `/${formatTeamNameForURL(cityName)}/${formatTeamNameForURL(leagueName)}/oyuncular/${players_goal[0]?.id}`)
                    }
                  >
                    <img
                      src={players_goal[0]?.player_img}
                      alt={`${players_goal[0]?.player_name}`}
                    />
                  </div>

                  {/* Oyuncu Adı ve Pozisyonu */}
                  <div className="player-info-home">
                    <h2>{players_goal[0]?.player_name}</h2>
                    <p>{players_goal[0]?.team_name}</p>
                  </div>
                </div>

                {/* Gol Krallığı Tablosu */}
                <table className="players-table">
                  <thead>
                    <tr>
                      <th>Sıra</th>
                      <th>Fotoğraf</th>
                      <th>İsim</th>
                      <th>Goller</th>
                    </tr>
                  </thead>
                  <tbody>
                    {players_goal.slice(1, 7).map((player, index) => (
                      <tr
                        key={player.id}
                        onClick={() =>
                          (window.location.href = `/${formatTeamNameForURL(cityName)}/${formatTeamNameForURL(leagueName)}/oyuncular/${player.id}`)
                        }
                      >
                        <td>{index + 2}</td>
                        <td>
                          <div className="player-info">
                            <img
                              src={player.player_img || ""}
                              alt={player.team_name || "Takım"}
                              className="player-pic"
                            />
                                </div>
                          </td>
                          <td>
                            <div className="player-details">
                              <strong>{player.player_name || "Oyuncu"}</strong>
                              <span>{player.team_name || "Takım"}</span>
                            </div>
                          
                        </td>
                        <td>
                          <strong>{player.total_goals || 0}</strong>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/* Full List Butonu */}
                <button
                  className="view-full-list-button"
                  onClick={() =>
                    (window.location.href = `/${formatTeamNameForURL(cityName)}/${leagueName}/en-degerliler-gol-kralligi`)
                  }
                >
                  Tüm Listeyi Görüntüle →
                </button>
              </div>
            </div>
            <div className="sub-table">
              {/* Oyuncu Tablosu */}
              <div className="goal-table-container">
                {/* Başlık */}
                <div className="table-header-home">
                  <h2>EN DEĞERLİLER</h2>
                </div>

                {/* Oyuncu Kartı */}
                <div className="player-card-home">
                  {/* Sol Taraftaki Logo ve İstatistikler */}
                  <div className="player-stats-home">
                    <ul>
                      <li>Toplam Maç</li>
                      <li style={{ fontSize: "23px" }}>
                        {players_value[0]?.total_matches}
                      </li>
                      <li>Toplam Gol</li>
                      <li style={{ fontSize: "23px" }}>
                        {players_value[0]?.total_goals}
                      </li>
                      <li>M.B. PUAN</li>
                      <li style={{ fontSize: "23px" }}>
                        {(
                          players_value[0]?.total_points /
                          players_value[0]?.total_matches
                        )?.toFixed(2)}
                      </li>
                      <li>Değeri</li>
                      <li style={{ fontSize: "23px" }}>
                        {Math.floor(players_value[0]?.value)}
                      </li>
                    </ul>
                  </div>

                  {/* Oyuncu Resmi */}
                  <div
                    className="player-image-home"
                    onClick={() =>
                      (window.location.href = `/${formatTeamNameForURL(cityName)}/${formatTeamNameForURL(leagueName)}/oyuncular/${players_value[0]?.id}`)
                    }
                  >
                    <img
                      src={players_value[0]?.player_img}
                      alt={`${players_value[0]?.player_name}`}
                    />
                  </div>

                  {/* Oyuncu Adı ve Pozisyonu */}
                  <div className="player-info-home">
                    <h2>{players_value[0]?.player_name}</h2>
                    <p>{players_value[0]?.team_name}</p>
                  </div>
                </div>

                {/* En Değerliler Tablosu */}
                <table className="players-table">
                  <thead>
                    <tr>
                      <th>Sıra</th>
                      <th>Fotoğraf</th>
                      <th>İsim</th>
                      <th>Değeri</th>
                    </tr>
                  </thead>
                  <tbody>
                    {players_value.slice(1, 7).map((player, index) => (
                      <tr
                        key={player.id}
                        onClick={() =>
                          (window.location.href = `/${formatTeamNameForURL(cityName)}/${formatTeamNameForURL(leagueName)}/oyuncular/${player.id}`)
                        }
                      >
                        <td>{index + 2}</td>
                        <td>
                          <div className="player-info">
                            <img
                              src={player.player_img || ""}
                              alt={player.team_name || "Takım"}
                              className="player-pic"
                            />
                           </div>   
                           </td>     
                            <td>    
                            <div className="player-details">
                              <strong>{player.player_name || "Oyuncu"}</strong>
                              <span>{player.team_name || "Takım"}</span>
                            </div>
                          
                        </td>
                        <td>
                          <strong>{Math.floor(player.value || 0)}</strong>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/* Full List Butonu */}
                <button
                  className="view-full-list-button"
                  onClick={() =>
                    (window.location.href = `/${cityName}/${leagueName}/en-degerliler-gol-kralligi`)
                  }
                >
                  Tüm Listeyi Görüntüle →
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="league-table-container">
          {/* Tablo Başlığı */}
          <div className="table-header-home">
            <h2>PUAN DURUMU</h2>
          </div>
          <div className="season-selector">
            <select
              id="season"
              value={selectedSeason}
              onChange={(e) => setSelectedSeason(e.target.value)}
            >
              <option value="">Sezon Seçin</option>
              {seasons
                .filter(
                  (season) =>
                    formatTeamNameForURL(season.league_name) === (leagueName)
                )
                .map((season) => (
                  <option key={season.id} value={season.season_name}>
                    {season.season_name}
                  </option>
                ))}
            </select>
          </div>

          {/* Takım Sıralama Tablosu */}
          <table className="league-table-container-league-table">
            <thead>
              <tr>
                <th>Sıra</th>
                <th>Takım Adı</th>
                <th>OM</th> {/* Galibiyet */}
                <th>G</th> {/* Mağlubiyet */}
                <th>P</th> {/* Puan */}
              </tr>
            </thead>
            <tbody>
              {(selectedSeason ? filteredTeams : teams).map((team, index) => (
                <tr
                  key={team.teamName}
                  onClick={() =>
                    (window.location.href = `/${formatTeamNameForURL(cityName)}/${formatTeamNameForURL(leagueName)}/takimlar/${formatTeamNameForURL(
                      team.team_name
                    )}`)
                  }
                >
                  <td>{index + 1}</td>
                  <td>
                    <div className="league-table-container-team-info">
                      <img
                        src={team.logo}
                        alt={team.team_name}
                        className="league-table-container-team-logo"
                      />
                      <span>{team.team_name}</span>
                    </div>
                  </td>
                  <td>{team.playedMatches}</td>
                  <td>{team.wins || 0}</td>
                  <td>{team.points + team.team_points}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            className="view-full-list-button"
            onClick={() =>
              (window.location.href = `/${cityName}/${leagueName}/puan-durumu`)
            }
          >
            Tüm Listeyi Görüntüle →
          </button>
        </div>
      </div>
    </div>
  );
}

const MatchCarousel = ({ matches, cityName, leagueName }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextMatch = () => {
    setCurrentIndex((currentIndex + 1) % matches.length);
  };

  const prevMatch = () => {
    setCurrentIndex((currentIndex - 1 + matches.length) % matches.length);
  };

  const currentMatch = matches.length > 0 ? matches[currentIndex] : null;

  return (
    <div>
      {matches.length === 0 ? (
        /* Default Görünüm: matches boşken */
        <div className="image-gallery">
          <div className="image-container">
            <img src={defaultImage} alt="Default Match" />
            <p className="default-message">Maç bulunamadı</p>
          </div>
        </div>
      ) : currentMatch ? (
        /* matches doluysa normal carousel görünümü */
        <div className="image-gallery">
          <div className="image-container">
            <img
              src={currentMatch?.match_picture || defaultImage}
              alt={currentMatch?.match_title || "Match Image"}
            />
            <button
              className="nav-button match-title-button"
              onClick={() =>
                (window.location.href = `/${formatTeamNameForURL(cityName)}/${formatTeamNameForURL(leagueName)}/maclar/${currentMatch.id}`)
              }
            >
              {currentMatch?.match_title || "Maç Başlığı"}
            </button>
            <div className="button-container-home">
              <button className="nav-button" onClick={prevMatch}>
                ◀
              </button>
              <button className="nav-button" onClick={nextMatch}>
                ▶
              </button>
            </div>
          </div>
        </div>
      ) : (
        <p>Yükleniyor...</p>
      )}
    </div>
  );
};
const getFilteredMatches = (
  matches,
  leagueName,
  cityName,
  isFixture,
  limit = 10
) => {
  return matches
    .filter(
      (match) =>
        match.is_it_fixture === isFixture &&
        formatTeamNameForURL(match.league_name) === leagueName &&
        formatTeamNameForURL(match.city) === formatTeamNameForURL(cityName)
    )
    .sort((a, b) => new Date(a.date) - new Date(b.date))
    .reverse()
    .slice(0, limit);
};

const getTopTeamsByStats = (matches, teams, cityName, leagueName) => {
  // Şehir ve lige göre maçları filtrele
  const filteredMatches = matches.filter(
    (match) =>
      formatTeamNameForURL(match.city) === formatTeamNameForURL(cityName) &&
      match.is_it_fixture === false &&
      formatTeamNameForURL(match.league_name) === leagueName
  );


  // Takım bazlı istatistik hesaplama
  const teamStats = teams.reduce((acc, team) => {
    const teamMatches = filteredMatches.filter(
      (match) =>
        match.first_team_name === team.team_name ||
        match.second_team_name === team.team_name
    );

    const stats = teamMatches.reduce(
      (stats, match) => {
        const isHome = match.first_team_name === team.team_name;
        const teamScore = isHome
          ? match.first_team_score
          : match.second_team_score;
        const opponentScore = isHome
          ? match.second_team_score
          : match.first_team_score;

        stats.playedMatches += 1;
        //stats.goalsScored += teamScore;
        
        
        // Puan hesaplama
        if (teamScore > opponentScore) {
          stats.points += 3; // Galibiyet
          stats.wins+= 1;
          
        } else if (teamScore === opponentScore) {
          stats.points += 1; // Beraberlik
        }
        return stats;
      },
      { playedMatches: 0, wins: 0,  points: 0 } // Mevcut puanı al, yoksa 0 başlat
    );

    // **Hesaplanan puanlara takımın mevcut `team.points` değerini ekle**


    acc[team.team_name] = stats;
    return acc;
  }, {});

  // Takımları sıralama ve ilk 21'i alma
  const sortedTeams = Object.entries(teamStats)
    .map(([team_name, stats]) => {
      const team = teams.find((t) => t.team_name === team_name);
      return {
        team_name,
        current_season: team?.current_season,
        team_points: team?.team_points || 0,
        logo: team?.logo || null, // Takım logosunu ekle
        ...stats,
      };
    })
    .filter(team => team.playedMatches > 0 || team.points + team.team_points > 0) // totalMatches 0 olanları filtrele
    .sort((a, b) => (b.points + b.team_points) - (a.points + a.team_points) || b.goalsScored - a.goalsScored) // Puan > Gol sıralaması
    .slice(0, 21); // İlk 21 takım


  return sortedTeams;
};

const getPlayerStatsByLeague = (
  matches,
  players,
  playerStatistics,
  leagueName,
  cityName,
  sortBy = "goals"
) => {
  // Şehir ve lige göre maçları filtrele
  const filteredMatches = matches.filter(
    (match) =>
      formatTeamNameForURL(match.league_name) === leagueName &&
      formatTeamNameForURL(match.city) === formatTeamNameForURL(cityName)
  );

  // Maç ID'lerini alın (ilgili maçlar)
  const matchIDs = filteredMatches.map((match) => match.id);

  // İlgili maçlara göre playerStatistics filtrele
  const filteredPlayerStatistics = playerStatistics.filter((stat) =>
    matchIDs.includes(stat.match_id)
  );

  // Oyuncu bazlı istatistik hesaplama
  const playerStats = filteredPlayerStatistics.reduce((acc, stat) => {
    const player = players.find((p) => p.id === stat.player_id);

    if (!player) return acc; // Oyuncu bulunamazsa devam et

    if (!acc[player.id]) {
      acc[player.id] = {
        id: player.id,
        team_name: player.team_name,
        player_name: player.player_name,
        player_img: player.player_img,
        total_goals: 0,
        total_matches: 0,
        total_points: 0, // Toplam puanı hesaplamak için başlatıyoruz
        total_yellow_cards: 0,
        total_red_cards: 0,
        value: 0, // Değer hesaplanacak
      };
    }

    // Gol, maç sayısı ve toplam puanı artır
    acc[player.id].total_goals += stat.number_of_goals || 0;
    acc[player.id].total_matches += 1;
    acc[player.id].total_points += stat.points || 0;
    acc[player.id].total_yellow_cards += stat.yellow_card > 0 ? 1 : 0;
    acc[player.id].total_red_cards += stat.red_card > 0 ? 1 : 0;

    return acc;
  }, {});

  Object.values(playerStats).forEach((player) => {
    const playedMatches = player.total_matches || 1; // 0'a bölme hatasını önlemek için
    player.value = (
      ((player.total_points / playedMatches) * 100 +
        playedMatches * 20 -
        player.total_yellow_cards * 30 -
        player.total_red_cards * 50) *
      70
    ).toFixed(2);
  });

  const processedPlayerStats = Object.values(playerStats);

  // Oyuncuları sıralama (gole göre, sonra maç sayısına göre)
  const sortedPlayerStats = Object.values(processedPlayerStats)
    .sort((a, b) => {
      if (sortBy === "goals") {
        return (
          b.total_goals - a.total_goals || b.total_matches - a.total_matches
        ); // Önce gole, sonra maça göre sırala
      } else if (sortBy === "value") {
        return b.value - a.value || b.total_goals - a.total_goals; // Önce değere, sonra gole göre sırala
      }
      return 0; // Varsayılan durumda sıralama yapma
    })
    .slice(0, 7);

  return sortedPlayerStats;
};
export default Home;
