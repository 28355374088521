import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Editor_Teams.css';
import './Add_Teams.css';
import AddTeams from './Add_Teams';
import UpdateTeam from './Update_Team';
import AddTeamPlayer from './Add_Team_Player.js';
import RemovePlayer from './Remove_Player.js';  
import { useUser } from "../UserContext"; // UserContext'i çağır

const Editor_Teams = () => {
    const [teams, setTeams] = useState([]);
    const [filteredTeams, setFilteredTeams] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [isAddPlayerModalOpen, setIsAddPlayerModalOpen] = useState(false); // Yeni popup kontrolü
    const { user, setUser } = useUser();
    const userCity = user?.city;
    
    const [isRemovePlayerModalOpen, setIsRemovePlayerModalOpen] = useState(false);
    const baseUrl = "https://elit-lig-back-end-bc5730e96b98.herokuapp.com/";
    // Veritabanından takımları çekme
    useEffect(() => {
        fetchTeams();
    }, []);

    const fetchTeams = async () => {
        try {
            const response = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/by-city/${userCity}`);
            const sortedTeams = response.data.sort((a, b) => {
                const dateA = new Date(a.createdAt);
                const dateB = new Date(b.createdAt);
                return dateB - dateA; // En son eklenen en üstte
            });
            setTeams(sortedTeams);
            setFilteredTeams(sortedTeams);
        } catch (error) {
            console.error("Error fetching teams:", error);
        }
    };

    // Takım arama fonksiyonu
    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchQuery(value);
        const filtered = teams.filter(team =>
            team.team_name.toLowerCase().includes(value)
        );
        setFilteredTeams(filtered);
        setCurrentPage(1); // Arama yapıldığında sayfayı başa al
    };

    const [currentPage, setCurrentPage] = useState(1);
        const teamsPerPage = 10;
    
        const lastIndex = currentPage * teamsPerPage;
        const firstIndex = lastIndex - teamsPerPage;
    
        const currentTeams = filteredTeams.slice(firstIndex, lastIndex);
    
        const totalPages = Math.ceil(filteredTeams.length / teamsPerPage);
    
        const getPaginationRange = () => {
            const range = [];
            const maxVisiblePages = 3;
            if (totalPages <= maxVisiblePages) {
                for (let i = 1; i <= totalPages; i++) {
                    range.push(i);
                }
                return range;
            }
        
            let start = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
            let end = Math.min(totalPages, start + maxVisiblePages - 1);
        
            if (end === totalPages) {
                start = Math.max(1, totalPages - maxVisiblePages + 1);
            }
        
            for (let i = start; i <= end; i++) {
                range.push(i);
            }
        
            // Adjust for start > 1, and ensure '...' starts after the first element (only when necessary)
            if (start > 2) {  // Changed condition to `> 2` to avoid showing `1 ... 2`
                range.unshift('...');
                range.unshift(1);
            } else if (start === 2) {
                range.unshift(1);
            }
        
            // Adjust for end < totalPages and add '...' at the end
            if (end < totalPages - 1) {
                range.push('...');
                range.push(totalPages);
            } else if (end === totalPages - 1) {
                range.push(totalPages);
            }
        
            return range;
        };
        const paginationRange = getPaginationRange();

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    // Güncelleme modalını açma
    const openUpdateModal = (team) => {
        setSelectedTeam(team);
        setIsUpdateModalOpen(true);
    };

    const closeUpdateModal = () => {
        setIsUpdateModalOpen(false);
        setSelectedTeam(null);
    };
    const openAddPlayerModal = () => {
        setIsAddPlayerModalOpen(true);
    };

    const closeAddPlayerModal = () => {
        setIsAddPlayerModalOpen(false);
    };
    const openRemovePlayerModal = () => {
        setIsRemovePlayerModalOpen(true);
    };
    
    const closeRemovePlayerModal = () => {
        setIsRemovePlayerModalOpen(false);
    };

    return (
        <div className="teams-container">
            {/* Yeni Takım Ekle Butonu */}
            <button className="add-buttonn" onClick={handleOpenModal}>
                Yeni Takım Ekle
            </button>
            <button className="add-buttonn" onClick={openAddPlayerModal}>
                Takımlara Oyuncu Ekle
            </button>
            <button className="add-buttonn" onClick={openRemovePlayerModal}>
                Takımdan Oyuncu Çıkar
            </button>

            {/* Takım Arama Alanı */}
            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Takım Ara..."
                    value={searchQuery}
                    onChange={handleSearch}
                />
            </div>

            {/* Takımlar Tablosu */}
            <div className="teams-table">
                <div className="table-header-team">
                    <span>Logo</span>
                    <span>Takımlar</span>
                    <span>Ligi</span>
                    <span>Sezonu</span>
                    <span className="empty-space"></span>
                </div>

                {currentTeams.map((team, index) => (
                    <div key={index} className="team-item">
                        <span>
                            {team.logo && (
                                <img
                                src={team.logo}
                                    alt={`${team.team_name} logo`}
                                    className="team-logo"
                                />
                            )}
                        </span>
                        <span>{team.team_name}</span>
                        <span>{team.current_league}</span>
                        <span>{team.current_season}</span>
                        <button className="update-button" onClick={() => openUpdateModal(team)}>
                            Güncelle
                        </button>
                    </div>
                ))}
            </div>

            {/* Sayfalandırma (Pagination) */}
            <div style={{ textAlign: 'center', marginTop: '-10px' }}> {/* Adjusted marginTop here */}
                {paginationRange.map((page, index) => (
                    <button
                        key={index}
                        onClick={() => {
                            if (typeof page === 'number') {
                                setCurrentPage(page);
                            }
                        }}
                        disabled={page === '...'}
                        style={{
                            margin: '0 2px',
                            padding: '5px 10px',
                            height: '30px',
                            marginBottom: '10px',
                            backgroundColor: currentPage === page ? '#320037' : '#008080',
                            color: currentPage === page ? '#ffffff' : '#ffffff',
                            border: '1px solid #008080',
                            borderRadius: '5px',
                            cursor: typeof page === 'number' ? 'pointer' : 'not-allowed',
                        }}
                    >
                        {page}
                    </button>
                ))}
            </div>

            {/* Modal */}
            {isModalOpen && (
                <AddTeams closeModal={handleCloseModal} refreshTeams={fetchTeams} />
            )}
            {isUpdateModalOpen && selectedTeam && (
                <UpdateTeam
                    selectedTeam={selectedTeam}
                    onClose={closeUpdateModal}
                    refreshTeams={fetchTeams}
                />
            )}
            {isAddPlayerModalOpen && (
                <AddTeamPlayer closeModal={closeAddPlayerModal} refreshTeams={fetchTeams} />
            )}
            {isRemovePlayerModalOpen && (
                <RemovePlayer closeModal={closeRemovePlayerModal} />
            )}

        </div>
    );
};

export default Editor_Teams;
