import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Add_Match.css';
import './Enter_Stats.css';
import { useUser } from "../UserContext"; // UserContext'i çağır

import { useParams } from 'react-router-dom';
import Add_Players from './Add_Player';
import { useNavigate } from 'react-router-dom';

const Enter_Stats = () => {
    const { user, setUser } = useUser();
    const userCity = user?.city;
    const { matchId } = useParams(); // URL'den matchId alınıyor
    const [matchData, setMatchData] = useState({
        first_team_name: "",
        second_team_name: "",
        first_team_score: 0,
        second_team_score: 0,
        first_team_percentage: 0,
        second_team_percentage: 0,
        date: "",
        time: "",
        match_field: "Ostim Saha",
        league_name: "",
        match_comment: "",
        match_title: "",
        match_season: "",
        match_video: "",
        match_images: "",
        match_interview: "",
        match_mvp: null,
        is_it_fixture: false, // Yeni alan eklendi
    });
    const navigate = useNavigate();
    const [teams, setTeams] = useState([]);// Tum Takimlar
    const [allPlayers, setAllPlayers] = useState([]); // Tüm oyuncular

    const [searchTermFirstAll, setSearchTermFirstAll] = useState(""); // Tum takimlardan ilk takım için oyuncu arama
    const [searchTermSecondAll, setSearchTermSecondAll] = useState(""); // Tum takimlardan ikinci takım için oyuncu arama

    const [searchTermFirstPlayer, setSearchTermFirstPlayer] = useState(""); //ilk takim icin oyuncu arama
    const [searchTermSecondPlayer, setSearchTermSecondPlayer] = useState(""); //ikinci takim icin oynuncu arama

    const [showAllPlayersForFirstTeam, setShowAllPlayersForFirstTeam] = useState(false); // Toggle for first team
    const [showAllPlayersForSecondTeam, setShowAllPlayersForSecondTeam] = useState(false); // Toggle for second team

    const [showAddPlayerModalForFirstTeam, setShowAddPlayerModalForFirstTeam] = useState(false); // Ilk takima yeni oyuncu ekleme
    const [showAddPlayerModalForSecondTeam, setShowAddPlayerModalForSecondTeam] = useState(false); // Ikinci takma yeni oyuncu ekleme

    const [firstTeamPlayers, setFirstTeamPlayers] = useState([]); //Ilk takimin oyunculari
    const [secondTeamPlayers, setSecondTeamPlayers] = useState([]); //Ikinci takimin oyunculari

    const [selectedFirstTeamPlayers, setSelectedFirstTeamPlayers] = useState([]); // ilk takim icin secilen oyuncular
    const [selectedSecondTeamPlayers, setSelectedSecondTeamPlayers] = useState([]); // ikinci takim icin secilen oyuncular

    const [filteredFirstTeamPlayers, setFilteredFirstTeamPlayers] = useState([]); //aramayla filtrelenmis ilk takim kadrosu
    const [filteredSecondTeamPlayers, setFilteredSecondTeamPlayers] = useState([]); // aramarlya filtrelenmis ikinici takim kadrosu

    const [filteredAllPlayersForFirst, setfilteredAllPlayersForFirst] = useState([]); //aramayla filtrelenmis tum oyuncular ilk takim icin
    const [filteredAllPlayersForSecond, setfilteredAllPlayersForSecond] = useState([]); // aramarlya filtrelenmis tum oyuncular ikinci takim icin
    const [isButtonVisible, setIsButtonVisible] = useState(true);

    const [fields, setFields] = useState([]);
    const [playerStatistics, setPlayerStatistics] = useState({});
    const isFixtureLeague = false;

    const [isStatsUpdated, setIsStatsUpdated] = useState(false); // Yeni state tanımlandı


    // Checkbox selection logic
    const isSelected = (playerId, isFirstTeam) => { //Bu checkbox lari duzenlemek icin gerekli
        return isFirstTeam
            ? selectedFirstTeamPlayers.includes(playerId)
            : selectedSecondTeamPlayers.includes(playerId);
    };

    // Generic filter logic
    const filterPlayers = (players, searchTerm) => //Oyuncu Filtreleme
        players.filter((player) =>
            player.player_name.toLowerCase().includes(searchTerm.toLowerCase())
        );

    // Update filtered players for the first team
    useEffect(() => {
        setFilteredFirstTeamPlayers(filterPlayers(firstTeamPlayers, searchTermFirstPlayer));
    }, [searchTermFirstPlayer, firstTeamPlayers]);

    // Update filtered players for the second team
    useEffect(() => {
        setFilteredSecondTeamPlayers(filterPlayers(secondTeamPlayers, searchTermSecondPlayer));
    }, [searchTermSecondPlayer, secondTeamPlayers]);

    // Update "All Players" filtering for the first team
    useEffect(() => {
        setfilteredAllPlayersForFirst(filterPlayers(allPlayers, searchTermFirstAll));
    }, [searchTermFirstAll, allPlayers]);

    // Update "All Players" filtering for the second team
    useEffect(() => {
        setfilteredAllPlayersForSecond(filterPlayers(allPlayers, searchTermSecondAll));
    }, [searchTermSecondAll, allPlayers]);

    // Takımlar, Sahalar, Ligler, Sezonlar
   useEffect(() => {
        const fetchData = async () => {
            try {
                const [teamsResponse, playerTeamsResponse,playersResponse] = await Promise.all([
                    axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/by-city/${userCity}`),
                    axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-takimlari"),
                    axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/by-city/${userCity}`)
                ]);

                setTeams(teamsResponse.data);

                // Deduplicate players by player_id
                const uniquePlayers = Array.from(
                    new Map(playerTeamsResponse.data.map(player => [player.player_id, player])).values()
                );

               
                // Kullanıcının şehriyle eşleşen oyuncuları filtrele
                const cityPlayersIds = new Set(playersResponse.data.map(player => player.id)); // Şehirdeki oyuncuların ID'lerini al
                const filteredPlayers = uniquePlayers.filter(player => cityPlayersIds.has(player.player_id)); // Sadece bu şehirdeki oyuncular

                setAllPlayers(filteredPlayers); // Filtrelenmiş oyuncuları state'e ata
                

            } catch (err) {
                console.error("Error fetching data", err);
            }
        }
        fetchData();
    }, [searchTermFirstPlayer, searchTermSecondPlayer, firstTeamPlayers, secondTeamPlayers]);

    useEffect(() => {
        // Match bilgilerini fetch et
        const fetchMatchData = async () => {
            try {
                const response = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar/${matchId}`);
                const match = response.data;

                // Gelen match bilgilerini matchData state'ine atayın
                setMatchData((prevData) => ({
                    ...prevData,
                    first_team_name: match.first_team_name,
                    second_team_name: match.second_team_name,
                    date: match.date,
                    time: match.time,
                    league_name: match.league_name,
                    match_season: match.match_season,

                }));
                // İlk ve ikinci takımın oyuncularını getir
                if (match.first_team_name && match.second_team_name) {
                    // Teams tablosundan first_team_id ve second_team_id'yi al
                    const teamsResponse = await axios.get('https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar');
                    const teams = teamsResponse.data;

                    const firstTeam = teams.find((team) => team.team_name === match.first_team_name);
                    const secondTeam = teams.find((team) => team.team_name === match.second_team_name);

                    if (firstTeam) {
                        const firstTeamPlayersResponse = await axios.get(
                            `https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-takimlari/${firstTeam.id}`
                        );
                        setFirstTeamPlayers(firstTeamPlayersResponse.data);
                    }

                    if (secondTeam) {
                        const secondTeamPlayersResponse = await axios.get(
                            `https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-takimlari/${secondTeam.id}`
                        );
                        setSecondTeamPlayers(secondTeamPlayersResponse.data);
                    }


                }
            } catch (error) {
                console.error('Error fetching match data:', error);
            }
        };

        fetchMatchData();
    }, []); // matchId değiştiğinde tekrar fetch yap

    const handleChange = (e) => {
        const { name, value } = e.target;
        setMatchData((prevData) => ({
            ...prevData,
            [name]: value
        }));

    };



    const handlePlayerSelection = (playerId, isFirstTeam) => {
        if (isFirstTeam) {
            // Eğer oyuncu ikinci takımda seçilmişse, uyarı göster
            if (selectedSecondTeamPlayers.includes(playerId)) {
                alert("Aynı oyuncu iki takımda birden oynayamaz!");
                return;
            }

            setSelectedFirstTeamPlayers((prev) => {
                const updatedSet = new Set(prev); // Mevcut listeyi Set yapısına dönüştür
                if (updatedSet.has(playerId)) {
                    updatedSet.delete(playerId); // Seçilmişse çıkar
                } else {
                    updatedSet.add(playerId); // Seçili değilse ekle
                }
                return Array.from(updatedSet); // Set'i tekrar diziye dönüştür
            });
        } else {
            // Eğer oyuncu ilk takımda seçilmişse, uyarı göster
            if (selectedFirstTeamPlayers.includes(playerId)) {
                alert("Aynı oyuncu iki takımda birden oynayamaz!");
                return;
            }

            setSelectedSecondTeamPlayers((prev) => {
                const updatedSet = new Set(prev);
                if (updatedSet.has(playerId)) {
                    updatedSet.delete(playerId);
                } else {
                    updatedSet.add(playerId);
                }
                return Array.from(updatedSet);
            });
        }
    };


    const handlePlayerSelectionFromAll = (playerId, isFirstTeam) => {
        if (isFirstTeam) {
            if (selectedSecondTeamPlayers.includes(playerId)) {
                alert("Aynı oyuncu iki takımda birden oynayamaz!");
                return;
            }

            setSelectedFirstTeamPlayers((prev) => {
                const updatedSet = new Set(prev); // Mevcut oyuncuları bir Set yapısına ekle
                if (updatedSet.has(playerId)) {
                    updatedSet.delete(playerId); // Eğer seçiliyse çıkar
                } else {
                    updatedSet.add(playerId); // Eğer seçili değilse ekle
                }
                return Array.from(updatedSet); // Set'i tekrar diziye çevir
            });
        } else {
            if (selectedFirstTeamPlayers.includes(playerId)) {
                alert("Aynı oyuncu iki takımda birden oynayamaz!");
                return;
            }

            setSelectedSecondTeamPlayers((prev) => {
                const updatedSet = new Set(prev);
                if (updatedSet.has(playerId)) {
                    updatedSet.delete(playerId);
                } else {
                    updatedSet.add(playerId);
                }
                return Array.from(updatedSet);
            });
        }
    };


    /*   const handlePlayerSelectionFromAll = (playerId, isFirstTeam) => {
           if (isFirstTeam) {
               // Eğer oyuncu ikinci takımda seçilmişse, uyarı göster
               if (selectedSecondTeamPlayers.includes(playerId)) {
                   alert("Aynı oyuncu iki takımda birden oynayamaz!");
                   return;
               }
   
               setSelectedFirstTeamPlayers((prev) => {
                   if (prev.includes(playerId)) {
                       return prev.filter((id) => id !== playerId); // Remove if already selected
                   } else {
                       console.log("ilk takim secilen ", selectedFirstTeamPlayers);
   
                       return [...prev, playerId]; // Add if not selected
   
                   }
               });
           } else {
              
               // Eğer oyuncu ilk takımda seçilmişse, uyarı göster
               if (selectedFirstTeamPlayers.includes(playerId)) {
                   alert("Aynı oyuncu iki takımda birden oynayamaz!");
                   return;
               }
   
              setSelectedSecondTeamPlayers((prev) => {
                   if (prev.includes(playerId)) {
                       return prev.filter((id) => id !== playerId); // Remove if already selected
                   } else {
                       console.log("ikinci takim secilen ", selectedSecondTeamPlayers);
   
                       return [...prev, playerId]; // Add if not selected
                   }
               });
           }
       };*/


    const updateMatchScores = async (matchId, firstTeamScore, secondTeamScore) => {
        try {
            // Match verisini güncellemek için API çağrısı
            const updatedData = {
                first_team_score: firstTeamScore,
                second_team_score: secondTeamScore,
                first_team_percentage: matchData.first_team_percentage,
                second_team_percentage: matchData.second_team_percentage,
                match_mvp: matchData.match_mvp || null,
                is_it_fixture: false
            };

            const response = await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar/${matchId}`, updatedData);

        } catch (error) {
            console.error("Error updating match scores:", error);
            alert("An error occurred while updating match scores. Please try again.");
        }
    };

    const handleAddPlayerStatistics = async (matchId) => {
        try {


            // İlk ve ikinci takımın toplam gollerini hesapla
            const firstTeamGoals = selectedFirstTeamPlayers.reduce((total, playerId) => {
                return total + (playerStatistics[playerId]?.goals || 0);
            }, 0);

            const secondTeamGoals = selectedSecondTeamPlayers.reduce((total, playerId) => {
                return total + (playerStatistics[playerId]?.goals || 0);
            }, 0);

            // Skorları API'ye kaydet
            await updateMatchScores(matchId, firstTeamGoals, secondTeamGoals);



            // Güncel skorları local state'de tut
            setMatchData((prevData) => ({
                ...prevData,
                first_team_score: firstTeamGoals,
                second_team_score: secondTeamGoals,
            }));

            // ILK TAKIM  VE IKINIC TAKIMIN TAKIM ID LERINI BUL
            // Takım adlarından ID'leri bulma 
            console.log("İlk Takım adi:", matchData.first_team_name);
            const firstTeam = teams.find(team => team.team_name === matchData.first_team_name);
            console.log("İlk Takım bulunana adi:", firstTeam.team_name);
            const secondTeam = teams.find(team => team.team_name === matchData.second_team_name);

            const firstTeamId = firstTeam.id;
            const secondTeamId = secondTeam.id;
            console.log("İlk Takım ID:", firstTeamId);
            console.log("İkinci Takım ID:", secondTeamId)


            const statisticsEntries = [];
            for (const playerId of [...selectedFirstTeamPlayers, ...selectedSecondTeamPlayers]) {
                const playerStats = playerStatistics[playerId];
                if (!playerStats) continue;

                 // Takım ID'lerini belirleme
                const isPlayerInFirstTeam = selectedFirstTeamPlayers.includes(playerId);
                const teamId = isPlayerInFirstTeam ? firstTeamId : secondTeamId;
                const opponentTeamId = isPlayerInFirstTeam ? secondTeamId : firstTeamId;
                // Verileri sayısal formata dönüştürme
                const statsData = {
                    player_id: playerId,
                    match_id: matchId,
                    team_id: teamId,
                    opponent_team_id: opponentTeamId,
                    number_of_goals: parseInt(playerStats.goals, 10) || 0,
                    goals_minutes: playerStats.goals_minutes || '',
                    yellow_card: parseInt(playerStats.yellow_card, 10) || 0,
                    red_card: parseInt(playerStats.red_card, 10) || 0,
                    points: parseInt(playerStats.points, 10) || 0,
                    goal_to_himself: parseInt(playerStats.goal_to_himself, 10) || 0,
                    goal_to_himself_minutes: playerStats.goal_to_himself_minutes || '',
                    player_position: playerStats.position || ''
                };

                statisticsEntries.push(statsData);
            }

             // Maç sonucunu belirle
            const { winnerTeamId, loserTeamId, draw } = determineMatchOutcome(
                    firstTeamGoals,
                    secondTeamGoals,
                    firstTeamId,
                    secondTeamId
                );

            // Takım istatistiklerini güncelle
            try {
                await updateTeamStatistics(
                    firstTeamId,
                    secondTeamId,
                    firstTeamGoals,
                    secondTeamGoals,
                    winnerTeamId === firstTeamId,
                    draw
                );
                await updateTeamStatistics(
                    secondTeamId,
                    firstTeamId,
                    secondTeamGoals,
                    firstTeamGoals,
                    winnerTeamId === secondTeamId,
                    draw
                );
            } catch (err) {
                console.error("Takım istatistikleri güncellenirken hata oluştu:", err);
            }

           console.log("Payload being sent:", statisticsEntries);
            for (const entry of statisticsEntries) {
                try {
                    await axios.post("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-istatistikleri/", entry);

                    // Her bir entry için updatePlayerStatistics çağrısı yap
                    try {
                        await updatePlayerStatistics(entry.player_id, entry, winnerTeamId, loserTeamId, draw);

                    } catch (err) {
                        console.error("Error updating player statistics for entry:", entry, err);
                    }

                } catch (err) {
                    console.error("Error adding player statistics for entry:", entry, err);
                }
            }
     

        } catch (err) {
            console.error("Error adding player statistics", err);
            alert("An error occurred while adding player statistics.");
        }
    };

    const updatePlayerStatistics = async (playerId, statsData, winnerTeamId, loserTeamId, draw) => {
        try {
            // Oyuncunun bilgilerini Players tablosundan çek
            const playerResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/${playerId}`);
            const playerData = playerResponse.data[0];

            // Yeni değerleri hesapla
            const howManyYellowCard = statsData.yellow_card !== 0 ? 1 : 0;
            const howManyRedCard = statsData.red_card !== 0 ? 1 : 0;

            const updatedData = {
                total_matches: playerData.total_matches + 1,
                total_goals: playerData.total_goals + (statsData.number_of_goals),
                total_points: playerData.total_points + (statsData.points),
                total_yellow_cards: playerData.total_yellow_cards + howManyYellowCard,
                total_red_cards: playerData.total_red_cards + howManyRedCard,
            };
            
            // Maç sonucu kontrolü
            if (draw) {
                updatedData.draws = playerData.draws + 1;
            } else if (statsData.team_id === winnerTeamId) {
                updatedData.wins = playerData.wins + 1;
            } else if (statsData.team_id === loserTeamId) {
                updatedData.losses = playerData.losses + 1;
            }

            // Oyuncunun bilgilerini güncelle
            await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/${playerId}`, updatedData);
        } catch (err) {
            console.error("Error updating player statistics:", err);
        }
    };

    const updateTeamStatistics = async (teamId, opponentTeamId, teamGoals, opponentGoals, isWinner, isDraw) => {
        try {
            // Takım bilgilerini Teams tablosundan çek
            const teamResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/${teamId}`);
            const teamData = teamResponse.data;

            // Güncellenecek verileri hazırlama
            const updatedData = {
                total_matches: teamData.total_matches + 1,
                goals_scored: teamData.goals_scored + teamGoals,
                goals_conceded: teamData.goals_conceded + opponentGoals,

            };

            // Maç sonucuna göre güncellemeler
            if (isDraw) {
                updatedData.team_draws = teamData.team_draws + 1;
            } else if (isWinner) {
                updatedData.team_wins = teamData.team_wins + 1;
            } else {
                updatedData.team_losses = teamData.team_losses + 1;
            }

            // Takım bilgilerini güncelle
            await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/${teamId}`, updatedData);

        } catch (err) {
            console.error("Takım bilgileri güncellenirken hata oluştu:", err);
        }
    }

    const determineMatchOutcome = (updatedFirstScore, updatedSecondScore, first_team_id, second_team_id) => {
        const firstTeamScore = updatedFirstScore;
        const secondTeamScore = updatedSecondScore;
        console.log("ilk takim skor: ", updatedFirstScore, "ikinci takim skor: ", updatedSecondScore);
        let winnerTeamId = null;
        let loserTeamId = null;
        let draw = false;

        if (firstTeamScore > secondTeamScore) {
            winnerTeamId = first_team_id;
            loserTeamId = second_team_id;
        } else if (secondTeamScore > firstTeamScore) {
            winnerTeamId = second_team_id;
            loserTeamId = first_team_id;
        } else {
            draw = true;
        }

        return { winnerTeamId, loserTeamId, draw };
    };

    const handlePlayerStatsChange = (playerId, field, value) => {
        if (value < 0) {
            alert("Negatif değer giremezsiniz.");
            return;
        }
        setIsButtonVisible(true);

    
        setPlayerStatistics((prevState) => {
            const updatedStatistics = {
                ...prevState,
                [playerId]: {
                    ...prevState[playerId],
                    [field]: value,
                },
            };
            return updatedStatistics;
        });
    };

    useEffect(() => {
        const saveMatchAndStatistics = async () => {
            if (isStatsUpdated) {
                try {

                    // Yeni maç ID'sini al ve istatistikleri kaydet
                    await handleAddPlayerStatistics(matchId);

                    alert("Maç ve oyuncu istatistikleri başarıyla kaydedildi!");
                } catch (error) {
                    console.error("Maç ve oyuncu istatistikleri kaydedilirken hata oluştu:", error);
                    alert("Bir hata oluştu. Lütfen tekrar deneyin.");
                } finally {
                    setIsStatsUpdated(false); // İşlem tamamlandıktan sonra flag'i sıfırla
                }
            }
        };

        saveMatchAndStatistics();
    }, [isStatsUpdated, playerStatistics]);
    
    const handleSaveMatchAndStatistics = async () => {
        // Güncelleme tamamlandığında işlemleri tetiklemek için bir state kullanıyoruz

        const allSelectedPlayers = [
            ...selectedFirstTeamPlayers,
            ...selectedSecondTeamPlayers,
        ];

         for (const playerId of allSelectedPlayers) {
                    const playerStats = playerStatistics[playerId];
            
                    if (playerStats) {
                        const { goals = 0, goals_minutes = "" } = playerStats;
            
                        // Gol dakikalarını boşlukla ayrılmış bir diziye çevir
                        const goalMinutesArray = goals_minutes
                            .split(" ")
                            .filter((minute) => minute.trim() !== "");
            
                        // Eğer gol sayısı 0'dan büyükse ve dakikalar eşleşmiyorsa, hata göster
                        if (goals > 0 && goalMinutesArray.length !== goals) {
                            alert(
                                `Oyuncu  için girilen gol sayısı (${goals}) ile gol dakikaları (${goals_minutes}) eşleşmiyor!`
                            );
                            return; // İşlemi durdur
                        }
                    }
                }

        setIsStatsUpdated(false); // Güncelleme başlıyor

         // Eksik istatistikleri tamamla
        allSelectedPlayers.forEach((playerId) => {
            ['goals', 'points', 'yellow_card', 'red_card', 'goals_minutes'].forEach((field) => {
                const currentPlayerStats = playerStatistics[playerId] || {};
                if (currentPlayerStats[field] === undefined || currentPlayerStats[field] === null) {
                    handlePlayerStatsChange(playerId, field, field === 'goals_minutes' ? '' : 0);
                }
            });
        });

        // Tüm güncellemeler tamamlandıktan sonra isStatsUpdated flag'ini true yap
        setTimeout(() => {
            setIsStatsUpdated(true);
        }, 0); // React state güncellemesini beklemek için
    };

    const refreshPlayers = async (newPlayerIds = [], isFirstTeam = true) => {
        if (isFirstTeam && matchData.first_team_name) {
            const firstTeam = teams.find(
                (team) => team.team_name === matchData.first_team_name
            );
            if (firstTeam) {
                try {
                    const response = await axios.get(
                        `https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-takimlari/${firstTeam.id}`
                    );
                    const updatedPlayers = response.data;
                    setFirstTeamPlayers(updatedPlayers);

                    // Yeni eklenen oyuncuları seçili hale getir
                    setSelectedFirstTeamPlayers((prevSelected) => {
                        const allSelected = [
                            ...prevSelected,
                            ...newPlayerIds,
                        ];
                        return [...new Set(allSelected)]; // Benzersiz liste oluştur
                    });
                } catch (error) {
                    console.error("Error refreshing first team players:", error);
                }
            }
        }

        if (!isFirstTeam && matchData.second_team_name) {
            const secondTeam = teams.find(
                (team) => team.team_name === matchData.second_team_name
            );
            if (secondTeam) {
                try {
                    const response = await axios.get(
                        `https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-takimlari/${secondTeam.id}`
                    );
                    const updatedPlayers = response.data;
                    setSecondTeamPlayers(updatedPlayers);

                    // Yeni eklenen oyuncuları seçili hale getir
                    setSelectedSecondTeamPlayers((prevSelected) => {
                        const allSelected = [
                            ...prevSelected,
                            ...newPlayerIds,
                        ];
                        return [...new Set(allSelected)]; // Benzersiz liste oluştur
                    });
                } catch (error) {
                    console.error("Error refreshing second team players:", error);
                }
            }
        }
    };


    // Tüm seçilen oyuncuları tek bir listede toplama
    const allSelectedPlayers = [
        ...allPlayers.filter(player => selectedFirstTeamPlayers.includes(player.player_id)),
        ...allPlayers.filter(player => selectedSecondTeamPlayers.includes(player.player_id))
    ];

    // Filter first and second team players and all players in separate useEffects
    useEffect(() => {
        const filteredFirst = firstTeamPlayers
            .filter((player) => player.player_name.toLowerCase().includes(searchTermFirstPlayer.toLowerCase()))
            .sort((a, b) => a.player_name.localeCompare(b.player_name)); // Sort alphabetically
        setFilteredFirstTeamPlayers(filteredFirst);
    }, [searchTermFirstPlayer, firstTeamPlayers]);

    useEffect(() => {
        const filteredSecond = secondTeamPlayers
            .filter((player) => player.player_name.toLowerCase().includes(searchTermSecondPlayer.toLowerCase()))
            .sort((a, b) => a.player_name.localeCompare(b.player_name)); // Sort alphabetically
        setFilteredSecondTeamPlayers(filteredSecond);
    }, [searchTermSecondPlayer, secondTeamPlayers]);

    useEffect(() => {
        const filteredAllFirst = allPlayers.filter(player =>
            player.player_name.toLowerCase().includes(searchTermFirstAll.toLowerCase())
        );
        setfilteredAllPlayersForFirst(filteredAllFirst);
    }, [searchTermFirstAll, allPlayers]);

    useEffect(() => {
        const filteredAllSecond = allPlayers.filter(player =>
            player.player_name.toLowerCase().includes(searchTermSecondAll.toLowerCase())
        );
        setfilteredAllPlayersForSecond(filteredAllSecond);
    }, [searchTermSecondAll, allPlayers]);

    const handleKeyNavigation = (e, rowIndex, colIndex, tableId) => {
        const keyCode = e.keyCode || e.which;
        let nextCell;
    
        if (keyCode === 37) { // Sol ok
            nextCell = document.querySelector(`[data-table="${tableId}"][data-row="${rowIndex}"][data-col="${colIndex - 1}"]`);
        } else if (keyCode === 39) { // Sağ ok
            nextCell = document.querySelector(`[data-table="${tableId}"][data-row="${rowIndex}"][data-col="${colIndex + 1}"]`);
        }
    
        if (nextCell) {
            e.preventDefault(); // Varsayılan davranışı engelle
            const input = nextCell.querySelector("input, select, textarea");
            if (input) {
                input.focus(); // Input'a odaklan
                if (input.tagName === "INPUT" || input.tagName === "TEXTAREA") {
                    input.select(); // Metni seçili hale getir
                }
            } else {
                nextCell.focus(); // Hücrenin kendisine odaklan
            }
        }
    };

     const handleDeleteMatch = async () => {
        const confirmDelete = window.confirm("Bu maçı silmek istediğinizden emin misiniz?");
        if (!confirmDelete) return;

        try {
            await axios.delete(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar/${matchId}`);
            alert("Maç başarıyla silindi.");
            navigate("/editor/maclar"); // Maçlar listesine geri dön
        } catch (error) {
            console.error("Maçı silerken hata oluştu:", error);
            alert("Maçı silerken bir hata oluştu. Lütfen tekrar deneyin.");
        }
    };

     // FIKSTURLU MACIN TAKIMLARINI DEGISTIRME ICIN MODAL
    const [showTeamChangeModal, setShowTeamChangeModal] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [filteredTeams, setFilteredTeams] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    const handleOpenTeamChangeModal = (teamType) => {
        setSelectedTeam(teamType);
        setShowTeamChangeModal(true);
      };
    
      const handleTeamSelect = (teamName) => {
        setMatchData((prevData) => ({
          ...prevData,
          [selectedTeam]: teamName,
        }));
        //setShowTeamChangeModal(false);
      };
    
      const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setFilteredTeams(
          teams.filter((team) => team.team_name.toLowerCase().includes(e.target.value.toLowerCase()))
        );
      };
    
      const handleSaveTeams = async () => {
        if (matchData.first_team_name === matchData.second_team_name) {
            alert("Hata: İki takım aynı olamaz! Lütfen farklı bir takım seçin.");
            return;
        }
    
        try {
            await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar/${matchId}`, {
                first_team_name: matchData.first_team_name,
                second_team_name: matchData.second_team_name,
            });
    
            alert("Takımlar başarıyla güncellendi!");
            setShowTeamChangeModal(false); // Modalı kapat
            window.location.reload(); // Sayfayı yenile
        } catch (error) {
            console.error("Error updating teams:", error);
            alert("Takımları güncellerken bir hata oluştu!");
        }
    };

    //MACIN TARIH VE SAATINI GUNCELLEME

    const updateMatchField = async (field) => {
        try {
            await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar/${matchId}`, {
                [field]: matchData[field]
            });

            alert(`${field === "date" ? "Tarih" : "Saat"} başarıyla güncellendi!`);
        } catch (error) {
            console.error("Error updating match:", error);
            alert("Güncelleme sırasında bir hata oluştu.");
        }
    };



    return (
        <div className='add-match-container'>
            <h2 className="add-match-title" >Maç İstatistikleri Gir</h2>

         {/*TAKIM DEGISIMI */}
                {/* Takım Değiştir Butonu */}
            <button className="add-match-add-player-button" onClick={() => setShowTeamChangeModal(true)}>
                Takım Değiştir
            </button>

            {/* Modal - Takım Değiştirme */}
            {showTeamChangeModal && (
                <div className="modal-overlay">
                <div className="modal-content">
                    <button className="close-button-player" onClick={() => setShowTeamChangeModal(false)}>
                    &times;
                    </button>

                    <div className="modal-title-addTeam">Takım Değiştir</div>

                    {/* İlk ve İkinci Takımı Değiştirme Butonları */}
                    <div className="team-selection">
                    <button
                        className={`team-select-button ${selectedTeam === "first_team_name" ? "selected" : ""}`}
                        onClick={() => handleOpenTeamChangeModal("first_team_name")}
                    >
                        {matchData.first_team_name}
                    </button>
                    <button
                        className={`team-select-button ${selectedTeam === "second_team_name" ? "selected" : ""}`}
                        onClick={() => handleOpenTeamChangeModal("second_team_name")}
                    >
                        {matchData.second_team_name}
                    </button>
                    </div>

                    {/* Takım Arama Kutusu */}
                    {selectedTeam && (
                    <>
                        <input
                        type="text"
                        placeholder="Takım ara..."
                        value={searchTerm}
                        onChange={handleSearch}
                        className="custom-input"
                        />
                        <div className="team-list">
                        {filteredTeams.map((team) => (
                            <div
                            key={team.id}
                            className={`team-item ${matchData[selectedTeam] === team.team_name ? "selected" : ""}`}
                            onClick={() => handleTeamSelect(team.team_name)}
                            >
                            {team.team_name}
                            </div>
                        ))}
                        </div>
                    </>
                    )}

                    <button className="create-button" onClick={handleSaveTeams}>
                    Yeni Takımları Kaydet
                    </button>
                </div>
                </div>
                )}
        
        
           {/*FORMUN GERI KALANI*/}   
            <form >
                <div className="add-match-team-box">
                    {firstTeamPlayers.length >= 0 && (
                        <div className="add-match-team-section">
                            <label className='add-match-label'>{matchData.first_team_name} Oyuncuları</label>

                            <input
                                type="text"
                                placeholder="İlk Takım oyuncusu arayın"
                                value={searchTermFirstPlayer}
                                onChange={(e) => setSearchTermFirstPlayer(e.target.value)}
                                className="add-match-player-search-input"
                            />
                            <ul className="add-match-player-list add-match-scrollable">
                                {filteredFirstTeamPlayers.map((player) => (
                                    <li key={player.player_id} className="add-match-player-list-item">
                                        <input
                                            type="checkbox"
                                            className='add-match-input'
                                            checked={isSelected(player.player_id, true)}
                                            onChange={() => {
                                                handlePlayerSelection(player.player_id, true);
                                            }}
                                        />
                                        {player.player_name}
                                    </li>
                                ))}
                            </ul>

                            {/*Ilk takima tum oyunculardan oyuncu secmek*/}
                            <button
                                className={`add-match-toggle-button ${showAllPlayersForFirstTeam ? "open" : ""}`}
                                onClick={() =>
                                    setShowAllPlayersForFirstTeam(prev => !prev)
                                }
                            >
                                <span className="arrow"></span>
                                {showAllPlayersForFirstTeam ? "Tüm Oyunculardan Seçimi Kapat" : "Tüm Oyunculardan Seç"}
                            </button>

                            {showAllPlayersForFirstTeam && (
                                <div>
                                    <input
                                        type="text"
                                        placeholder="Oyuncu ara"
                                        value={searchTermFirstAll}
                                        onChange={(e) => setSearchTermFirstAll(e.target.value)}
                                        className="add-match-player-search-input"
                                    />
                                    <ul className="add-match-player-list add-match-scrollable">
                                        {filteredAllPlayersForFirst.map(player => (
                                            <li key={player.player_id} className="add-match-player-list-item">
                                                <input
                                                    type="checkbox"
                                                    className="add-match-player-search-input"
                                                    checked={isSelected(player.player_id, true)}
                                                    onChange={() => handlePlayerSelectionFromAll(player.player_id, true)}
                                                />
                                                {player.player_name} {player.team_name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            <button
                                className="add-match-add-player-button"
                                onClick={() => setShowAddPlayerModalForFirstTeam(true)}
                            >
                                Yeni Oyuncu Ekle
                            </button>
                            {/* Modal for İlk Takım */}
                            {showAddPlayerModalForFirstTeam && (
                                <Add_Players
                                    closeModal={() => setShowAddPlayerModalForFirstTeam(false)}
                                    refreshTeams={refreshPlayers}
                                    onPlayerAdded={(newPlayerIds) =>
                                        refreshPlayers(newPlayerIds, true) // İlk takıma yeni oyuncuları ekle
                                    }
                                         defaultTeam={matchData.first_team_name}
                                />
                            )}
                        </div>
                    )}

                    {secondTeamPlayers.length >= 0 && (
                        <div className="add-match-team-section">
                            <label className='add-match-label'>{matchData.second_team_name} Oyuncuları</label>
                            <input
                                type="text"
                                placeholder="İkinci Takım oyuncusu arayın"
                                value={searchTermSecondPlayer}
                                onChange={(e) => setSearchTermSecondPlayer(e.target.value)}
                                className="add-match-player-search-input"
                            />
                            <ul className="add-match-player-list add-match-scrollable">
                                {filteredSecondTeamPlayers.map((player) => (
                                    <li key={player.player_id} className="add-match-player-list-item">
                                        <input
                                            type="checkbox"
                                            className="add-match-player-search-input"
                                            checked={isSelected(player.player_id, false)}
                                            onChange={() => {
                                                handlePlayerSelection(player.player_id, false);
                                            }}
                                        />
                                        {player.player_name}
                                    </li>
                                ))}
                            </ul>

                            <button
                                className={`add-match-toggle-button ${showAllPlayersForSecondTeam ? "open" : ""}`}
                                onClick={() =>
                                    setShowAllPlayersForSecondTeam(prev => !prev)
                                }
                            >
                                <span className="arrow"></span>
                                {showAllPlayersForSecondTeam ? "Tüm Oyunculardan Seçimi Kapat" : "Tüm Oyunculardan Seç"}
                            </button>

                            {showAllPlayersForSecondTeam && (
                                <div>
                                    <input
                                        type="text"
                                        placeholder="Oyuncu ara"
                                        value={searchTermSecondAll}
                                        onChange={(e) => setSearchTermSecondAll(e.target.value)}
                                        className="add-match-player-search-input"
                                    />
                                    <ul className="add-match-player-list add-match-scrollable">
                                        {filteredAllPlayersForSecond.map(player => (
                                            <li key={player.player_id} className="add-match-player-list-item">
                                                <input
                                                    type="checkbox"
                                                    className="add-match-player-search-input"
                                                    checked={isSelected(player.player_id, false)}
                                                    onChange={() => handlePlayerSelectionFromAll(player.player_id, false)}
                                                />
                                                {player.player_name} {player.team_name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            <button
                                className="add-match-add-player-button"
                                onClick={() => setShowAddPlayerModalForSecondTeam(true)}
                            >
                                Yeni Oyuncu Ekle
                            </button>
                            {/* Modal for İlk Takım */}
                            {showAddPlayerModalForSecondTeam && (
                                <Add_Players
                                    closeModal={() => setShowAddPlayerModalForSecondTeam(false)}
                                    refreshTeams={refreshPlayers}
                                    onPlayerAdded={(newPlayerIds) =>
                                        refreshPlayers(newPlayerIds, false) // İkinci takıma yeni oyuncuları ekle
                                    }
                                 defaultTeam={matchData.second_team_name}
                                />
                            )}
                        </div>
                    )}
                </div>

                {!isFixtureLeague && (
                    <>
                        <div className="add-match-form-group">
                            <label className="add-match-label">İlk Takım Yüzdesi</label>
                            <input
                                type="text"
                                className="add-match-player-search-input"
                                name="first_team_percentage"
                                value={matchData.first_team_percentage}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (!isNaN(value) && value >= 0 && value <= 100) {
                                        // Yalnızca 0-100 arasında bir sayı kabul edilir
                                        handleChange(e);
                                    } else if (value === "") {
                                        // Boş değer de kabul edilir
                                        handleChange(e);
                                    }
                                }}
                                required
                            />
                        </div>

                        <div className="add-match-form-group">
                            <label className="add-match-label">İkinci Takım Yüzdesi</label>
                            <input
                                type="text"
                                className="add-match-player-search-input"
                                name="second_team_percentage"
                                value={matchData.second_team_percentage}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (!isNaN(value) && value >= 0 && value <= 100) {
                                        // Yalnızca 0-100 arasında bir sayı kabul edilir
                                        handleChange(e);
                                    } else if (value === "") {
                                        // Boş değer de kabul edilir
                                        handleChange(e);
                                    }
                                }}
                                required
                            />
                        </div>
                    </>
                )}
                {!isFixtureLeague && (
                    <>
                        <div className="add-match-form-group">
                            <label className='add-match-label' >En Değerli Oyuncu (MVP)</label>
                            <select className="add-match-select" name="match_mvp" onChange={handleChange} required>
                                <option value="">Oyuncu Seç</option>
                                {allSelectedPlayers.map((player) => (
                                    <option key={player.player_id} value={player.player_id}>
                                        {player.player_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </>
                )}

            </form>

{/* SAAT VE TARIH GUNCELLEME  */}
            <form className="date-time-update-form">
                {/* Tarih Güncelleme Alanı */}
                <div className="date-time-group">
                    <label className="add-match-label">Maç Tarihi</label>
                    <input
                        type="date"
                        name="date"
                        value={matchData.date}
                        onChange={handleChange}
                        className="date-time-input"
                    />
                    <button
                        type="button"
                        className="add-match-add-player-button"
                        onClick={() => updateMatchField("date")}
                    >
                         Güncelle
                    </button>
                </div>

                {/* Saat Güncelleme Alanı */}
                <div className="date-time-group">
                    <label className="add-match-label">Maç Saati</label>
                    <input
                        type="time"
                        name="time"
                        value={matchData.time}
                        onChange={handleChange}
                        className="date-time-input"
                    />
                    <button
                        type="button"
                        className="add-match-add-player-button"
                        onClick={() => updateMatchField("time")}
                    >
                         Güncelle
                    </button>
                </div>
            </form>


            {(selectedFirstTeamPlayers.length > 0 || selectedSecondTeamPlayers.length > 0) && (
                <div className="add-match-statistics-container">
                    <h2>Oyuncu İstatistikleri Ekle</h2>

                    {/* İlk Takım Oyuncuları Tablosu */}
                    <h3>{matchData.first_team_name} Oyuncuları</h3>
                    <table className="add-match-player-statistics-table">
                        <thead>
                            <tr>
                                <th>Oyuncu</th>
                                <th>Mevki</th>
                                <th>Gol</th>
                                <th>Puan</th>
                                <th>SK</th>
                                <th>KK</th>
                                <th>Gol Dakikaları</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allPlayers
                                .filter((player) => selectedFirstTeamPlayers.includes(player.player_id))
                                .map((player, rowIndex) => (
                                    <tr key={player.player_id}>
                                        <td data-table="first" data-row={rowIndex} data-col={0} tabIndex={0}>
                                            {player.player_name}
                                        </td>
                                        <td data-table="first" data-row={rowIndex} data-col={1} tabIndex={0}>
                                            <select
                                                onKeyDown={(e) =>
                                                    handleKeyNavigation(e, rowIndex, 1,"first")
                                                }
                                                onChange={(e) =>
                                                    handlePlayerStatsChange(player.player_id, "position", e.target.value)
                                                }
                                            >
                                               <option value="">Seç</option>
                                                <option value="orta saha">orta saha</option>
                                                <option value="kaleci">kaleci</option>
                                                <option value="defans">defans</option>
                                                <option value="forvet">forvet</option>
                                            </select>
                                        </td>
                                        <td data-table="first" data-row={rowIndex} data-col={2} tabIndex={0}>
                                            <input
                                                type="text"

                                                onKeyDown={(e) =>
                                                    handleKeyNavigation(e, rowIndex, 2,"first")
                                                }
                                                onFocus={(e) => e.target.select()}
                                                defaultValue={0}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d*$/.test(value)) {
                                                        handlePlayerStatsChange(player.player_id, "goals", parseInt(value, 10) || 0);
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td data-table="first" data-row={rowIndex} data-col={3} tabIndex={0}>
                                            <input
                                                type="text"
                                                onFocus={(e) => e.target.select()}
                                                onKeyDown={(e) =>
                                                    handleKeyNavigation(e, rowIndex, 3,"first")
                                                }
                                                defaultValue={0}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d*$/.test(value)) {
                                                        handlePlayerStatsChange(player.player_id, "points", parseInt(value, 10) || 0);
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td data-table="first" data-row={rowIndex} data-col={4} tabIndex={0}>
                                            <input
                                                type="text"
                                                onFocus={(e) => e.target.select()}
                                                onKeyDown={(e) =>
                                                    handleKeyNavigation(e, rowIndex, 4,"first")
                                                }
                                                defaultValue={0}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d*$/.test(value)) {
                                                        handlePlayerStatsChange(player.player_id, "yellow_card", parseInt(value, 10) || 0);
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td data-table="first" data-row={rowIndex} data-col={5} tabIndex={0}>
                                            <input
                                                type="text"
                                                onFocus={(e) => e.target.select()}
                                                onKeyDown={(e) =>
                                                    handleKeyNavigation(e, rowIndex, 5,"first")
                                                }
                                                defaultValue={0}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d*$/.test(value)) {
                                                        handlePlayerStatsChange(player.player_id, "red_card", parseInt(value, 10) || 0);
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td data-table="first" data-row={rowIndex} data-col={6} tabIndex={0}>
                                            <input
                                                type="text"
                                                onFocus={(e) => e.target.select()}
                                                onKeyDown={(e) =>
                                                    handleKeyNavigation(e, rowIndex, 6,"first")
                                                }
                                                placeholder="16'.. 21' 35"
                                                onChange={(e) =>
                                                    handlePlayerStatsChange(player.player_id, "goals_minutes", e.target.value)
                                                }
                                            />
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>

                    {/* İkinci Takım Oyuncuları Tablosu */}
                    {/* İkinci Takım Oyuncuları Tablosu */}
                    <h3>{matchData.second_team_name} Oyuncuları</h3>
                    <table className="add-match-player-statistics-table">
                        <thead>
                            <tr>
                                <th>Oyuncu</th>
                                <th>Mevki</th>
                                <th>Gol</th>
                                <th>Puan</th>
                                <th>SK</th>
                                <th>KK</th>
                                <th>Gol Dakikaları</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allPlayers
                                .filter((player) => selectedSecondTeamPlayers.includes(player.player_id))
                                .map((player, rowIndex) => (
                                    <tr key={player.player_id}>
                                        <td data-table="second" data-row={rowIndex} data-col={0} tabIndex={0}>
                                            {player.player_name}
                                        </td>
                                        <td data-table="second" data-row={rowIndex} data-col={1} tabIndex={0}>
                                            <select
                                                onKeyDown={(e) => handleKeyNavigation(e, rowIndex, 1,"second")}
                                                onChange={(e) => handlePlayerStatsChange(player.player_id, "position", e.target.value)}
                                            >
                                                <option value="">Seç</option>
                                                <option value="orta saha">orta saha</option>
                                                <option value="kaleci">kaleci</option>
                                                <option value="defans">defans</option>
                                                <option value="forvet">forvet</option>
                                            </select>
                                        </td>
                                        <td data-table="second" data-row={rowIndex} data-col={2} tabIndex={0}>
                                            <input
                                                type="text"
                                                defaultValue={0}
                                                onKeyDown={(e) => handleKeyNavigation(e, rowIndex, 2,"second")}
                                                onFocus={(e) => e.target.select()}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d*$/.test(value)) {
                                                        handlePlayerStatsChange(player.player_id, "goals", parseInt(value, 10) || 0);
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td data-table="second" data-row={rowIndex} data-col={3} tabIndex={0}>
                                            <input
                                                type="text"
                                                defaultValue={0}
                                                onKeyDown={(e) => handleKeyNavigation(e, rowIndex, 3,"second")}
                                                onFocus={(e) => e.target.select()}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d*$/.test(value)) {
                                                        handlePlayerStatsChange(player.player_id, "points", parseInt(value, 10) || 0);
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td data-table="second" data-row={rowIndex} data-col={4} tabIndex={0}>
                                            <input
                                                type="text"
                                                defaultValue={0}
                                                onKeyDown={(e) => handleKeyNavigation(e, rowIndex, 4,"second")}
                                                onFocus={(e) => e.target.select()}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d*$/.test(value)) {
                                                        handlePlayerStatsChange(player.player_id, "yellow_card", parseInt(value, 10) || 0);
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td data-table="second" data-row={rowIndex} data-col={5} tabIndex={0}>
                                            <input
                                                type="text"
                                                defaultValue={0}
                                                onKeyDown={(e) => handleKeyNavigation(e, rowIndex, 5,"second")}
                                                onFocus={(e) => e.target.select()}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d*$/.test(value)) {
                                                        handlePlayerStatsChange(player.player_id, "red_card", parseInt(value, 10) || 0);
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td data-table="second" data-row={rowIndex} data-col={6} tabIndex={0}>
                                            <input
                                                type="text"
                                                placeholder="16'.. 21' 35"
                                                onKeyDown={(e) => handleKeyNavigation(e, rowIndex, 6,"second")}
                                                onFocus={(e) => e.target.select()}
                                                onChange={(e) =>
                                                    handlePlayerStatsChange(player.player_id, "goals_minutes", e.target.value)
                                                }
                                            />
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>


                    {/* Kaydetme Butonu */}
                    {isButtonVisible && selectedFirstTeamPlayers.length > 0 && selectedSecondTeamPlayers.length > 0 && (
                        <button
                            className="add-match-stat-button"
                            type="submit"
                            onClick={() => {
                                handleSaveMatchAndStatistics();
                                setIsButtonVisible(false); // Butonu gizle
                            }}
                        >
                            Maçı ve Oyuncu İstatistiklerini Kaydet
                        </button>
                    )}
                </div>
            )}

            <div style={{ marginTop: "20px" }}>
                <button
                    className="add-match-add-player-button"
                    onClick={handleDeleteMatch}
                    style={{ backgroundColor: 'red', color: 'white' }}
                >
                    Maçı Sil
                </button>
            </div>


        </div>
    );
};

export default Enter_Stats;
