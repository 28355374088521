import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Update_Team.css';
//import './Update_Player.css';
import { useUser } from "../UserContext"; // UserContext'i çağır

const UpdatePlayer = ({ selectedPlayer, onClose, refreshPlayers }) => {
    const [updatedFields, setUpdatedFields] = useState({ ...selectedPlayer });
    const [errorMessage, setErrorMessage] = useState('');
    const [teams, setTeams] = useState([]);
    const [imageFile, setImageFile] = useState(null);
    const [seasons, setSeasons] = useState([]);
    const [isButtonVisible, setIsButtonVisible] = useState(true);
    const [isActive, setIsActive] = useState(null);
    const { user, setUser } = useUser();
    const userCity = user?.city;
    
    useEffect(() => {
        const fetchTeams = async () => {
            try {
                const teamsResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/by-city/${userCity}`);
                setTeams(teamsResponse.data);
                const seasonsResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/sezonlar/by-city/${userCity}`);
                setSeasons(seasonsResponse.data);
                
                setIsActive(selectedPlayer.active);
                

            } catch (error) {
                console.error("Error fetching teams:", error);
                setErrorMessage('Takımlar alınamadı.');
            }
        };

        fetchTeams();
    }, []);


    const refreshPlayer = async () => {
        try {
            // Seçili oyuncunun güncellenmiş bilgilerini çek
            const response = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/${selectedPlayer.id}`);
            const updatedPlayer = response.data;

            // Oyuncu bilgilerini güncelle
            setUpdatedFields(updatedPlayer);

        } catch (error) {
            console.error("Error refreshing player:", error);
            setErrorMessage("Oyuncu bilgileri güncellenirken bir hata oluştu.");
        }
    };

    const handleUpdatePlayer = async (field) => {
        if (field === 'player_img') {
            await handleImageUpdate();
            return;
        }

        if (updatedFields[field] === undefined || updatedFields[field] === null) return;

        try {
            // Eğer oyuncunun adı güncelleniyorsa PlayerTeams tablosunu da güncelle
            if (field === 'player_name' && updatedFields.player_name !== selectedPlayer.player_name) {
                const oldPlayerName = selectedPlayer.player_name; // Eski oyuncu adı
                const newPlayerName = updatedFields.player_name; // Yeni oyuncu adı

                // PlayerTeams tablosundaki oyuncu adını güncelle
                await axios.patch('https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-takimlari/update-player-name', {
                    oldPlayerName,
                    newPlayerName,
                });
            }
            if (field === 'team_id' && updatedFields.team_id !== selectedPlayer.team_id) {
                const newTeamId = updatedFields.team_id; // Yeni takım ID'si
                const newTeam = teams.find((team) => team.id === parseInt(newTeamId)); // Yeni takım bilgisi
                const oldTeamId = selectedPlayer.team_id; // Eski takım ID'si

                if (!newTeam) {
                    alert('Seçilen takım bulunamadı!');
                    return;
                }

                // PlayerTeams tablosuna yeni kayıt ekle
                await axios.post('https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-takimlari/add', {
                    player_id: selectedPlayer.id,
                    player_name: selectedPlayer.player_name,
                    team_id: newTeamId,
                    team_name: newTeam.team_name,
                });
            }


            // Players tablosundaki oyuncu bilgisini güncelle
            await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/${selectedPlayer.id}`, {
                [field]: updatedFields[field],
            });

            alert(`${field} başarıyla güncellendi!`);
           // Güncel bilgileri hem updatedFields hem de selectedPlayer'a ata
            const updatedPlayer = { ...selectedPlayer, [field]: updatedFields[field] };
            setUpdatedFields(updatedPlayer);
            refreshPlayers(); // Oyuncu listesini yenile
            
        } catch (error) {
            console.error(`Error updating ${field}:`, error);
            setErrorMessage(`${field} güncelleme işlemi başarısız.`);
        }
    };


    const handleImageUpdate = async () => {
        if (!imageFile) return;

        const formData = new FormData();
        formData.append('player_img', imageFile);

        try {
            const seasonsResponse = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/sezonlar");
            setSeasons(seasonsResponse.data);
            await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/updateImage/${selectedPlayer.id}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            alert('Oyuncu resmi başarıyla güncellendi!');
            refreshPlayers();
            onClose();
        } catch (error) {
            console.error("Error updating player image:", error);
            setErrorMessage('Resim güncelleme işlemi başarısız.');
        }
    };

      const handleDeletePlayer = async () => {
        const confirmDelete = window.confirm("Bu oyuncuyu silmek istediğinize emin misiniz?");
        if (!confirmDelete) return;
    
        try {
            await axios.delete(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/${selectedPlayer.id}`);
            alert("Oyuncu başarıyla silindi!");
            onClose(); // Modal'ı kapat
        } catch (error) {
            console.error("Error deleting player:", error);
            if (error.response && error.response.data.message) {
                alert(error.response.data.message); // Backend'den dönen mesajı göster
            } else {
                alert("Silme işlemi başarısız.");
            }
        }
    };

      const handleRemoveImage = async () => {
        try {
            await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/${selectedPlayer.id}`, {
                player_img: "https://elitlig-space.fra1.digitaloceanspaces.com/profilePhotos/default_player.png",
            });
            alert("Oyuncu resmi kaldırıldı ve varsayılan resim ayarlandı.");
            refreshPlayers();
        } catch (error) {
            console.error("Error removing player image:", error);
            setErrorMessage("Resim kaldırma işlemi başarısız.");
        }
    };

    const handleToggleActiveStatus = async () => {
       
        try {
            const newActiveStatus = !isActive;

            // Backend'e durumu güncelleme isteği gönder
            await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/${selectedPlayer.id}`, {
                active: newActiveStatus,
            });

            // State ve updatedFields güncelle
            setIsActive(newActiveStatus);
            setUpdatedFields((prev) => ({ ...prev, active: newActiveStatus }));

            alert(`Oyuncu durumu ${newActiveStatus ? 'aktif' : 'pasif'} olarak güncellendi.`);
            refreshPlayers(); // Oyuncu listesini yenile
        } catch (error) {
            console.error('Error updating active status:', error);
            alert('Oyuncu durumu güncellenirken bir hata oluştu.');
        }
    };

    if (!selectedPlayer) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2 className="modal-title">Oyuncu Güncelle</h2>
                <span className="close-button" onClick={onClose}>×</span>

                <div className="modal-scrollable">
                    {/* Oyuncu Adı */}
                    <div className="update-field">
                        <label className="logo-label" >Oyuncu Adı</label>
                        <input
                            type="text"
                            value={updatedFields.player_name || ''}
                            onChange={(e) => setUpdatedFields({ ...updatedFields, player_name: e.target.value })}
                        />
                        <button className="update-button" onClick={() => handleUpdatePlayer('player_name')}>Güncelle</button>            
                    </div>
                                
                     

                    {/* Oyuncu Resmi */}
                    <div className="update-field">
                        <label className="logo-label" >Resim Güncelle</label>
                        <input type="file" onChange={(e) => setImageFile(e.target.files[0])} />
                        <button className="update-button" onClick={handleImageUpdate}>Resim Güncelle</button>
                        <button
                            onClick={handleRemoveImage}
                            style={{
                                marginLeft: "10px",
                                backgroundColor: "red",
                                color: "white",
                                border: "none",
                                padding: "5px 10px",
                                cursor: "pointer",
                            }}
                        >
                            Fotoğrafı Kaldır
                        </button>
                    </div>

                    {/* Takım */}
                    <div className="update-field">
                        <label className="logo-label" >Takım</label>
                        <select
                            value={updatedFields.team_id || ''}
                            onChange={(e) => setUpdatedFields({ ...updatedFields, team_id: e.target.value })}
                        >
                            <option value="">Takım Seç</option>
                            {teams.map((team) => (
                                <option key={team.id} value={team.id}>
                                    {team.team_name}
                                </option>
                            ))}
                        </select>
                        <button className="update-button" onClick={() => handleUpdatePlayer('team_id')}>Güncelle</button>
                    </div>

                    {/* Mevki */}
                    <div className="update-field">
                        <label className="logo-label">Mevki</label>
                        <select
                            value={updatedFields.player_position || ''}
                            onChange={(e) => setUpdatedFields({ ...updatedFields, player_position: e.target.value })}
                        >
                            <option value="">Seç</option>
                            <option value="Kaleci">Kaleci</option>
                            <option value="Defans">Defans</option>
                            <option value="Orta Saha">Orta Saha</option>
                            <option value="Forvet">Forvet</option>
                        </select>
                        <button  className="update-button" onClick={() => handleUpdatePlayer('player_position')}>Güncelle</button>
                    </div>

                    {/* Doğum Tarihi */}
                    <div className="update-field">
                        <label className="logo-label">Doğum Tarihi</label>
                        <input
                            type="date"
                            value={updatedFields.birth_date || ''}
                            onChange={(e) => setUpdatedFields({ ...updatedFields, birth_date: e.target.value })}
                        />
                        <button className="update-button" onClick={() => handleUpdatePlayer('birth_date')}>Güncelle</button>
                    </div>

                    {/* Uyruk */}
                    <div className="update-field">
                        <label className="logo-label">Uyruk</label>
                        <input
                            type="text"
                            value={updatedFields.nationality || ''}
                            onChange={(e) => setUpdatedFields({ ...updatedFields, nationality: e.target.value })}
                        />
                        <button className="update-button" onClick={() => handleUpdatePlayer('nationality')}>Güncelle</button>
                    </div>

                    {/* Sezon */}
                    <div className="update-field">
                        <label className="logo-label">Sezon</label>
                        <select
                            value={updatedFields.season || ''}
                            onChange={(e) => setUpdatedFields({ ...updatedFields, season: e.target.value })}
                        >
                            <option value="">Sezon Seç</option>
                            {seasons.map((season) => (
                                <option key={season.id} value={season.season_name}>
                                    {season.season_name}
                                </option>
                            ))}
                        </select>
                        <button  className="update-button" onClick={() => handleUpdatePlayer('season')}>Güncelle</button>
                    </div>

                    {/* Performans Alanları */}

                    {/* Değer Güncelle */}
                    <div className="update-field">
                        <label className="logo-label">Değer</label>
                        <input
                            type="text"
                            value={updatedFields.value !== undefined ? updatedFields.value : 0}
                            onChange={(e) => {
                                const value = isNaN(parseInt(e.target.value, 10)) ? 0 : parseInt(e.target.value, 10);
                                setUpdatedFields({ ...updatedFields, value: value });
                            }}
                            className="short-input"
                        />
                        <button className="update-button" onClick={() => handleUpdatePlayer('value')}>Güncelle</button>
                    </div>

                    {/* Toplam Maç Güncelle */}
                    <div className="update-field">
                        <label className="logo-label">Toplam Maç</label>
                        <input
                            type="text"
                            value={updatedFields.total_matches !== undefined ? updatedFields.total_matches : 0}
                            onChange={(e) => {
                                const value = isNaN(parseInt(e.target.value, 10)) ? 0 : parseInt(e.target.value, 10);
                                setUpdatedFields({ ...updatedFields, total_matches: value });
                            }}
                            className="short-input"
                        />
                        <button className="update-button" onClick={() => handleUpdatePlayer('total_matches')}>Güncelle</button>
                    </div>

                    {/* Toplam Gol Güncelle */}
                    <div className="update-field">
                        <label className="logo-label">Toplam Gol</label>
                        <input
                            type="text"
                            value={updatedFields.total_goals !== undefined ? updatedFields.total_goals : 0}
                            onChange={(e) => {
                                const value = isNaN(parseInt(e.target.value, 10)) ? 0 : parseInt(e.target.value, 10);
                                setUpdatedFields({ ...updatedFields, total_goals: value });
                            }}
                            className="short-input"
                        />
                        <button className="update-button" onClick={() => handleUpdatePlayer('total_goals')}>Güncelle</button>
                    </div>

                    {/* Toplam Puan Güncelle */}
                    <div className="update-field">
                        <label className="logo-label">Toplam Puan</label>
                        <input
                            type="text"
                            value={updatedFields.total_points !== undefined ? updatedFields.total_points : 0}
                            onChange={(e) => {
                                const value = isNaN(parseInt(e.target.value, 10)) ? 0 : parseInt(e.target.value, 10);
                                setUpdatedFields({ ...updatedFields, total_points: value });
                            }}
                            className="short-input"
                        />
                        <button className="update-button" onClick={() => handleUpdatePlayer('total_points')}>Güncelle</button>
                    </div>

                    {/* Sarı Kart Güncelle */}
                    <div className="update-field">
                        <label className="logo-label">Sarı Kart</label>
                        <input
                            type="text"
                            value={updatedFields.total_yellow_cards !== undefined ? updatedFields.total_yellow_cards : 0}
                            onChange={(e) => {
                                const value = isNaN(parseInt(e.target.value, 10)) ? 0 : parseInt(e.target.value, 10);
                                setUpdatedFields({ ...updatedFields, total_yellow_cards: value });
                            }}
                            className="short-input"
                        />
                        <button className="update-button" onClick={() => handleUpdatePlayer('total_yellow_cards')}>Güncelle</button>
                    </div>

                    {/* Kırmızı Kart Güncelle */}
                    <div className="update-field">
                        <label className="logo-label">Kırmızı Kart</label>
                        <input
                            type="text"
                            value={updatedFields.total_red_cards !== undefined ? updatedFields.total_red_cards : 0}
                            onChange={(e) => {
                                const value = isNaN(parseInt(e.target.value, 10)) ? 0 : parseInt(e.target.value, 10);
                                setUpdatedFields({ ...updatedFields, total_red_cards: value });
                            }}
                            className="short-input"
                        />
                        <button className="update-button" onClick={() => handleUpdatePlayer('total_red_cards')}>Güncelle</button>
                    </div>

                    {/* Galibiyet Güncelle */}
                    <div className="update-field">
                        <label className="logo-label">Galibiyet</label>
                        <input
                            type="text"
                            value={updatedFields.wins !== undefined ? updatedFields.wins : 0}
                            onChange={(e) => {
                                const value = isNaN(parseInt(e.target.value, 10)) ? 0 : parseInt(e.target.value, 10);
                                setUpdatedFields({ ...updatedFields, wins: value });
                            }}
                            className="short-input"
                        />
                        <button className="update-button" onClick={() => handleUpdatePlayer('wins')}>Güncelle</button>
                    </div>

                    {/* Beraberlik Güncelle */}
                    <div className="update-field">
                        <label className="logo-label">Beraberlik</label>
                        <input
                            type="text"
                            value={updatedFields.draws !== undefined ? updatedFields.draws : 0}
                            onChange={(e) => {
                                const value = isNaN(parseInt(e.target.value, 10)) ? 0 : parseInt(e.target.value, 10);
                                setUpdatedFields({ ...updatedFields, draws: value });
                            }}
                            className="short-input"
                        />
                        <button className="update-button" onClick={() => handleUpdatePlayer('draws')}>Güncelle</button>
                    </div>

                    {/* Mağlubiyet Güncelle */}
                    <div className="update-field">
                        <label className="logo-label" >Mağlubiyet</label>
                        <input
                            type="text"
                            value={updatedFields.losses !== undefined ? updatedFields.losses : 0}
                            onChange={(e) => {
                                const value = isNaN(parseInt(e.target.value, 10)) ? 0 : parseInt(e.target.value, 10);
                                setUpdatedFields({ ...updatedFields, losses: value });
                            }}
                            className="short-input"
                        />
                        <button className="update-button" onClick={() => handleUpdatePlayer('losses')}>Güncelle</button>
                    </div>

                    <div className="update-field">
                        <label className="logo-label" >Katılım Tarihi</label>
                        <input
                            type="date"
                            value={updatedFields.attendence_date || ''}
                            onChange={(e) => setUpdatedFields({ ...updatedFields, attendence_date: e.target.value })}
                        />
                        <button  className="update-button" onClick={() => handleUpdatePlayer('attendence_date')}>Güncelle</button>
                    </div>
                            
                    <div className="update-field-switch">
                        <label className='logo-label'>Oyuncu Durumu</label>
                        <button
                            className={`switch-button ${isActive ? 'switch-active' : 'switch-inactive'}`}
                            onClick={handleToggleActiveStatus}
                        >
                            {isActive ? 'AKTİF' : 'PASİF'}
                        </button>
                    </div>
                   

                   {isButtonVisible && (
                    <button className="update-team-delete-button" onClick={() => {
                        handleDeletePlayer();
                        setIsButtonVisible(false); // Butonu gizle
                      }}
                      
                      >Oyuncuyu Sil</button>
                    )}
                </div>

                {errorMessage && <p className="error-message">{errorMessage}</p>}
            </div>
        </div>
    );
};

export default UpdatePlayer;
