import React from "react";
import image from "../assets/turkey.webp";
import "./style.css";
import { useData } from "./Cache";
import Footer from "./Footer";
import Header from "./Header";
const TurkeyMap = () => {
  const { leagues, loading, error } = useData();
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const citiesWithLeagues = leagues.reduce((acc, league) => {
    const { city, league_name } = league;

    if (!acc[city]) {
      acc[city] = [];
    }

    acc[city].push(league_name);
    return acc;
  }, {});

  return (
    <div className="map-container">
      <Header />
      {/* Türkiye Haritası */}
      <div className="map-wrapper">
        <img src={image} alt="Türkiye Haritası" className="map-image" />
        {/* Ankara */}
        <div
          className="city-marker"
          style={{ top: "33%", left: "38%" }}
          onClick={() => (window.location.href = `/ankara`)}
        >
          📍
        </div>
        {/* Antalya */}
        <div
          className="city-marker"
          style={{ bottom: "13%", left: "30%" }}
          onClick={() => (window.location.href = `/antalya`)}
        >
          📍
        </div>
            {/* İzmir */}
        <div
          className="city-marker"
          style={{ top: "55%", left: "14%" }}
          onClick={() => (window.location.href =`/izmir`)}
        >
          📍
        </div>
      </div>
      <div className="league-container">
        <div className="city-list">
          {Object.keys(citiesWithLeagues).map((city, index) => (
            <div key={index} className="city-item">
              {/* Şehir Butonu */}
              <button
                className="city-button"
                onClick={() =>
                  (window.location.href = `/${city
                    .toLowerCase("tr")
                    .replace(/\s+/g, "-")}`)
                }
              >
                {city}
              </button>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TurkeyMap;
