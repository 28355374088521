import React from "react";
import { useParams } from "react-router-dom";
import { useData } from "./Cache"; // Verileri almak için kullandığın hook
import "./style.css";
import Header from "./Header";
import image from "../assets/slayt.jpg";
const CityHome = () => {
  const { cityName } = useParams(); // URL'deki şehir ismini al
  const { leagues, loading, error } = useData(); // Tüm ligleri al

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  // Şehirdeki ligleri filtrele
  const cityLeagues = leagues.filter(
    (league) => formatTeamNameForURL(league.city) === formatTeamNameForURL(cityName)
  );

  if (cityLeagues.length === 0) {
    return <p>Bu şehirde lig bulunamadı.</p>;
  }

  return (
    <div className="city-leagues-container">
      <Header />
      <div className="leagues-grid">
        {cityLeagues.map((league) => (
          <div
            key={league.id}
            className="league-card"
            onClick={() => {
              const formattedLeagueName = formatTeamNameForURL(league.league_name);
              window.location.href = `/${formatTeamNameForURL(cityName)}/${formattedLeagueName}`;
            }}
          >
              <h2 className="league-card-title">{league.league_name}</h2>
            <img
              src={league.league_logo || image}
              alt={league.league_name}
              className="league-card-image"
            />
            
          </div>
        ))}
      </div>
    </div>
  );
};
function formatTeamNameForURL(teamName) {
  return teamName
    .toLowerCase()
    .replace(/ç/g, "c")
    .replace(/ğ/g, "g")
    .replace(/ı/g, "i")
    .replace(/ö/g, "o")
    .replace(/ş/g, "s")
    .replace(/ü/g, "u")
    .replace(/[^a-z0-9 ]/g, "")
    .trim()
    .replace(/\s+/g, "-");
}
export default CityHome;
