// pages/Add_Writings.js
import React, { useState } from 'react';
import axios from 'axios';
import { useUser } from "../UserContext"; // UserContext'i çağır


const Add_Writings = () => {

    
    const { user, setUser } = useUser();
    const userCity = user?.city;
    
    const [writingData, setWritingData] = useState({
        title: "",
        content: "",
        type: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setWritingData({
            ...writingData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!writingData.type) {
            alert("Lütfen yazının türünü seçin.");
            return;
        }

        try {
            const response = await axios.post("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/yazilar/add", writingData);
            alert("Yazı başarıyla eklendi!");
            setWritingData({ title: "", content: "", type: "" });
        } catch (err) {
            console.error("Error adding writing:", err);
            alert("Yazı eklenirken hata oluştu.");
        }
    };

    return (
        <div style={{ maxWidth: '600px', margin: '0 auto', padding: '20px' }}>
            <h1 style={{ marginLeft: '10px' }} >Yeni Yazı Ekle</h1>
            <form onSubmit={handleSubmit}>
                <div style={{ marginBottom: '20px' }}>
                    <label>Yazı Başlığı:</label>
                    <input
                        type="text"
                        name="title"
                        value={writingData.title}
                        onChange={handleChange}
                        maxLength="300"
                        required
                        style={{ width: '100%', padding: '8px', marginTop: '5px' }}
                    />
                </div>
                
                <div style={{ marginBottom: '20px' }}>
                    <label>Yazı İçeriği:</label>
                    <textarea
                        name="content"
                        value={writingData.content}
                        onChange={handleChange}
                        maxLength="20000"
                        required
                        style={{ width: '100%', height: '200px', padding: '8px', marginTop: '5px' }}
                    />
                </div>

                <div style={{ marginBottom: '20px' }}>
                    <label>Yazı Türü:</label>
                    <select
                        name="type"
                        value={writingData.type}
                        onChange={handleChange}
                        required
                        style={{ width: '100%', padding: '8px', marginTop: '5px' }}
                    >
                        <option value="">Tür Seçin</option>
                        <option value="Haber">Haber</option>
                        <option value="Duyuru">Duyuru</option>
                    </select>
                </div>
                
                <button type="submit" style={{ padding: '10px 20px', fontSize: '16px' }}>Yazıyı Kaydet</button>
            </form>
        </div>
    );
};

export default Add_Writings;
