import './App.css';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { DataProvider } from "./pages/Cache";
import { UserProvider, useUser } from './UserContext';

//FRONTEND PAGES
import Home from './pages/Home';
import CityHome from "./pages/CityHome";
import Home2 from "./pages/Home2";
import PlayerList from './pages/Player_List';
import Rules from './pages/Rules';
import PlayerListDouble from './pages/PlayerListDouble';
import News from './pages/News';
import Results from './pages/Results';
import Matches from './pages/MatchPage';
import Players from './pages/Player';
import Teams from './pages/Team';
import Login from './pages/Login';
import Skorboard from './pages/Skorboard';
import Communication from './pages/Communication';
import Transfers from './pages/Transfers';
import Penalties from './pages/Penalties';
import Rewards from './pages/Rewards';
import TeamList from './pages/TeamList';
import AllTeamList from './pages/AllTeamList';
import LeagueStandings from './pages/LeagueStandings';
import Fixture from './pages/Fixture';
import AllResults from './pages/AllResults';
import AllResultsMobile from './pages/AllResultsMobile';

//EDITOR PAGES
import EditorPlayers from './editor_pages/Editor_Players';
import EditorTeams from './editor_pages/Editor_Teams';
import EditorMatches from './editor_pages/Editor_Matches';
import EditorLeagues from './editor_pages/Editor_Leagues';
import EditorSeasons from './editor_pages/Editor_Seasons';
import Editor from './editor_pages/Editor';
import EditorMenu from './editor_pages/EditorMenu';
import UpdateTeam from './editor_pages/Update_Team';
import UpdatePlayer from './editor_pages/Update_Player';
import UpdateMatch from './editor_pages/Update_Match';
import EnterStats from './editor_pages/Enter_Stats';
import Add_Writings from './editor_pages/Add_Writings';
import AddMatch from './editor_pages/Add_Match';
import AddUser from './editor_pages/Add_User';


import React, { createContext, useState,useEffect } from 'react';
import axios from 'axios';

import ProtectedRoute from './ProtectedRoute'; // Adjust path as necessary
import api from './api/api';
axios.defaults.withCredentials = true;
export const UserContext = createContext();


function App() {
  return (
    <UserProvider>
      <BrowserRouter>
        <DataProvider>
          <AppContent />
        </DataProvider>
      </BrowserRouter>
    </UserProvider>
  );
}



function AppContent() {
  const location = useLocation();
  const { user, isLoading } = useUser(); 

  /*const [user, setUser] = useState(null); // Kullanıcı bilgisini tutar
  const [isLoading, setIsLoading] = useState(true); // Yüklenme durumunu ekleyin
  // Verify session on app load or route change
  useEffect(() => {
    const verifySession = async () => {
      try {
        

        const response = await api.get('/users/verify'); // `api` kullan

        const { user } = response.data;
        setUser(user); // Set user state

      } catch (error) {
        console.error('Oturum doğrulama hatası:', error);
        setUser(null); // Clear user if session is invalid
      }finally {
        setIsLoading(false); // Yüklenme tamamlandı
      }
    };

  verifySession();
}, [location]); // Run on location change to ensure session consistency

useEffect(() => {
  const verifySession = async () => {
    try {
      // Önce local storage'da oturum bilgisi var mı kontrol et
      const cachedUser = JSON.parse(localStorage.getItem('user'));
      if (cachedUser) {
        console.log("cachdeki user sehir ",cachedUser.city)
        setUser(cachedUser); // Cache'deki bilgiyi kullan
        setIsLoading(false);
        return; // API çağrısı yapmadan çık
      }

      // Eğer cache yoksa API'den doğrulama yap
      const response = await api.get('/users/verify');
      console.log("database e gitti ",response.data.role)
      const { user } = response.data;
      setUser(user); // Kullanıcıyı state'e ata

      // Doğrulanan kullanıcıyı local storage'a kaydet
      localStorage.setItem('user', JSON.stringify(user));
    } catch (error) {
      console.error('Oturum doğrulama hatası:', error);
      setUser(null); // Geçersiz kullanıcı
    } finally {
      setIsLoading(false); // Yüklenme tamamlandı
    }
  };

  verifySession();
}, []); */


  // Menü barının gösterileceği sayfalar
  const editorPages = [
    '/editor',
    '/editor/oyuncular',
    '/editor/takimlar',
    '/editor/maclar',
    '/editor/ligler',
    '/editor/sezonlar',
    '/editor/mac-ekle',
    '/editor/yazi-ekle',
    '/editor/takim-guncelle',
    '/editor/oyuncu-guncelle',
    '/editor/kullanici-ekle'
  ];
  const dynamicPaths = ['/editor/mac-guncelle/','/editor/ist-gir/'];
  const showEditorMenu = editorPages.includes(location.pathname) ||
  dynamicPaths.some(path => location.pathname.startsWith(path));

  return (
    <div className="app-container">
      {showEditorMenu && <EditorMenu />}
      <div className="content">

      {isLoading ? ( // Yükleniyor durumunu burada kontrol edin
          <div>Loading...</div>
        ) : (
        <Routes>
          {/* FRONTEND SAYFALARI */}
          <Route path="/:cityName/:leagueName" element={<Home />} />
          <Route path="/:cityName" element={<CityHome />} />
          <Route path="/" element={<Home2 />} />
          <Route path="/oyuncu-listesi" element={<PlayerList />} />
          <Route path="/kurallar" element={<Rules />} />
          <Route path="/haberler" element={<News />} />
          <Route path='/:cityName/:leagueName/puan-durumu' element={<LeagueStandings/>} />
          <Route path='/skorboard' element={<Skorboard/>} />
          <Route path='/takim-listesi' element={<AllTeamList/>} />
          <Route path="/:cityName/:leagueName/fikstur" element={<Fixture />} />
          <Route path="/:cityName/:leagueName/sonuclar" element={<AllResults />} />
          <Route path="/:cityName/:leagueName/sonuclar-mobil" element={<AllResultsMobile />} />

          <Route path='/kurallar' element={<Rules/>} />
          <Route path='/iletisim' element={<Communication/>} /> {/* not completed */}
          <Route path='/:cityName/:leagueName/transferler' element={<Transfers/>} /> {/* not completed */}
          <Route path='/:cityName/:leagueName/cezalar' element={<Penalties/>} /> {/* not completed */}

          <Route path='/:cityName/:leagueName/en-degerliler-gol-kralligi' element={<PlayerListDouble/>} />
          <Route path='/haberler-ve-duyurular' element={<News/>} /> {/* not completed */}
          <Route path='/:cityName/:leagueName/odul-kosesi' element={<Rewards/>} /> {/* not completed */}
          <Route path='/:leagueName/sonuclar' element={<Results/>} />
          <Route path='/:leagueName/puan-durumu' element={<TeamList/>} />
          <Route path="/:cityName/:leagueName/takimlar/:teamName" element={<Teams />} />
          <Route path="/:cityName/:leagueName/maclar/:matchID" element={<Matches />} />
          <Route path="/:cityName/:leagueName/oyuncular/:playerID" element={<Players />} />

          <Route path="/giris" element={<Login />} />

          {/* Editor Sayfaları */}
          {/* Protected Routes */}
        
          <Route
            path="/editor"
            element={
              <ProtectedRoute user={user} roleRequired={['editor', 'admin']} >
                <Editor />
              </ProtectedRoute>
            }
          />

          <Route
            path="/editor/oyuncular"
            element={
              <ProtectedRoute user={user} roleRequired={['editor', 'admin']}>
                <EditorPlayers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/editor/takimlar"
            element={
              <ProtectedRoute user={user} roleRequired={['editor', 'admin']}>
                <EditorTeams />
              </ProtectedRoute>
            }
          />
          <Route
            path="/editor/maclar"
            element={
              <ProtectedRoute user={user} roleRequired={['editor', 'admin']}>
                <EditorMatches />
              </ProtectedRoute>
            }
          />
          <Route
            path="/editor/ligler"
            element={
              <ProtectedRoute user={user} roleRequired={['editor', 'admin']}>
                <EditorLeagues />
              </ProtectedRoute>
            }
          />
          <Route
            path="/editor/sezonlar"
            element={
              <ProtectedRoute user={user} roleRequired={['editor', 'admin']}>
                <EditorSeasons />
              </ProtectedRoute>
            }
          />
          <Route
            path="/editor/mac-ekle"
            element={
              <ProtectedRoute user={user} roleRequired={['editor', 'admin']}>
                <AddMatch />
              </ProtectedRoute>
            }
          />
          <Route
            path="/editor/mac-guncelle/:matchId"
            element={
              <ProtectedRoute user={user} roleRequired={['editor', 'admin']}>
                <UpdateMatch />
              </ProtectedRoute>
            }
          />
          <Route
            path="/editor/ist-gir/:matchId"
            element={
              <ProtectedRoute user={user} roleRequired={['editor', 'admin']}>
                <EnterStats />
              </ProtectedRoute>
            }
          />
          <Route
            path="/editor/takim-guncelle"
            element={
              <ProtectedRoute user={user} roleRequired={['editor', 'admin']}>
                <UpdateTeam />
              </ProtectedRoute>
            }
          />
          <Route
            path="/editor/oyuncu-guncelle/:matchId"
            element={
              <ProtectedRoute user={user} roleRequired={['editor', 'admin']}>
                <UpdatePlayer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/editor/yazi-ekle"
            element={
              <ProtectedRoute user={user} roleRequired={['admin','editor']}>
                <Add_Writings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/editor/kullanici-ekle"
            element={
              <ProtectedRoute user={user} roleRequired={['admin']}>
                <AddUser />
              </ProtectedRoute>
            }
          />

        </Routes>
        )}
      </div>
    </div>
  );
}

export default App;
