import { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import api from "./api/api";


const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const verifySession = async () => {
            try {
                const response = await api.get("/users/verify");
                setUser(response.data.user);
                console.log("api cagrisi user icin")
            } catch (error) {
                console.error("Oturum doğrulama hatası:", error);
                setUser(null);
            } finally {
                setIsLoading(false);
            }
        };

        if(!user){
            verifySession();
        }
    }, []);

    return (
        <UserContext.Provider value={{ user, setUser, isLoading }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
export default UserContext;
