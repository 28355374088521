import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Editor_Leagues.css';
import { useUser } from "../UserContext"; // UserContext'i çağır

const Editor_Leagues = () => {
    const [leagues, setLeagues] = useState([]);
    const [newLeague, setNewLeague] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [editLeagueId, setEditLeagueId] = useState(null);
    const [editLeagueName, setEditLeagueName] = useState("");
    const { user, setUser } = useUser();
    const userCity = user?.city;
    /*useEffect(() => {
        fetchLeagues();
    }, []);

    const fetchLeagues = async () => {
        try {
            const response = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/ligler/by-city/${userCity}`);
            setLeagues(response.data);
        } catch (error) {
            console.error('Error fetching leagues:', error);
        }
    };*/
    // Mevcut ligleri getir
    useEffect(() => {
        const fetchLeagues = async () => {
            try {
                const response = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/ligler/by-city/${userCity}`);               
                setLeagues(response.data);
            } catch (error) {
                console.error('Error fetching leagues:', error);
            }
        };
        fetchLeagues();
    }, []);

    // Yeni lig ekleme fonksiyonu
    const handleAddLeague = async () => {
        if (newLeague.trim() === '') {
            setErrorMessage('Lig adı boş olamaz.');
            return;
        }

        try {
            await axios.post("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/ligler", { league_name: newLeague, city: userCity });
            setNewLeague('');
            setErrorMessage('');
            // Yeni ligleri yeniden çek
            const response = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/ligler/by-city/${userCity}`);
            setLeagues(response.data);
        } catch (error) {
            if (error.response?.status === 400) {
                setErrorMessage('Bu isimde bir lig zaten var.');
            } else {
                console.error('Error adding league:', error);
            }
        }
    };

    // Lig silme fonksiyonu
    const handleDeleteLeague = async (leagueId) => {
        const confirmDelete = window.confirm("Ligi silmek istediğinize emin misiniz?");
        if (!confirmDelete) return;

        try {
            await axios.delete(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/ligler/${leagueId}`);
            setLeagues(leagues.filter((league) => league.id !== leagueId));
        } catch (error) {
            console.error('Error deleting league:', error);
            setErrorMessage("Lig silinirken bir hata oluştu.");
        }
    };

    const handleUpdateLeague = async (leagueId) => {
        if (editLeagueName.trim() === "") {
            setErrorMessage("Lig adı boş olamaz.");
            return;
        }

        try {
            await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/ligler/update-league/${leagueId}`, {
                newLeagueName: editLeagueName,
            });

            // Ligleri tekrar yükle
            const response = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/ligler/by-city/${userCity}`);
            setLeagues(response.data);
            setEditLeagueId(null);
            setEditLeagueName("");
            setErrorMessage("");
        } catch (error) {
            console.error("Error updating league:", error);
            setErrorMessage("Lig güncellenirken bir hata oluştu.");
        }
    };

    const handleLogoUpload = async (event, leagueId) => {
        const file = event.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append("logo", file);

        try {
            const response = await axios.patch(
                `https://elit-lig-back-end-bc5730e96b98.herokuapp.com/ligler/updateLogo/${leagueId}`,
                formData,
                { headers: { "Content-Type": "multipart/form-data" } }
            );

            //fetchLeagues(); // Güncellenmiş logoyu tekrar al
            alert(response.data.message);
        } catch (error) {
            console.error("Error updating league logo:", error);
            alert("Logo yüklenirken hata oluştu!");
        }
    };

    return (
        <div className="leagues-container">
            <div className="existing-leagues">
                <h2 className="section-title">Mevcut Ligler</h2>
                <div className="leagues-list">
                    {leagues.map((league) => (
                        <div key={league.id} className="league-item">
                            {editLeagueId === league.id ? (
                                <>
                                    <input
                                        type="text"
                                        value={editLeagueName}
                                        onChange={(e) => setEditLeagueName(e.target.value)}
                                        className="league-input"
                                    />

                            {/* Lig logosunu göster veya varsayılan bir logo ekle */}
                            {/*<img 
                                src={league.league_logo || "https://via.placeholder.com/50"} 
                                alt="Lig Logosu"
                                className="league-logo"
                            />*/}

                            {/* Logo Yükleme Butonu */}
                            <label className="update-button">
                                {league.league_logo ? "Logoyu Güncelle" : "Logo Ekle"}
                                <input type="file" accept="image/*" onChange={(e) => handleLogoUpload(e, league.id)} hidden />
                            </label>
                                    
                                    <button className="update-button" onClick={() => handleUpdateLeague(league.id)}>
                                        Güncelle
                                    </button>
                                    <button className="delete-button" onClick={() => setEditLeagueId(null)}>
                                        X
                                    </button>
                                </>
                            ) : (
                                <>
                                    <span>{league.league_name}</span>
                                    <button className="update-button" onClick={() => {
                                        setEditLeagueId(league.id);
                                        setEditLeagueName(league.league_name);
                                    }}>
                                        Düzenle
                                    </button>
                                    <button className="delete-button" onClick={() => handleDeleteLeague(league.id)}>
                                        Sil
                                    </button>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            <div className="add-league-section">
                <h2 className="section-title">Yeni Lig Ekle</h2>
                <input
                    type="text"
                    value={newLeague}
                    onChange={(e) => setNewLeague(e.target.value)}
                    placeholder="Lig adı girin..."
                    className="league-input"
                />
                <button onClick={handleAddLeague} className="add-button">
                    Oluştur
                </button>

                {errorMessage && <p className="error-message">{errorMessage}</p>}
            </div>
        </div>
    );
};

export default Editor_Leagues;
