import Header from './Header';
import Footer from './Footer';
import './style.css';

const Communication = () => {
    return (
        <div>
            <Header />
            <header class="header-banner">
                <h1 class="banner-title">İletişim</h1>
                <div class="zigzag">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path fill="#4facfe" fill-opacity="1" d="M0,256L48,224C96,192,192,128,288,96C384,64,480,64,576,96C672,128,768,192,864,213.3C960,235,1056,213,1152,213.3C1248,213,1344,235,1392,245.3L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
                    </svg>
                </div>
            </header>
            <Footer />
        </div>
    );
};
export default Communication;
