import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./style.css";
import field from "../assets/field.png";
import { useData } from "./Cache";





const AllResults = () => {
  const { cityName, leagueName } = useParams();
  const { matches, seasons, teams, loading, error } = useData();

  const [selectedSeason, setSelectedSeason] = useState(null);
  const [filteredMatches, setFilteredMatches] = useState([]);

  useEffect(() => {
    if (leagueName && selectedSeason) {
      const filtered = matches
        .filter(
          (match) =>
            formatTeamNameForURL(match.league_name) === leagueName &&
            match.match_season === selectedSeason &&
            match.is_it_fixture === false &&
            formatTeamNameForURL(match.city) === formatTeamNameForURL(cityName)
        )
        .map((match) => {
          const firstTeam = teams.find(
            (team) => team.team_name === match.first_team_name
          );
          const secondTeam = teams.find(
            (team) => team.team_name === match.second_team_name
          );

          return {
            ...match,
            first_team_logo: firstTeam ? firstTeam.logo : null,
            second_team_logo: secondTeam ? secondTeam.logo : null,
          };
        });

      setFilteredMatches(filtered);
    } else {
      setFilteredMatches([]); // Seçim olmadığında boş liste
    }
  }, [leagueName, selectedSeason, matches, teams]);

  const handleSeasonChange = (season) => {
    setSelectedSeason(season);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>

      <div className="centered-container-2">
        <select onChange={(e) => handleSeasonChange(e.target.value)}>
          <option value="">Sezon Seç</option>
          {seasons
            .filter(
              (season) =>
                 formatTeamNameForURL(season.league_name) === leagueName
            )
            .map((season) => (
              <option key={season.id} value={season.season_name}>
                {season.season_name}
              </option>
            ))}
        </select>
      </div>





      {selectedSeason && (
        <div>
          <MatchTable
            matches={filteredMatches.sort(
              (a, b) => new Date(b.date) - new Date(a.date)
            )}
            cityName={cityName}
            leagueName={leagueName}
          />
        </div>
      )}

    </div>
  );
};

const MatchTable = ({ matches, cityName, leagueName }) => {
  const groupMatchesByDate = (matches) => {
    return matches.reduce((acc, match) => {
      const matchDate = formatDate(match.date);
      if (!acc[matchDate]) {
        acc[matchDate] = [];
      }
      acc[matchDate].push(match);
      return acc;
    }, {});
  };

  const formatDate = (isoDate) => {
    if (!isoDate) return "";
    const months = [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ];
    const date = new Date(isoDate);
    return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
  };

  const formatTime = (timeString) => timeString.slice(0, 5);

  const groupedMatches = groupMatchesByDate(matches);

  return (
    <div className="fixture-container">
      {Object.keys(groupedMatches).map((date) => (
        <React.Fragment key={date}>
          <h2 className="fixture-date">{date}</h2>
          {groupedMatches[date]
            .sort(
              (a, b) =>
                new Date(`1970-01-01T${a.time}`) -
                new Date(`1970-01-01T${b.time}`)
            )
            .map((match) => (
              <div className="fixture-row" key={match.id}>
                <div className="team">
                  <span>{match.first_team_name}</span>
                  <img
                    src={match.first_team_logo}
                    alt={match.first_team_name}
                    className="team-logo"
                  />
                </div>

                <div className="time">
                  {match.first_team_score}-{match.second_team_score}
                </div>

                <div className="team">
                  <img
                    src={match.second_team_logo}
                    alt={match.second_team_name}
                    className="team-logo"
                  />
                  <span>{match.second_team_name}</span>
                </div>

                <div className="stadium">
                  <img
                    src={field}
                    alt={match.match_field}
                    className="team-logo"
                  />
                  <span>
                    <a
                      href={`https://www.google.com/maps?q=${encodeURIComponent(
                        match.match_field
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="stadium-link"
                    >
                      {match.match_field}
                    </a>
                  </span>
                </div>

                <div className="actions">
                  <button
                    className="quick-view"
                    onClick={() =>
                      (window.location.href = `${formatTeamNameForURL(cityName)}/${formatTeamNameForURL(leagueName)}/maclar/${match.id}`)
                    }
                  >
                    Maçı Görüntüle
                  </button>
                </div>
              </div>
            ))}
        </React.Fragment>
      ))}
    </div>
  );
};
function formatTeamNameForURL(teamName) {
  return teamName
    .toLowerCase()
    .replace(/ç/g, "c")
    .replace(/ğ/g, "g")
    .replace(/ı/g, "i")
    .replace(/ö/g, "o")
    .replace(/ş/g, "s")
    .replace(/ü/g, "u")
    .replace(/[^a-z0-9 ]/g, "")
    .trim()
    .replace(/\s+/g, "-");
}
export default AllResults;
