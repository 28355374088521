import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // URL'den şehir ve lig ismini almak için
import Header from "./Header";
import Footer from "./Footer";
import "./style.css";
import field from "../assets/field.png";
import { useData } from "./Cache";

const Fixture = () => {
  const { cityName, leagueName } = useParams(); // URL'den şehir ve lig bilgisi al
  const { matches, seasons, teams, loading, error } = useData();

  const [selectedSeason, setSelectedSeason] = useState(null);
  const [filteredMatches, setFilteredMatches] = useState([]);

  useEffect(() => {
    if (leagueName && selectedSeason) {
      const filtered = matches.filter(
        (match) =>
          match.is_it_fixture === true &&
          formatTeamNameForURL(match.league_name) === formatTeamNameForURL(leagueName) &&
          match.match_season === selectedSeason &&
          formatTeamNameForURL(match.city) === formatTeamNameForURL(cityName)
      );
      setFilteredMatches(filtered);
    } else {
      setFilteredMatches([]); // Seçim olmadığında boş liste
    }
  }, [leagueName, selectedSeason, matches]);

  const handleSeasonChange = (seasonId) => {
    setSelectedSeason(seasonId);
  };

  return (
    <div>
      <Header />
      <header class="header-banner">
        <h1 class="banner-title">Fikstür</h1>
        <div class="zigzag">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#4facfe"
              fill-opacity="1"
              d="M0,256L48,224C96,192,192,128,288,96C384,64,480,64,576,96C672,128,768,192,864,213.3C960,235,1056,213,1152,213.3C1248,213,1344,235,1392,245.3L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
            ></path>
          </svg>
        </div>
      </header>

      <div className="centered-container-2">
        <select onChange={(e) => handleSeasonChange(e.target.value)}>
          <option value="">Sezon Seç</option>
          {seasons
            .filter(
              (season) =>
                formatTeamNameForURL(season.league_name) === leagueName
            )
            .map((season) => (
              <option key={season.id} value={season.season_name}>
                {season.season_name}
              </option>
            ))}
        </select>
      </div>

      {selectedSeason && (
        <div>
          <MatchTable matches={filteredMatches} teams={teams}/>
          <br />
          <br />
          <br />
        </div>
      )}

      <Footer />
    </div>
  );
};

const MatchTable = ({ matches, teams }) => {
  const groupMatchesByDate = (matches) => {
    const grouped = matches.reduce((acc, match) => {
      const matchDate = formatDate(match.date);
      if (!acc[matchDate]) {
        acc[matchDate] = [];
      }
      acc[matchDate].push(match);
      return acc;
    }, {});

    const sortedDates = Object.keys(grouped)
      .sort((a, b) => new Date(a) - new Date(b))
      .reverse();

    const sortedGroupedMatches = {};
    sortedDates.forEach((date) => {
      sortedGroupedMatches[date] = grouped[date];
    });

    return sortedGroupedMatches;
  };

  const formatDate = (isoDate) => {
    if (!isoDate) return "";
    const months = [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ];
    const date = new Date(isoDate);
    return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
  };

  const formatTime = (timeString) => timeString.slice(0, 5);

  const groupedMatches = groupMatchesByDate(matches);

  return (
    <div className="fixture-container">
      {Object.keys(groupedMatches).map((date) => (
        <React.Fragment key={date}>
          <h2 className="fixture-date">{date}</h2>
          {groupedMatches[date]
            .sort(
              (a, b) =>
                new Date(`1970-01-01T${a.time}`) -
                new Date(`1970-01-01T${b.time}`)
            )
            .map((match) => (
              <div className="fixture-row" key={match.id}>
                <div className="team">
                  <span>{match.first_team_name}</span>
                  <img
                    src={(teams.find((team) => team.team_name === match.first_team_name) || {}).logo}
                    alt={match.first_team_name}
                    className="team-logo"
                  />
                </div>

                <div className="time">{formatTime(match.time)}</div>

                <div className="team">
                  <img
                    src={(teams.find((team) => team.team_name === match.second_team_name) || {}).logo}
                    alt={match.second_team_name}
                    className="team-logo"
                  />
                  <span>{match.second_team_name}</span>
                </div>

                <div className="stadium">
                  <img
                    src={field}
                    alt={match.match_field}
                    className="team-logo"
                  />
                  <span>
                    <a
                      href={`https://www.google.com/maps?q=${encodeURIComponent(
                        match.match_field
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="stadium-link"
                    >
                      {match.match_field}
                    </a>
                  </span>
                </div>
                <div className="actions">
                  <button className="quick-view">Quick View</button>
                </div>
              </div>
            ))}
        </React.Fragment>
      ))}
    </div>
  );
};
function formatTeamNameForURL(teamName) {
  return teamName
    .toLowerCase()
    .replace(/ç/g, "c")
    .replace(/ğ/g, "g")
    .replace(/ı/g, "i")
    .replace(/ö/g, "o")
    .replace(/ş/g, "s")
    .replace(/ü/g, "u")
    .replace(/[^a-z0-9 ]/g, "")
    .trim()
    .replace(/\s+/g, "-");
}
export default Fixture;
