import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './Update_Match.css';
import { useUser } from "../UserContext"; // UserContext'i çağır

const Update_Match = () => {
  const { matchId } = useParams(); // URL'den maç ID'sini al
  const [selectedMatch, setSelectedMatch] = useState(null); // Seçilen maç verisi
  const [selectedFile, setSelectedFile] = useState(null); // Resim dosyası
  const [firstTeamPlayers, setFirstTeamPlayers] = useState([]);
  const [secondTeamPlayers, setSecondTeamPlayers] = useState([]);
  const [playerStatistics, setPlayerStatistics] = useState({});
  const [playerStatisticsOld, setPlayerStatisticsOld] = useState({});
  const [match_data, setMatchData] = useState(null);
  const [leagues, setLeagues] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const baseUrl = "https://elit-lig-back-end-bc5730e96b98.herokuapp.com/";
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const { user, setUser } = useUser();
  const userCity = user?.city;

  // Maç bilgilerini fetch et
  useEffect(() => {
    const fetchMatchData = async () => {
      try {
        const leaguesResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/ligler/by-city/${userCity}`);
        const seasonsResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/sezonlar/by-city/${userCity}`);

        setLeagues(leaguesResponse.data);
        setSeasons(seasonsResponse.data);

        const matchResponse = await axios.get(
          `https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar/${matchId}`
        );
        const matchData = matchResponse.data;
        setSelectedMatch(matchData);
        setMatchData(matchData);

        // Takım ID'lerini almak için Teams tablosunu sorgula
        const teamsResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/by-city/${userCity}`);
        const teams = teamsResponse.data;

        // Takım isimlerinden ID'leri eşle
        const firstTeam = teams.find(
          (team) => team.team_name === matchData.first_team_name
        );
        const secondTeam = teams.find(
          (team) => team.team_name === matchData.second_team_name
        );

        if (!firstTeam || !secondTeam) {
          console.error("Takım eşleşmesi bulunamadı. Lütfen takım adlarını kontrol edin.");
          return;
        }

        const updatedMatchData = {
          ...matchData,
          first_team_id: firstTeam.id,
          second_team_id: secondTeam.id,
        };
        setSelectedMatch(updatedMatchData);

        // Oyuncuları `players` tablosundan çek
        const playersResponse = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular");
        const players = playersResponse.data;

        // Takım oyuncularını fetch et
        const [firstTeamResponse, secondTeamResponse] = await Promise.all([
          axios.get(
            `https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-istatistikleri/${matchId}/team/${updatedMatchData.first_team_id}`
          ),
          axios.get(
            `https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-istatistikleri/${matchId}/team/${updatedMatchData.second_team_id}`
          ),
        ]);

        // `player_id`'leri `player_name` ile eşleştir ve `player_name` ekle
        const enrichedFirstTeamPlayers = firstTeamResponse.data.map(player => {
          const playerData = players.find(p => p.id === player.player_id);
          return {
            ...player,
            player_name: playerData?.player_name || "Bilinmiyor",
          };
        });
  
        const enrichedSecondTeamPlayers = secondTeamResponse.data.map(player => {
          const playerData = players.find(p => p.id === player.player_id);
          return {
            ...player,
            player_name: playerData?.player_name || "Bilinmiyor",
          };
        });

        setFirstTeamPlayers(enrichedFirstTeamPlayers);
        setSecondTeamPlayers(enrichedSecondTeamPlayers);

       console.log("enriched first ",enrichedFirstTeamPlayers);
        console.log("enriched second ",enrichedSecondTeamPlayers);
        

        // Oyuncu istatistiklerini organize et
        const allStats = {};
        [...firstTeamResponse.data, ...secondTeamResponse.data].forEach(
          (player) => {
            allStats[player.player_id] = player;
          }
        );
        setPlayerStatistics(allStats);
        setPlayerStatisticsOld(allStats);
      } catch (error) {
        console.error("Error fetching match or players:", error);
      }
    };

    fetchMatchData();
  }, [matchId]);

  // Alan değişikliklerini handle et
  const handleFieldChange = (field, value) => {
    setSelectedMatch((prev) => ({
      ...prev,
      [field]: value
    }));
  };

  // Oyuncu istatistikleri güncelleme
  const handlePlayerStatsChange = (playerId, field, value) => {

      if (value < 0) {
        alert("Negatif değer giremezsiniz.");
        return;
    }
    setIsButtonVisible(true);
    
    setPlayerStatistics((prev) => ({
      ...prev,
      [playerId]: {
        ...prev[playerId],
        [field]: value,
      },
    }));
  };

  // Update match information in the database
  const handleUpdateField = async (field) => {
    try {
      await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar/${selectedMatch.id}`, {
        [field]: selectedMatch[field]
      });
      alert(`${field} updated successfully!`);
    } catch (error) {
      console.error("Error updating match information:", error);
      alert('Failed to update match information.');
    }
  };

  // Resim dosyasını handle et
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  // Resim dosyasını yükle ve güncelle
  const handleFileUpload = async () => {
    if (!selectedFile) {
      alert("Lütfen bir dosya seçin!");
      return;
    }
    const formData = new FormData();
    formData.append("match_picture", selectedFile);
    try {
      // Resim dosyasını yükleyerek maç bilgilerini güncelle
      const response = await axios.patch(
        `https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar/${matchId}/upload-picture`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      const filePath = response.data.filePath; // Yüklenen dosyanın sunucudaki yolu

      // Ekranı güncelle
      
      alert("Resim başarıyla yüklendi ve güncellendi!");
    } catch (error) {
      console.error("Resim yükleme veya güncelleme sırasında hata oluştu:", error);
      alert("Bir hata oluştu. Lütfen tekrar deneyin.");
    }
  };
  // Delete the selected match
  const handleDeleteMatch = async () => {
    if (window.confirm("Maçı silmek istediğinize emin misiniz?")) {
      try {
        // Maçın berabere olup olmadığını belirle
        const isDraw =
          selectedMatch.first_team_score === selectedMatch.second_team_score;

        const winnerTeamName = !isDraw
          ? selectedMatch.first_team_score > selectedMatch.second_team_score
            ? selectedMatch.first_team_name
            : selectedMatch.second_team_name
          : null;

        const loserTeamName = !isDraw
          ? selectedMatch.first_team_score < selectedMatch.second_team_score
            ? selectedMatch.first_team_name
            : selectedMatch.second_team_name
          : null;

        // İstatistikleri güncelleme
        if (isDraw) {
          // Berabere durumunda
          await deleteResultChange(
            selectedMatch.first_team_name,
            0,
            0,
            -1,
            true
          );
          await deleteResultChange(
            selectedMatch.second_team_name,
            0,
            0,
            -1,
            false
          );
        } else {
          // Kazanan ve kaybeden durumunda
          if (winnerTeamName === selectedMatch.first_team_name) {
            await deleteResultChange(winnerTeamName, -1, 0, 0, true);
          } else {
            await deleteResultChange(winnerTeamName, -1, 0, 0, false);
          }

          if (loserTeamName === selectedMatch.first_team_name) {
            await deleteResultChange(loserTeamName, 0, -1, 0, true);

          } else {
            await deleteResultChange(loserTeamName, 0, -1, 0, false);
          }


        }

        // PlayerStatistics tablosundan match_id'ye ait kayıtları sil
        await axios.delete(
          `https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-istatistikleri/match/${selectedMatch.id}`
        );

        // Matches tablosundan maçı sil
        await axios.delete(
          `https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar/${selectedMatch.id}`
        );

        alert("Match deleted successfully!");
        setSelectedMatch(null);
      } catch (error) {
        console.error("Error deleting match:", error);
        alert("Failed to delete match.");
      }
    }
  };


  const deleteResultChange = async (teamName, win, lost, draw, isFirstTeam) => {
    try {
      // Takım istatistiklerini güncelle
      const teamResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/team_name/${teamName}`);
      const team = teamResponse.data;

      // Takım bilgilerini güncelle
      const matchData = selectedMatch; // Maç bilgilerini kullan
      if (isFirstTeam)
        console.log()
      await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/${team.id}`, {
        team_wins: team.team_wins + win,
        team_losses: team.team_losses + lost,
        team_draws: team.team_draws + draw,
        total_matches: team.total_matches - 1,
        goals_scored: isFirstTeam
          ? team.goals_scored - matchData.first_team_score
          : team.goals_scored - matchData.second_team_score,
        goals_conceded: isFirstTeam
          ? team.goals_conceded - matchData.second_team_score
          : team.goals_conceded - matchData.first_team_score,
      });
      // Takımın oyuncularını belirle
      const players = isFirstTeam ? firstTeamPlayers : secondTeamPlayers;

      // Her oyuncu için güncelleme işlemleri
      for (const player of players) {
        // PlayerStatistic tablosundan oyuncu ve maç bilgilerini al
        const playerStatResponse = await axios.get(
          `https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-istatistikleri/player/${player.player_id}/match/${selectedMatch.id}`
        );
        const playerStat = playerStatResponse.data;

        // İlgili oyuncunun maç istatistiklerini al
        const matchSk = parseInt(playerStat.yellow_card);
        const matchKk = parseInt(playerStat.red_card);
        const matchPoints = parseInt(playerStat.points);
        const matchGoals = parseInt(playerStat.number_of_goals);

        // Oyuncunun genel istatistiklerini güncelle
        const playerResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/${player.player_id}`);
        const playerData = playerResponse.data[0];
        
        let isThereYellowCard = 0;
        let isThereRedCard = 0;
        if(matchSk !=0){
          isThereYellowCard=1;
        }
        if(matchKk != 0){
          isThereRedCard = 1;
        }
        

        await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/${player.player_id}`, {
          total_matches: playerData.total_matches - 1,
          wins: playerData.wins + win,
          losses: playerData.losses + lost,
          draws: playerData.draws + draw,
          total_goals: playerData.total_goals - matchGoals,
          total_yellow_cards: playerData.total_yellow_cards -isThereYellowCard,
          total_red_cards: playerData.total_red_cards -  isThereRedCard,
          total_points: playerData.total_points - matchPoints,
        });
      }
    } catch (error) {
      console.error("Error updating result change in delete:", error);
    }


  }

  // Yeni fonksiyon: Takım ve oyuncu istatistiklerini güncelleme
 /* const updateResultChange = async (teamName, win, lost, draw) => {
    try {

      // Takım istatistiklerini güncelle
      const teamResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/team_name/${teamName}`);
      const team = teamResponse.data;

      await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/${team.id}`, {
        team_wins: team.team_wins + win,
        team_losses: team.team_losses + lost,
        team_draws: team.team_draws + draw,
      });

      // Oyuncu istatistiklerini güncelle
      const players = teamName === selectedMatch.first_team_name ? firstTeamPlayers : secondTeamPlayers;
      for (const player of players) {
        const playerResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/${player.player_id}`);
        const playerData = playerResponse.data[0];

        await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/${player.player_id}`, {
          wins: playerData.wins + win,
          losses: playerData.losses + lost,
          draws: playerData.draws + draw,
        });
      }
    } catch (error) {
      console.error("Error updating result change:", error);
    }
  };

  // Yeni fonksiyon: Sonuç değişikliğini başlatma
  const initUpdateResultChange = (isDraw, isNewDraw, newWinnerName, winnerTeamName, loserTeamName, newLoserName) => {
    if (!isDraw && !isNewDraw && newWinnerName !== winnerTeamName) {
      updateResultChange(winnerTeamName, -1, 1, 0);
      updateResultChange(loserTeamName, 1, -1, 0);
    } else if (!isDraw && isNewDraw) {
      updateResultChange(winnerTeamName, -1, 0, 1);
      updateResultChange(loserTeamName, 0, -1, 1);
    } else if (isDraw && !isNewDraw) {
      updateResultChange(newWinnerName, 1, 0, -1);
      updateResultChange(newLoserName, 0, 1, -1);
    }
  };

  const handleUpdatePlayerStats = async (playerId) => {
    try {

      // Mevcut skorları kontrol et ve kazanan/kaybedeni belirle
      const { first_team_score: oldFirstScore, second_team_score: oldSecondScore } = selectedMatch;

      let winnerTeamName = null;
      let loserTeamName = null;
      let isDraw = false;

      if (oldFirstScore > oldSecondScore) {
        winnerTeamName = selectedMatch.first_team_name;
        loserTeamName = selectedMatch.second_team_name;
      } else if (oldFirstScore < oldSecondScore) {
        winnerTeamName = selectedMatch.second_team_name;
        loserTeamName = selectedMatch.first_team_name;
      } else {
        isDraw = true;
      }

      // Eski istatistikleri al
      const newStats = playerStatistics[playerId]; //guncellenmis stats
      const oldStats = playerStatisticsOld[playerId];

       // NaN kontrolü ve 0'a eşitleme
      if (isNaN(newStats.yellow_card)) {
        newStats.yellow_card = 0;
      }
      if (isNaN(newStats.red_card)) {
        newStats.red_card = 0;
      }
      if (isNaN(newStats.number_of_goals)) {
        newStats.number_of_goals = 0;
      }
      if (isNaN(newStats.points)) {
        newStats.points = 0;
      }

      // Farkları hesapla
      const golFarki = parseInt(newStats.number_of_goals) - parseInt(oldStats.number_of_goals);
      
      let skFarki=0;
      let kkFarki=0;

      if(newStats.yellow_card ==0 && oldStats.yellow_card!=0){
        skFarki =-1;
      }else if(newStats.yellow_card != 0 && oldStats.yellow_card ==0){
        skFarki =1;
      }

      if(newStats.red_card ==0 && oldStats.red_card!=0){
        kkFarki =-1;
      }else if(newStats.red_card != 0 && oldStats.red_card ==0){
        kkFarki =1;
      }
      
      const puanFarki = parseInt(newStats.points) - parseInt(oldStats.points);

      // Takım ve rakip takım isimlerini belirle
      const isPlayerInFirstTeam = firstTeamPlayers.some((player) => player.player_id === playerId);
      const teamName = isPlayerInFirstTeam ? selectedMatch.first_team_name : selectedMatch.second_team_name;
      const oppTeamName = isPlayerInFirstTeam ? selectedMatch.second_team_name : selectedMatch.first_team_name;

      // Matches tablosunu güncelle
      const scoreField = isPlayerInFirstTeam ? "first_team_score" : "second_team_score";
      await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar/${matchId}`, {
        [scoreField]: selectedMatch[scoreField] + golFarki,
      });


      // Yeni skorları kontrol et ve kazanan/kaybedeni belirle
      const matchResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar/${matchId}`);
      const updatedMatch = matchResponse.data;

      let newWinnerName = null;
      let newLoserName = null;
      let isNewDraw = false;

      if (updatedMatch.first_team_score > updatedMatch.second_team_score) {
        newWinnerName = updatedMatch.first_team_name;
        newLoserName = updatedMatch.second_team_name;
      } else if (updatedMatch.first_team_score < updatedMatch.second_team_score) {
        newWinnerName = updatedMatch.second_team_name;
        newLoserName = updatedMatch.first_team_name;
      } else {
        isNewDraw = true;
      }

      // Teams tablosunu güncelle
      try {
        // Takımı `teamName` yerine `team_id` kullanarak güncelle
        const teamResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/team_name/${teamName}`);

        const oppTeamResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/team_name/${oppTeamName}`);

        const team = teamResponse.data; // İlk takımın bilgileri
        const oppTeam = oppTeamResponse.data; // Rakip takımın bilgileri

        // Gol sayısını güncelle

        await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/${team.id}`, {
          goals_scored: team.goals_scored + golFarki,
        });

        await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/${oppTeam.id}`, {
          goals_conceded: oppTeam.goals_conceded + golFarki,
        });

      } catch (error) {
        console.error("Teams tablosunu güncellerken hata oluştu:", error.message);
      }


      // PlayerStatistics tablosunu güncelle
      await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-istatistikleri/playerStat/${parseInt(oldStats.id)}`, {
        yellow_card: parseInt(newStats.yellow_card) ,
        red_card: parseInt(newStats.red_card),
        points: parseInt(oldStats.points) + puanFarki,
        goals_minutes: newStats.goals_minutes,
        player_position: newStats.player_position,
        number_of_goals: parseInt(oldStats.number_of_goals) + golFarki, // number_of_goals güncelleniyor
      });

      // Players tablosunu güncelle
      const playerResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/${playerId}`);
      await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/${playerId}`, {
        total_yellow_cards: parseInt(playerResponse.data[0].total_yellow_cards) + skFarki,
        total_red_cards: parseInt(playerResponse.data[0].total_red_cards) + kkFarki,
        total_points: parseInt(playerResponse.data[0].total_points) + puanFarki,
        total_goals: parseInt(playerResponse.data[0].total_goals) + golFarki, // total_goals güncelleniyor

      });

      // Sonuç değişikliklerini başlat
      initUpdateResultChange(isDraw, isNewDraw, newWinnerName, winnerTeamName, loserTeamName, newLoserName);
      alert("İstatistikler başarıyla güncellendi!");
      // Sayfayı yenile
      window.location.reload();
    } catch (error) {
      console.error("İstatistik güncellenirken hata oluştu:", error);
      alert("Bir hata oluştu. Lütfen tekrar deneyin.");
    }
  };*/


    const updateResultChange = async (teamName, win, lost, draw) => {
    try {

      // Takım istatistiklerini güncelle
      const teamResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/team_name/${teamName}`);
      const team = teamResponse.data;

      await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/${team.id}`, {
        team_wins: team.team_wins + win,
        team_losses: team.team_losses + lost,
        team_draws: team.team_draws + draw,
      });

      // Oyuncu istatistiklerini güncelle
      const players = teamName === selectedMatch.first_team_name ? firstTeamPlayers : secondTeamPlayers;
      for (const player of players) {
        const playerResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/${player.player_id}`);
        const playerData = playerResponse.data[0];

        await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/${player.player_id}`, {
          wins: playerData.wins + win,
          losses: playerData.losses + lost,
          draws: playerData.draws + draw,
        });
      }
    } catch (error) {
      console.error("Error updating result change:", error);
    }
  };

  const handleSaveAllStats = async () => {
    
      const allPlayers = [...firstTeamPlayers, ...secondTeamPlayers];
      
          for (const player of allPlayers) {
            const playerId = player.player_id;
            const playerStats = playerStatistics[playerId];
            console.log(playerStats)
      
            if (playerStats) {
      
                const { number_of_goals = 0, goals_minutes = "" } = playerStats;
      
                // Gol dakikalarını boşlukla ayrılmış bir diziye çevir
                const goalMinutesArray = goals_minutes
                    .split(" ")
                    .filter((minute) => minute.trim() !== "");
      
                console.log("gols ",number_of_goals,"gol dk ", goals_minutes);
      
      
                // Eğer gol sayısı 0'dan büyükse ve dakikalar eşleşmiyorsa, hata göster
                if (number_of_goals > 0 && goalMinutesArray.length !== number_of_goals) {
                  console.log("hey")
                    alert(
                        `Oyuncu  için girilen gol sayısı (${number_of_goals}) ile gol dakikaları (${goals_minutes}) eşleşmiyor!`
                    );
                    return; // İşlemi durdur
                }
            }
        }

    
    try {
      // 1. Tüm oyuncular için istatistikleri güncelle
      const allPlayers = [...firstTeamPlayers, ...secondTeamPlayers];
      const oldMatchScores = { firstTeam: 0, secondTeam: 0 };
      const newMatchScores = { firstTeam: 0, secondTeam: 0 };

      for (const player of allPlayers) {
        const playerId = player.player_id;
        const oldStats = playerStatisticsOld[playerId];
        const newStats = playerStatistics[playerId];

        // NaN kontrolü ve 0'a eşitleme
        if (isNaN(newStats.yellow_card)) {
          newStats.yellow_card = 0;
        }
        if (isNaN(newStats.red_card)) {
          newStats.red_card = 0;
        }

        // Fark hesaplama
        const goalDiff = (parseInt(newStats.number_of_goals) || 0) - (parseInt(oldStats.number_of_goals) || 0);
        const pointDiff = (parseInt(newStats.points) || 0) - (parseInt(oldStats.points) || 0);

        //const yellowCardDiff = (parseInt(newStats.yellow_card) || 0) - (parseInt(oldStats.yellow_card) || 0);
        //const redCardDiff = (parseInt(newStats.red_card) || 0) - (parseInt(oldStats.red_card) || 0);

        console.log("eski sari card ", oldStats.yellow_card)
        console.log("yeni sari card", newStats.yellow_card)
        let yellowCardDiff = 0;
        let redCardDiff = 0;
        if (newStats.yellow_card == 0 && oldStats.yellow_card != 0) {
          yellowCardDiff = -1;
        } else if (newStats.yellow_card != 0 && oldStats.yellow_card == 0) {
          yellowCardDiff = 1;
        }

        if (newStats.red_card == 0 && oldStats.red_card != 0) {
          redCardDiff = -1;
        } else if (newStats.red_card != 0 && oldStats.red_card == 0) {
          redCardDiff = 1;
        }
        console.log("sk farki ", yellowCardDiff)
        console.log("kk farki ", redCardDiff)

        // Takımın toplam skorlarını güncelle
        if (firstTeamPlayers.some((p) => p.player_id === playerId)) {
          oldMatchScores.firstTeam += parseInt(oldStats.number_of_goals) || 0;
          newMatchScores.firstTeam += parseInt(newStats.number_of_goals) || 0;
        } else {
          oldMatchScores.secondTeam += parseInt(oldStats.number_of_goals) || 0;
          newMatchScores.secondTeam += parseInt(newStats.number_of_goals) || 0;
        }

        // Oyuncu istatistiklerini güncelle
        await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-istatistikleri/playerStat/${oldStats.id}`, {
          number_of_goals: parseInt(newStats.number_of_goals) || 0,
          yellow_card: parseInt(newStats.yellow_card) || 0,
          red_card: parseInt(newStats.red_card) || 0,
          points: parseInt(newStats.points) || 0,
          goals_minutes: newStats.goals_minutes,
          player_position: newStats.player_position,
        });

        // Oyuncu tablosunu güncelle
        const playerResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/${playerId}`);
        const playerData = playerResponse.data[0];

        await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/${playerId}`, {
          total_goals: playerData.total_goals + goalDiff,
          total_yellow_cards: playerData.total_yellow_cards + yellowCardDiff,
          total_red_cards: playerData.total_red_cards + redCardDiff,
          total_points: playerData.total_points + pointDiff
        });
      }

      // 2. Maç skorlarını güncelle
      const firstTeamScoreDiff = newMatchScores.firstTeam - oldMatchScores.firstTeam;
      const secondTeamScoreDiff = newMatchScores.secondTeam - oldMatchScores.secondTeam;

      await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar/${matchId}`, {
        first_team_score: selectedMatch.first_team_score + firstTeamScoreDiff,
        second_team_score: selectedMatch.second_team_score + secondTeamScoreDiff,
      });

      // 3. Takım toplam gollerini güncelle
      const teamsResponse = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar");
      const firstTeam = teamsResponse.data.find((team) => team.team_name === selectedMatch.first_team_name);
      const secondTeam = teamsResponse.data.find((team) => team.team_name === selectedMatch.second_team_name);

      await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/${firstTeam.id}`, {
        goals_scored: firstTeam.goals_scored + firstTeamScoreDiff,
        goals_conceded: firstTeam.goals_conceded + secondTeamScoreDiff,
      });

      await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/${secondTeam.id}`, {
        goals_scored: secondTeam.goals_scored + secondTeamScoreDiff,
        goals_conceded: secondTeam.goals_conceded + firstTeamScoreDiff,
      });

      // 4. Win, draw, loss durumunu kontrol et ve güncelle
      if (!(selectedMatch.first_team_score === newMatchScores.firstTeam && selectedMatch.second_team_score === newMatchScores.secondTeam)) {


        const isDrawBefore = oldMatchScores.firstTeam === oldMatchScores.secondTeam;
        const isDrawAfter = newMatchScores.firstTeam === newMatchScores.secondTeam;
        let newWinner = null;
        let newLoser = null;

        const oldWinner = oldMatchScores.firstTeam > oldMatchScores.secondTeam
          ? firstTeam.team_name
          : secondTeam.team_name;
        const oldLoser = oldMatchScores.firstTeam < oldMatchScores.secondTeam
          ? firstTeam.team_name
          : secondTeam.team_name;

        if (!isDrawAfter) {
          newWinner = newMatchScores.firstTeam > newMatchScores.secondTeam ? firstTeam.team_name : secondTeam.team_name;
          newLoser = newMatchScores.firstTeam < newMatchScores.secondTeam ? firstTeam.team_name : secondTeam.team_name;
        }

        if (isDrawBefore && !isDrawAfter) {
          console.log("onceden drawdi simdi draw degil")
          await updateResultChange(newWinner, 1, 0, -1);
          await updateResultChange(newLoser, 0, 1, -1);
        }
        else if (!isDrawBefore && isDrawAfter) {
          console.log("onceden draw degildi simdi draw ")

          await updateResultChange(oldWinner, -1, 0, 1);
          await updateResultChange(oldLoser, 0, -1, 1);
        }

        else if (!isDrawBefore && !isDrawAfter && newWinner !== oldWinner) {
          console.log("win ve los yer degistirdi")
          await updateResultChange(oldWinner, -1, 1, 0);
          await updateResultChange(oldLoser, 1, -1, 0);
          //await updateResultChange(newWinner, 1, 0, 0);
          //await updateResultChange(newLoser, 0, 1, 0);
        }
      }


      alert("Tüm istatistikler başarıyla güncellendi!");
      window.location.reload();
    } catch (error) {
      console.error("Tüm istatistikleri güncellerken hata oluştu:", error);
      alert("Bir hata oluştu. Lütfen tekrar deneyin.");
    }
  };



  return (
    <div className='add-match-container'>
      <h1 className="add-match-title" >Maç Bilgileri Güncelleme</h1>

      {selectedMatch && (

        <div className="update-match-form">
          <div className="match-info-container">
            <div className="match-info-row">
              <span className="team-info">{selectedMatch.first_team_name} - Skor: {selectedMatch.first_team_score || "0"}</span>
            </div>
            <div className="match-info-row">
              <span className="team-info">{selectedMatch.second_team_name} - Skor: {selectedMatch.second_team_score || "0"}</span>
            </div>
          </div>

          {/* İlk Takım Oyuncuları */}
          <h2>{selectedMatch.first_team_name} Oyuncuları</h2>
          <table className="player-stats-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>AD</th>
                <th>Mevki</th>
                <th>Gol</th>
                <th>Puan</th>
                <th>SK</th>
                <th>KK</th>
                <th>Gol Dakikaları</th>

              </tr>
            </thead>
            <tbody>
              {firstTeamPlayers.map((player) => (
                <tr key={player.player_id}>
                  <td>{player.player_id}</td>
                  <td>{player.player_name}</td>
                  <td>
                    <select
                      value={playerStatistics[player.player_id]?.player_position || ""}
                      onChange={(e) =>
                        handlePlayerStatsChange(
                          player.player_id,
                          "player_position",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Seç</option>
                      <option value="orta saha">orta saha</option>
                      <option value="kaleci">kaleci</option>
                      <option value="defans">defans</option>
                      <option value="forvet">forvet</option>
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      value={playerStatistics[player.player_id]?.number_of_goals || 0}
                      onChange={(e) =>
                        handlePlayerStatsChange(
                          player.player_id,
                          "number_of_goals",
                          parseInt(e.target.value, 10)
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={playerStatistics[player.player_id]?.points || 0}
                      onChange={(e) =>
                        handlePlayerStatsChange(
                          player.player_id,
                          "points",
                          parseInt(e.target.value, 10)
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={playerStatistics[player.player_id]?.yellow_card || 0}
                      onChange={(e) =>
                        handlePlayerStatsChange(
                          player.player_id,
                          "yellow_card",
                          parseInt(e.target.value, 10)
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={playerStatistics[player.player_id]?.red_card || 0}
                      onChange={(e) =>
                        handlePlayerStatsChange(
                          player.player_id,
                          "red_card",
                          parseInt(e.target.value, 10)
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={playerStatistics[player.player_id]?.goals_minutes || ""}
                      onChange={(e) =>
                        handlePlayerStatsChange(
                          player.player_id,
                          "goals_minutes",
                          e.target.value
                        )
                      }
                    />
                  </td>

                </tr>
              ))}
            </tbody>
          </table>

          <h2>{selectedMatch.second_team_name} Oyuncuları</h2>
          <table className="player-stats-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Oyuncu Adı</th>
                <th>Mevki</th>
                <th>Gol</th>
                <th>Puan</th>
                <th>SK</th>
                <th>KK</th>
                <th>Gol Dakikaları</th>

              </tr>
            </thead>
            <tbody>
              {secondTeamPlayers.map((player) => (
                <tr key={player.player_id}>
                  <td>{player.player_id}</td>
                  <td>{player.player_name}</td>
                  <td>
                    <select
                      value={playerStatistics[player.player_id]?.player_position || ""}
                      onChange={(e) =>
                        handlePlayerStatsChange(
                          player.player_id,
                          "player_position",
                          e.target.value
                        )
                      }
                    >
                     <option value="">Seç</option>
                    <option value="orta saha">orta saha</option>
                    <option value="kaleci">kaleci</option>
                    <option value="defans">defans</option>
                    <option value="forvet">forvet</option>
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      value={playerStatistics[player.player_id]?.number_of_goals || 0}
                      onChange={(e) =>
                        handlePlayerStatsChange(
                          player.player_id,
                          "number_of_goals",
                          parseInt(e.target.value, 10)
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={playerStatistics[player.player_id]?.points || 0}
                      onChange={(e) =>
                        handlePlayerStatsChange(
                          player.player_id,
                          "points",
                          parseInt(e.target.value, 10)
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={playerStatistics[player.player_id]?.yellow_card || 0}
                      onChange={(e) =>
                        handlePlayerStatsChange(
                          player.player_id,
                          "yellow_card",
                          parseInt(e.target.value, 10)
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={playerStatistics[player.player_id]?.red_card || 0}
                      onChange={(e) =>
                        handlePlayerStatsChange(
                          player.player_id,
                          "red_card",
                          parseInt(e.target.value, 10)
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={playerStatistics[player.player_id]?.goals_minutes || ""}
                      onChange={(e) =>
                        handlePlayerStatsChange(
                          player.player_id,
                          "goals_minutes",
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {isButtonVisible && (
            <button
              className="add-match-add-player-button"
              onClick={() => {
                handleSaveAllStats();
                setIsButtonVisible(false); // Butonu gizle
              }}

            >
              Tüm İstatistikleri Kaydet
            </button>
          )}

          <div className="update-match-form-row" >
            <label className='update-match-label'>Maç MVP</label>
            <input
              type="number" className="update-match-input"
              value={selectedMatch.match_mvp || ""}
              onChange={(e) => handleFieldChange('match_mvp', parseInt(e.target.value))}
            />
            <button className="add-match-add-player-button" onClick={() => handleUpdateField('match_mvp')}>Güncelle</button>
          </div>

          <div className="update-match-form-row" >
            <label className='update-match-label'>{selectedMatch.first_team_name} Takım Yüzdesi</label>
            <input
              type="text" className="update-match-input"
              value={selectedMatch.first_team_percentage || ""}
              onChange={(e) => handleFieldChange('first_team_percentage', e.target.value)}
            />
            <button className="add-match-add-player-button" onClick={() => handleUpdateField('first_team_percentage')}>Güncelle</button>
          </div>
          <div className="update-match-form-row" >
            <label className='update-match-label'>{selectedMatch.second_team_name} Takım Yüzdesi</label>
            <input
              type="text" className="update-match-input"
              value={selectedMatch.second_team_percentage || ""}
              onChange={(e) => handleFieldChange('second_team_percentage', e.target.value)}
            />
            <button className="add-match-add-player-button" onClick={() => handleUpdateField('second_team_percentage')}>Güncelle</button>
          </div>
          <div className="update-match-form-row" >
            <label className='update-match-label'>Tarih</label>
            <input
              type="date" className="update-match-input"
              value={selectedMatch.date || ""}
              onChange={(e) => handleFieldChange('date', e.target.value)}
            />
            <button className="add-match-add-player-button" onClick={() => handleUpdateField('date')}>Güncelle</button>
          </div>
          <div className="update-match-form-row" >
            <label className='update-match-label'>Saat</label>
            <input
              type="time" className="update-match-input"
              value={selectedMatch.time || ""}
              onChange={(e) => handleFieldChange('time', e.target.value)}
            />
            <button className="add-match-add-player-button" onClick={() => handleUpdateField('time')}>Güncelle</button>
          </div>
          
          <div className="update-match-form-row">
            <label className="update-match-label">Lig</label>
            <select
              className="update-match-input"
              value={selectedMatch.league_name || ""}
              onChange={(e) => handleFieldChange("league_name", e.target.value)}
            >
              <option value="">Seç</option>
              {leagues.map((league) => (
                <option key={league.id} value={league.league_name}>
                  {league.league_name}
                </option>
              ))}
            </select>
            <button
              className="add-match-add-player-button"
              onClick={() => handleUpdateField("league_name")}
            >
              Güncelle
            </button>
          </div>
          
          <div className="update-match-form-row">
            <label className="update-match-label">Sezon</label>
            <select
              className="update-match-input"
              value={selectedMatch.match_season || ""}
              onChange={(e) => handleFieldChange("match_season", e.target.value)}
            >
              <option value="">Seç</option>
              {seasons.map((season) => (
                <option key={season.id} value={season.season_name}>
                  {season.season_name}
                </option>
              ))}
            </select>
            <button
              className="add-match-add-player-button"
              onClick={() => handleUpdateField("match_season")}
            >
              Güncelle
            </button>
          </div>

          <div className="update-match-form-row" >
            <label className='update-match-label'>Maç Başlığı</label>
            <input
              type="text" className="update-match-input"
              value={selectedMatch.match_title || ""}
              onChange={(e) => handleFieldChange('match_title', e.target.value)}
            />
            <button className="add-match-add-player-button" onClick={() => handleUpdateField('match_title')}>Güncelle</button>
          </div>

          <div className="update-match-form-row" >
            <label className='update-match-label'>Maç Yorumu</label>
            <input
              type="text" className="update-match-input"
              value={selectedMatch.match_comment || ""}
              onChange={(e) => handleFieldChange('match_comment', e.target.value)}
            />
            <button className="add-match-add-player-button" onClick={() => handleUpdateField('match_comment')}>Güncelle</button>
          </div>

          <div className="update-match-form-row">
            <label className='update-match-label'>Maç Resmi</label>
            <input type="file" className="update-match-input" onChange={handleFileChange} />
            <button className="add-match-add-player-button" onClick={handleFileUpload}>Yükle</button>
            {selectedMatch.match_picture && (
              <img
                src={`${baseUrl}${selectedMatch.match_picture}`}
                alt="Maç Resmi"
                style={{ width: '100px', marginTop: '10px' }}
              />
            )}
          </div>

          

          <div className="update-match-form-row" >
            <label className='update-match-label'>Maç Görselleri</label>
            <input
              type="text" className="update-match-input"
              value={selectedMatch.match_images || ""}
              onChange={(e) => handleFieldChange('match_images', e.target.value)}
            />
            <button className="add-match-add-player-button" onClick={() => handleUpdateField('match_images')}>Güncelle</button>
          </div>

          <div className="update-match-form-row" >
            <label className='update-match-label'>Maç Videosu</label>
            <input
              type="text" className="update-match-input"
              value={selectedMatch.match_video || ""}
              onChange={(e) => handleFieldChange('match_video', e.target.value)}
            />
            <button className="add-match-add-player-button" onClick={() => handleUpdateField('match_video')}>Güncelle</button>
          </div>

          <div className="update-match-form-row" >
            <label className='update-match-label'>Maç Röportajı</label>
            <input
              type="text" className="update-match-input"
              value={selectedMatch.match_interview || ""}
              onChange={(e) => handleFieldChange('match_interview', e.target.value)}
            />
            <button className="add-match-add-player-button" onClick={() => handleUpdateField('match_interview')}>Güncelle</button>
          </div>

          <div className="update-match-form-row" >
            <label className='update-match-label'>Saha</label>
            <input
              type="text" className="update-match-input"
              value={selectedMatch.field || ""}
              onChange={(e) => handleFieldChange('field', e.target.value)}
            />
            <button className="add-match-add-player-button" onClick={() => handleUpdateField('field')}>Güncelle</button>
          </div>

          {isButtonVisible && (
            <div className="update-match-form-row">
              <button
                className="add-match-add-player-button"
                onClick={() => {
                  handleDeleteMatch();
                  setIsButtonVisible(false); // Butonu gizle
                }}
                style={{ backgroundColor: 'red', color: 'white' }}>Seçilen Maçı Sil

              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Update_Match;
