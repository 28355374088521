import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Editor_Players.css'; // Bu satırı ekleyin
import './Add_Teams.css';
import AddPlayers from './Add_Player.js';
import UpdatePlayer from './Update_Player.js';
import { useUser } from "../UserContext"; // UserContext'i çağır


const Editor_Players = () => {
    const [playerData, setPlayerData] = useState({
        player_name: "",
        player_img: "",
        team_id: 0,
        birth_date: "",
        nationality: "",
        value: 0,
        season: "",
        total_matches: 0,
        total_goals: 0,
        total_points: 0,
        total_yellow_cards: 0,
        total_red_cards: 0,
        wins: 0,
        draws: 0,
        losses: 0,
        attendence_date: ""
    });

    const [players, setPlayers] = useState([]);
    const [teams, setTeams] = useState([]);
    const [filteredPlayers, setFilteredPlayers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const { user, setUser } = useUser();
    const userCity = user?.city;
     
    const playersPerPage = 10;

    const lastIndex = currentPage * playersPerPage;
    const firstIndex = lastIndex - playersPerPage;

    const currentPlayers = filteredPlayers.slice(firstIndex, lastIndex);

    const totalPages = Math.ceil(filteredPlayers.length / playersPerPage);

    const getPaginationRange = () => {
        const range = [];
        const maxVisiblePages = 3;
        if (totalPages <= maxVisiblePages) {
            for (let i = 1; i <= totalPages; i++) {
                range.push(i);
            }
            return range;
        }
    
        let start = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        let end = Math.min(totalPages, start + maxVisiblePages - 1);
    
        if (end === totalPages) {
            start = Math.max(1, totalPages - maxVisiblePages + 1);
        }
    
        for (let i = start; i <= end; i++) {
            range.push(i);
        }
    
        // Adjust for start > 1, and ensure '...' starts after the first element (only when necessary)
        if (start > 2) {  // Changed condition to `> 2` to avoid showing `1 ... 2`
            range.unshift('...');
            range.unshift(1);
        } else if (start === 2) {
            range.unshift(1);
        }
    
        // Adjust for end < totalPages and add '...' at the end
        if (end < totalPages - 1) {
            range.push('...');
            range.push(totalPages);
        } else if (end === totalPages - 1) {
            range.push(totalPages);
        }
    
        return range;
    };
    const paginationRange = getPaginationRange();
    

    const baseUrl = "https://elit-lig-back-end-bc5730e96b98.herokuapp.com/";

    // Oyuncuları ve takımları yükle
    const fetchPlayersAndTeams = async () => {
        try {
            const playersResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/by-city/${userCity}`);

            // Oyuncuları en son eklenenden en eskiye doğru sırala
            const sortedPlayers = playersResponse.data.sort((a, b) => {
                const dateA = new Date(a.createdAt);
                const dateB = new Date(b.createdAt);
                return dateB - dateA; // En son eklenen en üstte
            });

            setPlayers(sortedPlayers);
            setFilteredPlayers(sortedPlayers);

            const teamsResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/by-city/${userCity}`);
            // Takımları alfabetik olarak sırala
            setTeams(teamsResponse.data);
        } catch (err) {
            console.error("Error fetching players or teams", err);
        }
    };

    // Component yüklendiğinde oyuncuları ve takımları getir
    useEffect(() => {
        fetchPlayersAndTeams();
    }, []);

    

    // refreshPlayers fonksiyonu fetchPlayersAndTeams'i çağırır
    const refreshPlayers = () => {
        fetchPlayersAndTeams();
    };

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchQuery(value);
        const filtered = players
            .filter(player => player.player_name.toLowerCase().includes(value));
        setFilteredPlayers(filtered);
    };

    
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    // Güncelleme modalını açma
    const openUpdateModal = (player) => {
        setSelectedPlayer(player);
        setIsUpdateModalOpen(true);
    };

    const closeUpdateModal = () => {
        setIsUpdateModalOpen(false);
        setSelectedPlayer(null);
        fetchPlayersAndTeams(); // Oyuncu listesini yenile
    };

    return (
        <div>
            <div className="teams-container">
                {/* Yeni Oyuncu Ekle Butonu */}
                <button className="add-buttonn" onClick={handleOpenModal} >
                    Yeni Oyuncu Ekle
                </button>

                {/* Oyuncu Arama Alanı */}
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Oyuncu Ara..."
                        value={searchQuery}
                        onChange={handleSearch}
                    />
                </div>

                {/* Oyuncular Tablosu */}
                <div className="teams-table">
                    <div className="table-header-player">
                    <span>Oyuncu Foto</span>
                        <span>Oyuncu Adı</span>
                        <span>Takımı</span>
                    </div>

                    {currentPlayers.map((player, index) => (
                        <div key={index} className="team-item-player">
                            <span>
                            {player.player_img && (
                                <img
                                src={player.player_img}
                                    alt={`${player.player_name} logo`}
                                    className="player-logo"
                                />
                            )}
                        </span>
                            <span>{player.player_name}</span>
                            <span>{player.team_name}</span>
                            <button className="update-button" onClick={() => openUpdateModal(player)}>
                                Güncelle
                            </button>
                        </div>
                    ))}
                </div>
                {/* Sayfalandırma (Pagination) */}
                <div style={{ textAlign: 'center', marginTop: '-10px' }}> {/* Adjusted marginTop here */}
                {paginationRange.map((page, index) => (
                    <button
                        key={index}
                        onClick={() => {
                            if (typeof page === 'number') {
                                setCurrentPage(page);
                            }
                        }}
                        disabled={page === '...'}
                        style={{
                            margin: '0 2px',
                            padding: '5px 10px',
                            height: '30px',
                            marginBottom: '10px',
                            backgroundColor: currentPage === page ? '#320037' : '#008080',
                            color: currentPage === page ? '#ffffff' : '#ffffff',
                            border: '1px solid #008080',
                            borderRadius: '5px',
                            cursor: typeof page === 'number' ? 'pointer' : 'not-allowed',
                        }}
                    >
                        {page}
                    </button>
                ))}
            </div>

                {/* Modal */}
                {isModalOpen && (
                    <AddPlayers closeModal={handleCloseModal} refreshTeams={fetchPlayersAndTeams} />
                )}
                {isUpdateModalOpen && selectedPlayer && (
                    <UpdatePlayer
                        selectedPlayer={selectedPlayer}
                        onClose={closeUpdateModal}
                        refreshPlayers={refreshPlayers}
                    />
                )}

            </div>
        </div>
    );
};

export default Editor_Players;
