import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // URL'den şehir ve lig bilgisini almak için
import Header from "./Header";
import Footer from "./Footer";
import "./style.css";
import { useData } from "./Cache";

const LeagueStandings = () => {
  const { cityName, leagueName } = useParams(); // URL'den şehir ve lig bilgisi al
  const { matches, seasons, teams, loading, error } = useData();

  const [selectedSeason, setSelectedSeason] = useState(null);
  const [filteredMatches, setFilteredMatches] = useState([]);

  // Sezon veya URL parametreleri değiştiğinde maçları filtreleme
  useEffect(() => {
    if (leagueName && selectedSeason) {
      const filtered = matches.filter(
        (match) =>
          formatTeamNameForURL(match.league_name) === leagueName &&
          match.match_season === selectedSeason &&
          match.is_it_fixture === false &&
          formatTeamNameForURL(match.city) === formatTeamNameForURL(cityName)
      );
      setFilteredMatches(filtered);
    } else {
      setFilteredMatches([]); // Seçim olmadığında boş liste
    }
  }, [leagueName, selectedSeason, matches]);

  const handleSeasonChange = (seasonId) => {
    setSelectedSeason(seasonId);
  };

  const calculateTeamStats = (teamName, matches) => {
    // Maçları takım adına göre filtrele
    const teamMatches = matches.filter(
      (match) =>
        formatTeamNameForURL(match.first_team_name) === formatTeamNameForURL(teamName) ||
        formatTeamNameForURL(match.second_team_name) === formatTeamNameForURL(teamName)
    );

    // Maçları tarihlerine göre sırala
    teamMatches.sort((a, b) => new Date(a.date) - new Date(b.date));

    const stats = {
      wins: 0,
      draws: 0,
      losses: 0,
      goalsFor: 0,
      goalsAgainst: 0,
      lastFiveMatches: [],
    };

    teamMatches.forEach((match) => {
      const isHome = formatTeamNameForURL(match.first_team_name) === formatTeamNameForURL(teamName);
      const teamScore = isHome
        ? match.first_team_score
        : match.second_team_score;
      const opponentScore = isHome
        ? match.second_team_score
        : match.first_team_score;

      stats.goalsFor += teamScore;
      stats.goalsAgainst += opponentScore;

      if (teamScore > opponentScore) stats.wins++;
      else if (teamScore === opponentScore) stats.draws++;
      else stats.losses++;

      // Son 5 maçın sonucunu kaydet
      stats.lastFiveMatches.push({
        matchId: match.id,
        result:
          teamScore > opponentScore
            ? "win"
            : teamScore === opponentScore
            ? "draw"
            : "loss",
      });
    });

    // Sadece son beş maçı al
    stats.lastFiveMatches = stats.lastFiveMatches.slice(-5);

    return stats;
  };

  function formatTeamNameForURL(teamName) {
    return teamName
      .toLowerCase()
      .replace(/ç/g, "c")
      .replace(/ğ/g, "g")
      .replace(/ı/g, "i")
      .replace(/ö/g, "o")
      .replace(/ş/g, "s")
      .replace(/ü/g, "u")
      .replace(/[^a-z0-9 ]/g, "")
      .trim()
      .replace(/\s+/g, "-");
  }

  const filteredTeams = teams.filter(
    (team) => formatTeamNameForURL(team.city) === formatTeamNameForURL(cityName) && team.active
  );

  const teamStats = filteredTeams.map((team) => ({
    ...team,
    stats: calculateTeamStats(team.team_name, filteredMatches),
  }));

  return (
    <div>
      <Header />
      <header class="header-banner">
        <h1 class="banner-title">Puan Tablosu</h1>
        <div class="zigzag">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#4facfe"
              fill-opacity="1"
              d="M0,256L48,224C96,192,192,128,288,96C384,64,480,64,576,96C672,128,768,192,864,213.3C960,235,1056,213,1152,213.3C1248,213,1344,235,1392,245.3L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
            ></path>
          </svg>
        </div>
      </header>

      <div className="centered-container-2">
        <select onChange={(e) => handleSeasonChange(e.target.value)}>
          <option value="">Sezon Seç</option>
          {seasons
            .filter(
              (season) =>
                formatTeamNameForURL(season.league_name) === leagueName
            )
            .map((season) => (
              <option key={season.id} value={season.season_name}>
                {season.season_name}
              </option>
            ))}
        </select>
      </div>

      <div className="table-container">
        {selectedSeason && (
          <div>
            <table>
              <thead>
                <tr>
                  <th>Sıra</th>
                  <th>Takım</th>
                  <th>Oyn. Maç</th>
                  <th>Gal</th>
                  <th>Ber</th>
                  <th>Mağ</th>
                  <th>AG</th>
                  <th>YG</th>
                  <th>Av</th>
                  <th>Puan</th>
                  <th>Form</th>
                  <th>İncele</th>
                </tr>
              </thead>
              <tbody>
                {teamStats
                  .filter((team) => team.current_season === selectedSeason || (team.stats.wins + team.stats.draws + team.stats.losses) > 0)
                  .sort((a, b) => {
                    const pointsA = a.stats.wins * 3 + a.stats.draws + a.team_points;
                    const pointsB = b.stats.wins * 3 + b.stats.draws + b.team_points;
                    return pointsB - pointsA;
                  })
                  .map((team, index) => (
                    <tr key={team.id}>
                      <td>{index + 1}</td>
                      <td
                        style={{
                          display: "flex",
                          textAlign: "left",
                          alignItems: "center",
                          fontWeight: "bolder",
                        }}
                      >
                        <img
                          src={team.logo}
                          alt={team.team_name}
                          className="little-logo-style"
                        />
                        <span>{team.team_name}</span>
                      </td>
                      <td>
                        {team.stats.wins + team.stats.draws + team.stats.losses}
                      </td>
                      <td>{team.stats.wins}</td>
                      <td>{team.stats.draws}</td>
                      <td>{team.stats.losses}</td>
                      <td>{team.stats.goalsFor}</td>
                      <td>{team.stats.goalsAgainst}</td>
                      <td>{team.stats.goalsFor - team.stats.goalsAgainst}</td>
                      <td style={{ fontWeight: "bolder", fontSize: "17px" }}>
                        {team.stats.wins * 3 + team.stats.draws + team.team_points}
                      </td>
                      <td>
                        {team.stats.lastFiveMatches &&
                        team.stats.lastFiveMatches.length > 0 ? (
                          team.stats.lastFiveMatches.map((match, i) => (
                            <span key={i} className={`form ${match.result}`}>
                              {match.result.slice(0, 1).toUpperCase()}
                            </span>
                          ))
                        ) : (
                          <span style={{ color: "gray" }}>-</span>
                        )}
                      </td>
                      <td
                        onClick={() =>
                          (window.location.href = `/${formatTeamNameForURL(cityName)}/${formatTeamNameForURL(leagueName)}/takimlar/${formatTeamNameForURL(
                            team.team_name
                          )}`)
                        }
                      >
                        ➡
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default LeagueStandings;
