import { useState, useEffect } from "react";

export default function Scoreboard() {
  const [teamA, setTeamA] = useState("AYZ");
  const [teamB, setTeamB] = useState("KAY");
  const [scoreA, setScoreA] = useState(3);
  const [scoreB, setScoreB] = useState(5);
  const [time, setTime] = useState(0); // Başlangıç 0 saniye
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    let timer;
    if (isRunning) {
      timer = setInterval(() => setTime((prev) => prev + 1), 1000);
    }
    return () => clearInterval(timer);
  }, [isRunning]);

  const resetGame = () => {
    setScoreA(0);
    setScoreB(0);
    setTime(0);
    setIsRunning(false);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "10px", padding: "10px", fontFamily: "Arial, sans-serif" }}>
      {/* Scoreboard Display */}
      <div style={{ display: "flex", alignItems: "center", backgroundColor: "#0B1215", color: "white", padding: "10px", borderRadius: "8px", fontSize: "20px" }}>
        <div style={{ padding: "10px", backgroundColor: "#121E1E", fontWeight: "bold" }}>{teamA}</div>
        <div style={{ padding: "10px", backgroundColor: "#3A1D92", fontWeight: "bold", display: "flex", alignItems: "center" }}>
          {scoreA} | {scoreB}
        </div>
        <div style={{ padding: "10px", backgroundColor: "#121E1E", fontWeight: "bold" }}>{teamB}</div>
        <div style={{ padding: "10px", backgroundColor: "#3A1D92", fontWeight: "bold" }}>{formatTime(time)}</div>
      </div>
      {/* Controls */}
      <div style={{ display: "flex", gap: "5px" }}>
        <button onClick={() => setScoreA(scoreA + 1)}>+1 {teamA}</button>
        <button onClick={() => setScoreB(scoreB + 1)}>+1 {teamB}</button>
        <button onClick={() => setIsRunning(!isRunning)}>
          {isRunning ? "Durdur" : "Başlat"}
        </button>
        <button onClick={resetGame} style={{ backgroundColor: "red", color: "white" }}>Reset</button>
      </div>
    </div>
  );
}
