import Header from './Header';
import './style.css';
import Footer from './Footer';

const Rules = () => {
    return (
        <div>
            <Header />
            <header class="header-banner">
                <h1 class="banner-title">Kurallar</h1>
                <div class="zigzag">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path fill="#4facfe" fill-opacity="1" d="M0,256L48,224C96,192,192,128,288,96C384,64,480,64,576,96C672,128,768,192,864,213.3C960,235,1056,213,1152,213.3C1248,213,1344,235,1392,245.3L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
                    </svg>
                </div>
            </header>
            <div className="rules-container">
                <h1 className="rules-title">KURALLAR</h1>
                <ol className="rules-list">
                    <li>
                        LİGİN İŞLEYİŞİ:
                        <p className="rule-description">Elit Lig’in Adı Ligimizde Bulunmasını Hedeflediğimiz “Elit” Takımlardan Gelmektedir. Burada Bahsedilen Elit Takım Futbol Kalitesinden Ziyade Futbol Kültürü, Ahlakı Oturmuş Bilinçli Takımlardır. Bizim İçin En Değerli Takım Centilmenliğe Puandan Daha Çok Önem Veren Takımdır. Bu Hedeflerimize Ulaşmak İçin Lig İçerisinde Disiplin Kurallarından Asla Taviz Vermeyeceğiz.</p>
                        <p className="rule-description">Elit Lig’de Para Ödülü Yoktur. Bunun Yerine Yılın Şampiyonu, Takım Olarak Tatile Götürülür. Para Ödülü Verilmemesinin Nedeni Ligimizde Oluşmasını İstediğimiz Dostluk Ortamının, Ödül Hırsıyla Zedelenmemesini İstememizdir. Bu Amaçla Haftalık Veya Aylık Olarak Vereceğimiz Sembolik Ödüller Ve Yılın Şampiyonuna Vereceğimiz Tatil Ödülüyle Hem Rekabeti Diri Tutmayı Hem de Rekabetin Dostluğa Zarar Vermesini Engellemeyi Amaçlamaktayız.</p>
                        <p className="rule-description">Elit Lig’de 1 Yıl 2 Sezondan Oluşmaktadır. 6 Aylık Sezonların Son Maçlarına 31 Mayıs’ı 1 Haziran’a Ve 30 Kasım’ı 1 Aralık’a Bağlayan Gece 02.00 da Başlayan Maçlar Dahildir.</p>
                        <p className="rule-description">Sezonun Tamamlanmasının Ardından Play-Off Süreci Başlar Ve Play-Off’u Kazanan Takım, Kupa Ve Madalyalarını Alarak Sezonun Şampiyonu Olur.</p>
                        <p className="rule-description">2 Sezonun Şampiyonu Süper Final Maçına Çıkar Ve Kazanan Takım Yılın Şampiyonu Ünvanını Alarak Tatil Ödülünü Almaya Hak Kazanır.</p>
                    </li>
                    <li>
                        LİGE KATILIM:
                        <p className="rule-description">Elit Lig’e Katılmak İçin Takım Yetkilisinin Siteye Girip Kayıt Olması Veya 0507 169 0 888 Müşteri Temsilcimizi Arayıp Gerekli Bilgileri Vermesi Gerekmektedir.</p>
                        <p className="rule-description">Elit Lig’e Katılım İçin Herhangi Bir Tarih Ve Katılım Ücreti Yoktur. Takımının Hazır Olması Halinde İsteyen Her Takım İstediği Tarihte Elit Lig’e Kayıt Olabilir ve Dilediği Tarihe Maçını Alabilir.</p>
                        <p className="rule-description">Katılımcı Takımlar Sadece Oynadıkları Maça Ücret Öderler. Bunun Dışında Hiçbir Ücret Talep Edilmez.</p>
                        <p className="rule-description">Kayıt İşleminin Ardından, Hemen İlk Maçınızı Almayı Talep Edebilirsiniz. Bu Talebinizi İster Online Olarak Site Üzerinden, İsterseniz de İletişim Merkezini Arayarak İletebilirsiniz. Talebinizi İletmenizin Ardından Görevli Personel Talepleriniz Doğrultusunda Size Ayarlayacağı Rakip Ve Saha İçin Görüşmelerde Bulunur.</p>
                        <p className="rule-description">Rakibi, Hakemi Ve Sahayı Ayarlamasının Ardından Bilgilendirme İçin Size Geri Dönüş Sağlar. Sizin Yapmanız Gereken Maçın Saatinden 10 Dk Önce Takımınızla Beraber Sahada Olmaktır.</p>
                    </li>
                    <li>
                        TAKIM KADROLARI:
                        <p className="rule-description">Maçlar 8 As 4 Yedek Oyuncuyla Oynanır. Her Takımın 4 Oyuncu Değişikliği Hakkı Vardır. Oyundan Çıkan Oyuncu Tekrar Oyuna Giremez.</p>
                        <p className="rule-description">Takımlar Ligde Yapacakları Maçlarda Play-Off’lara Kadar Kadro Konusunda Özgürdür. İstediği Kadroyla Sahaya Çıkabilir.</p>
                        <p className="rule-description">Takım Yetkilisi Play-Off Kayıt Sürecinde 13 Kişilik Listeyi, Sitedeki Oyuncu Ekleme Ekranından Veya Whatsapp Hattından Mesaj Olarak Lig Yönetimine Göndermesi Gerekmektedir.</p>
                        <p className="rule-description">Play-Off’lara Kadar Gönderilen Listedeki Oyuncuların 3’ünün Takımı Adına En Az 10 Maçta Oynamış Olması; Diğer 5’inin En Az 5 Maçta Oynamış Olması Ve Kalan 5’inin De En Az 3 Maçta Oynamış Olması Gerekmektedir.</p>
                        <p className="rule-description">Takım Kadrosuyla İlgili Yukarıda Belirtilen Kuralların İhlalinde Uygulanacak Ceza Hükmen Mağlubiyettir. İtiraz Süreciyle İlgili Bilgiler Maç Sonrası Başlığı Altında Belirtilmiştir.</p>
                        <p className="rule-description">Bir Takım Sahaya En Az 5 Kişiyle Çıkabilir. 5 Kişiden Az Kişiyle Çıkılan Veya Görülen Kırmızı Kartlarla 5 Kişinin Altına Düşülen Maçlar Eksilen Takımın Aleyhine 3-0 Tescil Edilir.</p>
                        <p className="rule-description">Play-Off’larda Verilecek 13 Kişilik Takım Kadrosunda Güncel Futbol Sezonunda Aktif Lisanslı Yalnızca 3 Oyuncu Bulundurulabilir. Bu lisanslı oyunculardan yalnızca 2si aynı anda sahada bulunabilir.</p>
                        <p className="rule-description">Ligden Daha Önceki Sezonlarda İhraç Edilmiş Oyuncuların Ligde Oynaması İle İlgili Karar Lig Yönetimine Aittir. Mevcut Sezonda İhraç Edilmiş Oyuncular İçin, Rakip Takımın İtirazı Hükmen Mağlubiyet Olarak Tescillenecek Olup, Önceki Sezonlarda İhraç Edilmiş Oyuncular İçin Yapılacak İtiraz, Hükmen Mağlubiyet Olabileceği Gibi, Uyarı Cezası Ve İtirazın Reddi Kararı da Verilebilir.</p>
                        <p className="rule-description">Kadroya Daimi Eklenecek Oyuncunun Bilgilerinin Doğru Verilmesi Zorunludur. Bu Bilgiler Kadrodaki Oyuncuların Doğrulanması Açısından Gereklidir.</p>
                    </li>
                    <li>
                        MAÇ ÖNCESİ:
                        <p className="rule-description">Maçınızı Almak İçin Sitedeki Online Maç Al Sayfasından İstenen Bilgileri Eksiksiz Doldurmanız Veya İletişim Merkezimizle Görüşmeniz Gerekmektedir. Online Alacağınız Maçların Ardından Teyit İçin Sizi Arayacağız. Teyit Edilmemiş Hiçbir Talep Kabul Edilmez.</p>
                        <p className="rule-description">İstediğiniz Gün Ve Saatte İstediğiniz Rakiple Maçınızın Ayarlanmasının Ardından Temsilcimiz Sizi Telefonla Bilgilendirecektir. Bu Bildirimin Ardından Maçınızı İptal Etmek İsterseniz Maç Saatinden En Az 12 Saat Öncesinde İptal Talebinizi İletişim Merkezimize Bildirmeniz Gerekmektedir.</p>
                        <p className="rule-description">Eğer Maçı Aldığınız Tarihten İtibaren Maça 12 Saatten Az Süre Kalmışsa İptal Talebini Maçı Aldığınız Saatten İtibaren 1 Saat İçinde Bildirmeniz Gerekir. Örneğin Salı Günü Perşembe 21.00 ‘a Maç Aldınız Ve Daha Sonra İptal Etmek İstediniz. İptal Talebinizi Perşembe 09.00’a Kadar İletişim Merkezimize Bildirmeniz Gerekli. Ancak Salı Günü 16.00’te Aynı Günün Akşamı 21.00’a Maç Almışsanız, İptal Talebinizi Salı Günü 17.00’a Kadar Bildirmeniz Gerekir.</p>
                        <p className="rule-description">Yukarıda Belirtilen Sürelerde İptal Edilen Maçlardan Hiçbir Ücret Talep Edilmez Ve Hükmen Mağlup Edilmez. Ancak Bu Sürelerin Dışında İptal Edilen Maçlar Veya Hiç Gelinmeyen Maçlar Hükmen Mağlubiyetle Cezalandırılır Ve Bu Maçın Ücretinin Takip Eden Hafta İçerisinde Ödenmesi Gerekir. Aksi Halde O Takım Elit Lig’e Devam Edemez.</p>
                        <p className="rule-description">Yukarıdaki Hükmen Mağlubiyet Gerektiren Şartlar Oluşmasına Rağmen, Hükmen Galibiyeti Hak Eden Taraf, Bu Hakkından Feragat Etme Hakkına Sahiptir. Ancak Bu Durumu Bir Başka Müsabaka İçin Emsal Olarak Gösteremez. Kendi Vazgeçtiği Hakkından Başka Takımın da Vazgeçmesini İsteme Hakkına Sahip Değildir.</p>
                        <p className="rule-description">Takımların Maç Takviminde Sıkıntı Yaşanmaması İçin Maç Saatinden 10 Dk Önce Sahada Olması Tavsiye Edilir.</p>
                        <p className="rule-description">Takımlar Maçtan Önce Maç Ücretini Ve Kadro Listesini Görevliye Teslim Etmelidir. Aksi Takdirde Maç Başlamaz.</p>
                        <p className="rule-description">Takımın Kendine Ait (Sırtında Herkes İçin Ayrı Numara Olan) Forması Varsa, O Formalarla Oynayabilir. Ancak Sırtında Numara Olmayan, Veya Aynı Numaradan Birden Fazla Bulunan Veya Numara Olsa Da Tek Tip Olmayan Formalarla Oynanamaz. Bu Durumda Görevli Tarafından Verilen Yelekler (Bip) Giyilir.</p>
                        <p className="rule-description">2 Takımın Formalarının Da Aynı Renkte Olması Durumunda Hakem Bir Tarafın Yelek (Bip) Giymesini İsteyebilir. Yelek Giyecek Takıma Yazı-Tura Atışıyla Karar Verilir.</p>
                        <p className="rule-description">Eğer Takım Maça Geç Kalmışsa 15 Dk Bekleme Süresi Vardır. 15 Dakikanın Sonunda Geç Kalan Takım Sahaya Çıkacak 4 Kişiyi Tamamlayamamışsa Hükmen 3-0 Mağlup Sayılır. Rakip Takım Biraz Daha Beklemeyi Kabul Ederse Maçın Normal Saatinde Tamamlanmasını Kabul Ederek Biraz Daha Bekleyebilir. Örneğin Takım Sahaya 20 Dk Geç Kaldı. 21.00 Maçı Rakibin Beklemeyi Kabul Etmesiyle 21.20’de Başladı. Bu Durumda Maç 22.00’de Biter. Maç 40 Dk Oynanır. Hükmen Galibiyeti Kabul Etmeyip Beklemeyi Seçen Takım Bu Şartları da Kabul Etmiş Olur.</p>
                    </li>
                    <li>
                        MAÇ SONRASI:
                        <p className="rule-description">Elit Lig’de İtirazlar Maç Sonrasında Yapılır. Maçın Oynandığı Günün Ertesi Günü Saat 12:00’a Kadar  Yapılan Ve Sadece İtiraza Konu Maçtaki Takımın Kaptanının Yaptığı İtiraz Geçerlidir. 3. Bir Takımın Yaptığı Veya Kaptan Dışındaki Başka Bir Oyuncunun Yaptığı İtirazlar Geçersizdir.</p>
                        <p className="rule-description">İtiraza Konu Olay Hükmen Mağlubiyetse Ve İtiraz Haklı Bulunursa Maç Sonucuna Göre Hükmen Mağlubiyet Uygulanır. Eğer Maç Sonucu İtirazı Haklı Bulunan Takım Lehine 3 Golden Fazla Farkla Ve Galibiyetle Sonuçlanmışsa Hükmen Mağlup Olacak Takımın Attığı Goller Silinir Ve Skor Sadece Galip Takımın Golleri Yazılacak Şekilde Tescillenir. Örneğin İtirazda Bulunan Takım 6-2 Galip Geldiyse  Skor 6-0 Olarak İtirazı Haklı Bulunan Takım Lehine Tescillenir.</p>
                        <p className="rule-description">Eğer İtirazı Haklı Bulunan Takım Mağlup Olmuş Veya 3 Golden Az Farkla Galip Gelmişse, Skor 3-0 İtirazı Haklı Bulunan Takım Lehine Tescillenir.</p>
                        <p className="rule-description">Elit Lig’in Sağlıklı Bir Şekilde İlerleyebilmesi İçin Siz Takımların Görüşleri Bizim İçin Çok Önemlidir. Bu Nedenle Maçınızın Tamamlanmasının Ardından 24 Saat İçinde Hakem, Koordinatör Ve Rakiple İlgili Görüşlerinizi İster Mail İster İletişim Merkezi Aracılığıyla Bize Bildirmenizi Önemle Rica Ederiz.</p>
                    </li>
                    <li>
                        PLAY-OFF:
                        <p className="rule-description">6 Aylık Sezonun Tamamlanmasının Ardından Elit Play-Off Süreci Başlar. Play-Off’a Dahil Olacak Takımlarda Sezonu En Az 10 Maçla Ve İlk 15 Sırada Tamamlamış Olması Şartı Aranır.</p>
                        <p className="rule-description">Ligi İlk 15 Sırada Tamamlamasına Rağmen Play-Off’a Katılmak İstemeyen Takımlar Olursa 10 Maçı Tamamlamış En Üst Sıradaki Takım Play-Off’a Katılmaya Hak Kazanır.</p>
                        <p className="rule-description">Play-Off’lardaki Oyuncu Kadroları Hakkındaki Bilgiler Takım Kadroları Başlığı Altında Belirtilmiştir.</p>
                        <p className="rule-description">Playofflarda, Tüm Oyuncuların Yanında Kimlik Bulundurması Gerekmektedir. Kimlikler İtiraz Durumunda Maçın Ardından Kontrol Edilecektir. Kontrol Sadece Üzerine İtirazda Bulunulan Oyuncuya Uygulanacaktır. Tüm Takımın Kimliği Kontrol Edilmeyecektir. Eğer İtiraz Edilen Oyuncunun Yanında Kimliği Yoksa, İtiraz Geçerli Kabul Edilip, Maç Hükmen İtirazda Bulunan Takım Lehine Tescillenecektir.</p>
                        <p className="rule-description">Ligin Normal Sezonunu; 1. Sırada Bitiren Takım Yarı Finalden ——- 2. Ve 3. Sırada Bitiren Takımlar Çeyrek Finalden —— 4.–5.–6. Ve 7. Sırada Bitiren Takımlar Play-Off  Gruplarından ——- 8.-9.-10.-11.-12..-13.-14. Ve 15. Sırada Bitiren Takımlar Ön Eleme Turundan Play-Off’lara Katılır.</p>
                        <p className="rule-description">Play-Off Takvimi Şu Şekilde İlerler;</p>
                        <ul className="sub-rules-list">
                            <li>Ön Eleme Turu’yla Başlayan Play-Off Mücadelesinde Rövanşsız Oynanan Eleme Maçlarını Kazanan 4 Takım, Play-Off Grubuna Dahil Olur.</li>
                            <li>2 Gruptan Oluşan Play-Off Grubu’na Ön Eleme Turundan Gelen 4 Takım Ve Lig’den Doğrudan Katılım Hakkı Sağlamış 4 Takım Play-Off’lardan Önce Belirlenen Statüde Yerleştirilir. Tek Devreli Lig Usulü Oynanan Grup Maçlarının Ardından Gruplarını İlk 2 Sırada Tamamlayan 4 Takım Çeyrek Finale Çıkar.</li>
                            <li>Çeyrek Final, Play-Off Grubundan Gelen 4 Takım Ve Lig’den Doğrudan Katılım Hakkı Kazanmış 2 Takımın Katılımıyla Rövanşsız Eleme Maçı Olarak Oynanır. Kazanan 3 Takım Yarı Final’e Yükselir.</li>
                            <li>Yarı Final, Çeyrek Finalden Gelen 3 Takım Ve Lig’den Doğrudan Katılım Hakkı Sağlamış 1 Takımın Katılımıyla Rövanşsız Eleme Maçı Şeklinde Oynanır. Kazanan 2 Takım Finale Yükselir.</li>
                            <li>Final, Yarı Finali Kazanan 2 Takım Arasında Rövanşsız Olarak Oynanır. Kazanan Takım Sezonun Şampiyonu Olur.</li>
                        </ul>
                        <br></br>
                        <p className="rule-description">Eleme Maçlarının Eşitlikle Sonuçlanması Durumunda, Uzatma Oynanmadan Direk Penaltı Atışlarına Geçilir. Takımlar 3’er Penaltı Attıktan Sonra Eşitlik Bozulmazsa Seri Penaltı Atışlarına Geçilir.</p>
                        <p className="rule-description">Play-Off Grup Aşamasında Takımlardan Birinin Maça Çıkmaması Durumunda, Karşı Tarafa Hükmen Galibiyet Verilir. Bu Hükmen Galibiyet Takımlardan Herhangi Birini Averaj Hesabında Mağdur Ediyorsa, Ligden Çekilen Takımın Oynadığı Diğer Maçlar da 3-0 Olarak Tescillenir.</p>
                        <p className="rule-description">Grup Maçlarında, Puan Eşitliği Durumunda Önce Gol Averajına Bakılır. Gol Averajının Eşit Olması Durumunda Takımların Birbiriyle Oynadığı Maçlara Bakılır. Burda da Eşitlik Bozulmazsa Attığı Gol En Çok Olan Takım Turu Geçer. Attığı Gol Sayısının da Eşit Olması Durumunda Takımlar Kendi Arasında Bir Eleme Maçı Oynarlar.</p>
                        <p className="rule-description">Eğer Play-Off’lara Katılacak Kıstaslara Sahip 15 Takım Elde Edilemezse, Lig Yönetimi Play-Off Statüsünde Değişiklik Yapabilir.</p>
                        <p className="rule-description">Statü Gereği Rövanşlı Oynanacak Maçlarda, Deplasman ve Ev Sahibi Avantajına Bakılmaksızın Averaj Eşitliği Durumunda Direk Penaltı Atışlarına Gidilir.</p>
                        <p className="rule-description">2 Sezonun Şampiyonları Yılın Şampiyonluğu İçin Rövanşsız Süper Final Maçına Çıkar. Şampiyon Olan Takım Tatil Ödülünün Kazananı Olur.</p>
                        <p className="rule-description">Süper Final Maçına Çıkacak Takımlar. Ligde Bildirdikleri En Son Kadroyla Sahaya Çıkabilirler. Bunun Dışında Misafir Oyuncu Oynatamazlar Ve Kadrolarına Takviye Yapamazlar. İlk Sezonun Şampiyonu Olan Takım 2. Sezonda da Lig’de Mücadele Etmiş Ve Play-Off’lara Katılmışsa, Süper Final Maçına da Yeni Kadrosuyla Çıkabilir. Ancak 2. Sezonda Mücadele Etmemişse Şampiyon Olduğu Kadro Dışında Oyuncu Oynatamaz.</p>
                    </li>
                    <li>
                        OYUN KURALLARI:
                        <p className="rule-description">Elit Lig’de Aksi Belirtilen Kurallar Hariç Uluslararası Futbol Kuralları Geçerlidir.</p>
                        <p className="rule-description">Üst Fileye Çarpıp Giren Goller Geçerlidir.</p>
                        <p className="rule-description">Ofsayt Uygulaması Yoktur.</p>
                        <p className="rule-description">Maçlar 25’er Dakikadan 2 Devre Halinde Oynanır.</p>
                        <p className="rule-description">Hakem İlk Sarı Karttan Sonra 2. Sarı Kartı Çıkarmadan Önce Dilerse 3 Dk Dinlenme Cezası Verebilir. Cezayı Gören Oyuncu 3 Dk Dışarıda Bekler. Takım Ceza Gören Oyuncunun Yerine Başka Bir Oyuncu Sokabilir. Ancak Bu Durumda Oyuncu Değişikliği Yapıldığı İçin Çıkan Oyuncunun Tekrar Oyuna Girmesi Mümkün Olmayacaktır. Eğer Oyuncu 3 Dk Cezasını Çekmiş Ve Tekrar Oyuna Dönmüşse, Sarı Kart Cezası Aynen Devam Eder Ve Göreceği 2. Sarı Kart Sonucunda Kırmızı Kartla Oyun Dışında Kalır.</p>
                        <p className="rule-description">Aut Atışından Ve Santradan Atılan Goller Geçerlidir.</p>
                        <p className="rule-description">Aut Atışından Sonra Topa Dokunan Oyuncunun Ceza Sahası Dışında Olması Gerekir. Aksi Halde Atış Tekrarlanır.</p>
                        <p className="rule-description">Uluslararası standartlardaki futbol kurallarından farklı olarak; Kaleciyle karşı karşıya kalınan pozisyonlarda atak yapan takım oyuncusuna yapılacak faullerde, sakatlamaya yönelik bir müdahale olmadığı sürece defans oyuncusu sarı kart ile cezalandırılır.</p>
                    </li>
                    <li>
                        DİSİPLİN:
                        <p className="rule-description">Elit Lig’de En Çok Önem Verdiğimiz Konu Disiplin’dir. Disiplinden Asla Taviz Verilmeyecektir. Bu Nedenle Disiplin Cezası Almış Oyuncuyu Takımında Oynattığı (Misafir de Olsa) Tespit Edilen Takım Hükmen Mağlup Edilir.</p>
                        <p className="rule-description">Ligimizde 2. Sarı Karttan Kırmızı Karta (Hakem Raporunda Aksi Belirtilmediği Takdirde) Ceza Yoktur. Direk Kırmızı Kartta İse Sakatlamaya Yönelik Sert Müdahale, Küfür, Darp Vb. Durumlar Hariç Yine Ceza Uygulanmamaktadır.. Hakem Olumlu Yönde Rapor Verip Ceza Verilmemesi Yönünde Görüş Bildirebilir. Bu Durumda Disiplin Kurulu Konuyla İlgili Kararı Vermede Yetkilidir.</p>
                        <p className="rule-description">Ligimizde Verilen Cezalarda Tahrik Unsuruna Büyük Önem Verilmektedir. Elit Lig’de Asıl Esas Olan Olayları Başlatan Taraf Olmamaktır. İstenmeyen Olayların Başlaması Durumunda Verilecek Cezalarda Olayları Başlatan Taraf Olmamak Hakem Ve Gözlemci Raporunun Yanı Sıra Kamera Görüntüleri de İncelenerek Hafifletici Neden Olarak Değerlendirilip, Buna Göre Ceza Uygulaması Yapılabilir.</p>
                        <p className="rule-description">Elitlig’de Play-Off Döneminde Alınan Cezalar, Yeni Sezonda Alınacak Maçlarla Eksiltilemez. İhraç Harici Cezalar, Cezanın Görüldüğü Sezon İçin Geçerlidir</p>
                        <p className="rule-description">Elit Lig Disiplin Kurulu Hakemin Kartla Cezalandırmadığı Oyunculara Da Ceza Verme Yetkisine Sahiptir. Verilecek Bu Cezalarda Video Görüntüleri Ve Koordinatör Raporu Esas Alınabilir.</p>
                        <p className="rule-description">Elit Lig’de Disiplin Kurulu’nun Vereceği Cezalarda Kıstas Aşağıdaki Gibidir;</p>
                        <ul className="sub-rules-list">
                            <li>Sarı Karttan Kırmızı Kart : Ceza Yok</li>
                            <li>Direkt Kırmızı Kart: Ceza Yok</li>
                            <li>Sakatlamaya Yönelik Sert Müdahale: 3 Maç’tan Men’e Kadar</li>
                            <li>Rakibi Veya Hakemi Küçük Düşürecek Her Türlü Hareket Ve Davranış: 1 Maç’tan Men’e Kadar</li>
                            <li>Küfür, Hakaret: 2 Maç’tan Men’e Kadar</li>
                            <li>Her Türlü Fiziki Müdahale: 3 Maç’tan Men’e Kadar</li>
                        </ul>
                        <p style={{fontWeight:'bolder'}}>Elit Lig Yönetimi, Kurallar Üzerinde İstediği Değişikliği Yapma Hakkını Saklı Tutar. Yapılan Değişiklik Site Üzerinden Duyurulur.</p>
                    </li>
                </ol>
            </div>
            <Footer/>
        </div>
    );
};

export default Rules;
