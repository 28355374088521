import React, { useState,useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
//import { UserContext } from '../App';
import { useUser } from "../UserContext"; // UserContext'i çağır

import './style.css';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  //const { setUser } = useContext(UserContext);
  const { setUser } = useUser(); // UserContext'ten setUser al

  const [showPassword, setShowPassword] = useState(false); // Şifre görünürlüğü durumu

  const handleSubmit = async (e) => {
    e.preventDefault(); // Sayfanın yeniden yüklenmesini engeller

    try {
        
        const response = await axios.post('https://elit-lig-back-end-bc5730e96b98.herokuapp.com/api/users/login', {
            username,
            password,
        }, {
            withCredentials: true // Çerezlerin gönderilmesini sağlar
        });

        const user = response.data.user;
  
        setUser(user);
  
        // **Bekleme süresi ekleyerek güncellenmesini sağla**
        setTimeout(() => {
          navigate("/");
        }, 100);

       /* const { user } = response.data;

        // Kullanıcı bilgilerini duruma kaydedin
        setUser(user);
        localStorage.setItem('user', JSON.stringify(user)); */// Cache'e kaydet

      // Token'ı çerezlere veya localStorage'a kaydedin (güvenlik için çerez tercih edilir)
      //document.cookie = `token=${token}; path=/; secure; SameSite=Strict`;

      // Rol'e göre yönlendirme (ör: Admin veya Editor)
      /*if (role === 'Admin') {
        setUser(response.data.user);
        alert('Admin olarak giriş yaptınız.');
      } else if (role === 'Editor') {
        setUser(response.data.user);
        alert('Editor olarak giriş yaptınız.');
      }*/

      // Anasayfaya veya editor sayfasına yönlendirin
      navigate('/');
    } catch (error) {
      console.error('Login error:', error);
      setErrorMessage('Kullanıcı adı veya şifre hatalı.');
    }
  };

  return (
    <div className="login-container">
      <h2>Giriş Yap</h2>
      <form onSubmit={handleSubmit} className="login-form">
        <div className="form-group">
          <label>Kullanıcı Adı:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Şifre:</label>
          <input
            type={showPassword ? 'text' : 'password'} // Şifre görünürlüğü durumu
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button
            type="button"
            className="toggle-password-button"
            onClick={() => setShowPassword((prev) => !prev)} // Görünürlüğü değiştir
          >
            {showPassword ? 'Şifreyi Gizle' : 'Şifreyi Göster'}
          </button>
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <button type="submit" className="login-button">
          Giriş Yap
        </button>
      </form>
    </div>
  );
};

export default Login;
