import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useData } from "./Cache";
import Header from "./Header";
import Footer from "./Footer";
import Pagination from "./Button";
import "./style.css";

const PlayerListDouble = () => {
  const { cityName, leagueName } = useParams(); // URL'den şehir ve lig ismi al
  const { players, matches, seasons, playerStatistics1, loading, error } =
    useData();

  const [selectedTable, setSelectedTable] = useState("value");
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [filteredPlayers, setFilteredPlayers] = useState([]);

  const playersPerPage = 25;
  const [currentPage, setCurrentPage] = useState(1);
  const lastIndex = currentPage * playersPerPage;
  const firstIndex = lastIndex - playersPerPage;

  useEffect(() => {
    if (!selectedSeason) return;

    // **Bu şehirde bulunan oyuncuları filtrele**
    const cityPlayers = players.filter(
      (player) => formatTeamNameForURL(player.city) === formatTeamNameForURL(cityName) && player.active
    );

    // **Bu ligde ve sezonda oynanan maçları filtrele**
    const seasonMatches = matches.filter(
      (match) =>
        formatTeamNameForURL(match.league_name) === leagueName &&
        match.match_season === selectedSeason &&
        match.is_it_fixture === false &&
        formatTeamNameForURL(match.city) === formatTeamNameForURL(cityName)
    );

    // **Oyuncuların performans istatistiklerini hesapla**
    const calculatedPlayerStats = cityPlayers.map((player) => {
      // Oyuncunun oynadığı maçları bul
      const playerStats = playerStatistics1.filter(
        (stat) =>
          stat.player_id === player.id &&
          seasonMatches.some((match) => match.id === stat.match_id)
      );

      // Toplam istatistikleri hesapla
      const totalGoals = playerStats.reduce(
        (acc, stat) => acc + stat.number_of_goals,
        0
      );
      const totalPoints = playerStats.reduce(
        (acc, stat) => acc + stat.points,
        0
      );
      const totalMatches = playerStats.length;
      const yellowCards = playerStats.reduce(
        (acc, stat) => acc + (stat.yellow_card > 0 ? 1 : 0),
        0
      );

      const redCards = playerStats.reduce(
        (acc, stat) => acc + (stat.red_card > 0 ? 1 : 0),
        0
      );

      return {
        ...player,
        totalGoals,
        totalPoints,
        totalMatches,
        yellowCards,
        redCards,
        value:
          ((totalPoints / totalMatches) * 100 +
            totalMatches * 20 -
            yellowCards * 30 -
            redCards * 50) *
          70,
      };
    });

    setFilteredPlayers(calculatedPlayerStats);
  }, [
    selectedSeason,
    cityName,
    leagueName,
    players,
    matches,
    playerStatistics1,
  ]);

  const sortedPlayers = filteredPlayers
    .sort((a, b) => {
      if (selectedTable === "value") return b.value - a.value;
      if (selectedTable === "goals") return b.totalGoals - a.totalGoals;
      return 0;
    })
    .slice(firstIndex, lastIndex);

  const totalPages = Math.ceil(filteredPlayers.length / playersPerPage);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <Header />
      <header className="header-banner">
        <h1 className="banner-title">Oyuncu Listesi</h1>
        <div className="zigzag">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#4facfe"
              fillOpacity="1"
              d="M0,256L48,224C96,192,192,128,288,96C384,64,480,64,576,96C672,128,768,192,864,213.3C960,235,1056,213,1152,213.3C1248,213,1344,235,1392,245.3L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
            ></path>
          </svg>
        </div>
      </header>
      <div className="player-container-pdl">
        {/* Sezon Seçimi */}
        <div className="select-container-pdl">
          <select onChange={(e) => setSelectedSeason(e.target.value)}>
            <option value="">Sezon Seç</option>
            {seasons
              .filter(
                (season) =>
                  formatTeamNameForURL(season.league_name) === leagueName
              )
              .map((season) => (
                <option key={season.id} value={season.season_name}>
                  {season.season_name}
                </option>
              ))}
          </select>
        </div>

        {/* Kategori Seçimi */}
        <div className="select-container-pdl">
          <select
            value={selectedTable}
            onChange={(e) => setSelectedTable(e.target.value)}
          >
            <option value="value">En Değerliler</option>
            <option value="goals">Gol Krallığı</option>
          </select>
        </div>

        {/* Oyuncu Tablosu */}
        <div className="player-table-pdl">
          <table>
            <caption>
              {selectedTable === "value" ? "EN DEĞERLİLER" : "GOL KRALLIĞI"}
            </caption>
            <thead>
              <tr>
                <th>Sıra</th>
                <th>Oyuncu</th>
                <th>Takım</th>
                <th>OM</th>
                <th>{selectedTable === "value" ? "M.B. PUAN" : "M.B. GOL"}</th>
                <th>{selectedTable === "value" ? "DEĞERİ" : "GOL"}</th>
                <th>🟨</th>
                <th>🟥</th>
              </tr>
            </thead>
            <tbody>
              {sortedPlayers.map((player, index) => (
                <tr key={index}>
                  <td>{(currentPage - 1) * playersPerPage + index + 1}</td>
                  <td>
                    <a
                      href={`/${formatTeamNameForURL(cityName)}/${formatTeamNameForURL(leagueName)}/oyuncular/${player.id}`}
                      className="player-link-pdl"
                    >
                      <img
                        src={player.player_img}
                        alt={player.player_name}
                        className="player-img-pdl"
                      />
                      {player.player_name}
                    </a>
                  </td>
                  <td>
                    <a
                      href={`/${formatTeamNameForURL(cityName)}/${formatTeamNameForURL(leagueName)}/takimlar/${formatTeamNameForURL(
                        player.team_name
                      )}`}
                      className="team-link-pdl"
                    >
                      {player.team_name}
                    </a>
                  </td>
                  <td>{player.totalMatches}</td>
                  <td>
                    {selectedTable === "value"
                      ? (player.totalPoints / player.totalMatches || 0).toFixed(
                          2
                        )
                      : (player.totalGoals / player.totalMatches || 0).toFixed(
                          2
                        )}
                  </td>
                  <td>
                    {selectedTable === "value"
                      ? player.value.toFixed(2)
                      : player.totalGoals}
                  </td>
                  <td>{player.yellowCards}</td>
                  <td>{player.redCards}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Sayfalama */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      </div>
      <Footer />
    </div>
  );
};

function formatTeamNameForURL(teamName) {
  return teamName
    .toLowerCase()
    .replace(/ç/g, "c")
    .replace(/ğ/g, "g")
    .replace(/ı/g, "i")
    .replace(/ö/g, "o")
    .replace(/ş/g, "s")
    .replace(/ü/g, "u")
    .replace(/[^a-z0-9 ]/g, "")
    .trim()
    .replace(/\s+/g, "-");
}

export default PlayerListDouble;
