import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
//import { UserContext } from "../App";
import { useUser } from "../UserContext";

import "./style.css";
import logo from "../assets/elitlig(2).png";
import { useData } from "./Cache";
import { useParams } from 'react-router-dom';

const Header = () => {
  const { leagues } = useData();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
  const { user, setUser } = useUser();

  // URL'den şehir ismini al
  const pathSegments = location.pathname.split("/").filter(Boolean);
  const { cityName } = useParams();
  const shouldShowSubHeader = pathSegments.length >= 2;

  const citiesWithLeagues = leagues.reduce((acc, league) => {
    if (!acc[formatTeamNameForURL(league.city)]) {
      acc[formatTeamNameForURL(league.city)] = [];
    }
    acc[formatTeamNameForURL(league.city)].push(league.league_name);
    return acc;
  }, {});

  const cityLeagues =
    cityName && citiesWithLeagues[formatTeamNameForURL(cityName)] ? citiesWithLeagues[formatTeamNameForURL(cityName)] : [];
  console.log(citiesWithLeagues, cityName);


  return (
    <div className="header-container-h">
      {/* Üst Menü */}
      <div className="top-header-h">
        <div className="club-logos-h">
          <span style={{ fontWeight: "bolder" }}>HEMEN MAÇ AL: </span>0507 169
          0888
        </div>
        <div className="top-right-buttons-h">
          <div
            className="city-change-container-h"
            onMouseEnter={() => setIsDropdownOpen(true)}
            onMouseLeave={() => setIsDropdownOpen(false)}
          >
            <button className="city-change-btn-h">Şehir Değiştir</button>
            {isDropdownOpen && (
              <div className="city-dropdown-h">
                {Object.keys(citiesWithLeagues).map((city, index) => (
                  <a key={index} href={`/${formatTeamNameForURL(city)}/`} className="city-item-h">
                    {city.replace(/-/g, " ").toUpperCase()}
                  </a>
                ))}
              </div>
            )}
          </div>

          {/* Eski Siteye Git veya Editör Paneli */}
          {user && (user.role === "admin" || user.role === "editor") ? (
            <button
              className="old-site-btn-h"
              onClick={() => (window.location.href = "/editor")}
            >
              Editör Paneli
            </button>
          ) : (
            <button
              className="old-site-btn-h"
              onClick={() => (window.location.href = "https://elitlig.com.tr")}
            >
              Eski Siteye Git
            </button>
          )}
        </div>
      </div>

      {/* Ana Menü */}
      <nav className="main-header-h">
        <div className="header-logo-h">
          <a href={cityName ? `/${cityName}` : "/"}>
            <img src={logo} alt="Logo" />
          </a>
        </div>

        {/* Hamburger Menü Butonu (Mobil için) */}
        <button
          className="hamburger-menu"
          onClick={() => setIsSidebarOpen(true)}
        >
          ☰
        </button>

        <ul className="nav-links-h">
          <li>
            <a href="/">ELİTLİG</a>
          </li>

          {cityName ? (
            <li>
              {cityLeagues.slice(0, 4).map((league, index) => (
                <a key={index} href={`/${formatTeamNameForURL(cityName)}/${formatTeamNameForURL(league)}`}>
                  {league.toUpperCase()}
                </a>
              ))}
            </li>
          ) : null}

          <li>
            <a href="/iletisim">İLETİŞİM</a>
          </li>
          <li>
            <a href="/kurallar">KURALLAR</a>
          </li>
          <li>
            <a href="/haber-duyuru">HABER & DUYURU</a>
          </li>
        </ul>
        <div className="right-section-h">
          {/* <button className="sign-in-btn-h">Sign in</button>
          <i className="fas fa-search search-icon"></i> */}
        </div>
      </nav>

      {/* Yandan Açılan Sidebar Menü (Mobil için) */}
      <div className={`sidebar-h ${isSidebarOpen ? "open" : ""}`}>
        <button
          className="close-sidebar"
          onClick={() => setIsSidebarOpen(false)}
        >
          ✖
        </button>

        <div className="header-logo-h">
          <img src={logo} alt="Logo" href="/" />
        </div>

        <a href="/" className="sidebar-link">
          ELİTLİG
        </a>
        <a href="/iletisim" className="sidebar-link">
          İLETİŞİM
        </a>
        <a href="/kurallar" className="sidebar-link">
          KURALLAR
        </a>
        <a href="/haber-duyuru" className="sidebar-link">
          HABER & DUYURU
        </a>

        {/* Eğer şehir varsa, o şehre ait ligleri göster */}
        {cityName && (
          <div className="sidebar-section">
            {cityLeagues.map((league, index) => (
              <a
                key={index}
                href={`/${formatTeamNameForURL(cityName)}/${formatTeamNameForURL(league)}`}
                className="sidebar-link"
              >
                {league}
              </a>
            ))}
          </div>
        )}

        {/* Eski Site veya Editör Paneli */}
        {user && (user.role === "admin" || user.role === "editor") ? (
          <a href="/editor" className="sidebar-link">
            Editör Paneli
          </a>
        ) : (
          <a href="https://elitlig.com.tr" className="sidebar-link">
            Eski Siteye Git
          </a>
        )}
      </div>

      {/* Alt Menü */}
      {shouldShowSubHeader && (
        <div className="sub-header-h">
          <ul className="sub-menu-h">
            <li>
              <a href={`/${pathSegments[0]}/${pathSegments[1]}/puan-durumu`}>
                Puan Tablosu
              </a>
            </li>
            <li>
              <a href={`/${pathSegments[0]}/${pathSegments[1]}/fikstur`}>
                Fikstür
              </a>
            </li>
            <li>
              <a href={`/${pathSegments[0]}/${pathSegments[1]}/sonuclar`}>
                Sonuçlar
              </a>
            </li>
            <li>
              <a
                href={`/${pathSegments[0]}/${pathSegments[1]}/en-degerliler-gol-kralligi`}
              >
                Oyuncular
              </a>
            </li>
            <li>
              <a href={`/${pathSegments[0]}/${pathSegments[1]}/transferler`}>
                Transferler
              </a>
            </li>
            <li>
              <a href={`/${pathSegments[0]}/${pathSegments[1]}/cezalar`}>
                Cezalar
              </a>
            </li>
            <li>
              <a href={`/${pathSegments[0]}/${pathSegments[1]}/odul-kosesi`}>
                Ödül Köşesi
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

function formatTeamNameForURL(teamName) {
  return teamName
    .toLowerCase()
    .replace(/ç/g, "c")
    .replace(/ğ/g, "g")
    .replace(/ı/g, "i")
    .replace(/ö/g, "o")
    .replace(/ş/g, "s")
    .replace(/ü/g, "u")
    .replace(/[^a-z0-9 ]/g, "")
    .trim()
    .replace(/\s+/g, "-");
}

export default Header;
